import { ReactNode } from "react";
import "styled-components";
import { StatusType } from "./status.type";
import { Project } from "./project.type";

export interface NavigationProps {
  id?: number;
  link: string;
  element?: ReactNode;
  name?: string;
}

export interface AppState {
  selectedProject: Project | null;
  status?: StatusType;
  error?: string | null;
}

export enum Breakpoints {
  MOBILE = "600px",
}
