import { FunctionComponent, useState } from "react";
import CtLayout from "../../../common/components/CtLayout/CtLayout";
import CtContent from "../../../common/components/CtLayout/CtContent";
import { Box, useMediaQuery } from "@mui/material";
import SubNav from "../components/SubNav";
import SettingsContent from "../components/SettingsContent";
import CtComingSoon from "../../../common/components/CtInfoUser/CtComingSoon";
import SubNavLayout from "../components/SubNavLayout";
import { Breakpoints } from "../../../common/utils/types/app.type";

const Notifications: FunctionComponent = () => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);

  return (
    <SubNavLayout
      openMobileNavbar={openMobileNavbar}
      setOpenMobileNavbar={setOpenMobileNavbar}
    >
      <CtLayout isCollapsed={true}>
        <CtContent>
          <Box display={"flex"} height={"100%"}>
            {isMobile ? <></> : <SubNav />}

            <SettingsContent
              title={"Notifications"}
              openMobileNavbar={openMobileNavbar}
              setOpenMobileNavbar={setOpenMobileNavbar}
            >
              <CtComingSoon />
            </SettingsContent>
          </Box>
        </CtContent>
      </CtLayout>
    </SubNavLayout>
  );
};

export default Notifications;
