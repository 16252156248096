import { FunctionComponent, useState } from "react";
import CtLayout from "../../../common/components/CtLayout/CtLayout";
import CtContent from "../../../common/components/CtLayout/CtContent";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SubNav from "../components/SubNav";
import SettingsContent from "../components/SettingsContent";
import { Alert, Divider, Input, Modal } from "antd";
import { Save, TextCursor, Trash } from "lucide-react";
import CtButton from "../../../common/components/CtButton/CtButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../common/utils/hooks/reduxHook";
import {
  handleDeleteProject,
  handleUpdateProject,
} from "../../../app/store/actions/app.action";
import { useLoading } from "../../../common/utils/hooks/useLoading";
import { useTranslation } from "react-i18next";
import SubNavLayout from "../components/SubNavLayout";
import { Breakpoints } from "../../../common/utils/types/app.type";

const General: FunctionComponent = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const dispatch = useAppDispatch();
  const { loadingStates, execWithLoader } = useLoading();

  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmProjectName, setConfirmProjectName] = useState("");

  const appState = useAppSelector((state) => state.app);
  const theme = useTheme();

  const [projectName, setProjectName] = useState(
    appState?.selectedProject?.name || ""
  );

  const handleSave = () => {
    execWithLoader("updateProject", async () => {
      await dispatch(handleUpdateProject(projectName));
    });
  };

  return (
    <SubNavLayout
      openMobileNavbar={openMobileNavbar}
      setOpenMobileNavbar={setOpenMobileNavbar}
    >
      <CtLayout isCollapsed={true}>
        <CtContent>
          <Box display={"flex"} height={"100%"}>
            {isMobile ? <></> : <SubNav />}

            <SettingsContent
              title={"General"}
              openMobileNavbar={openMobileNavbar}
              setOpenMobileNavbar={setOpenMobileNavbar}
            >
              <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
                <Box
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent={"space-between"}
                  alignItems={"flex-end"}
                  gap={"16px"}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    width={"100%"}
                  >
                    <Typography fontWeight={"bold"} variant={"caption"}>
                      {t("labels.project_name")}
                    </Typography>
                    <Input
                      value={projectName}
                      prefix={
                        <Box display={"flex"} mr={"4px"}>
                          <TextCursor
                            size={14}
                            color={theme.palette.grey[400]}
                          />
                        </Box>
                      }
                      onChange={(e) => setProjectName(e.target.value)}
                    />
                  </Box>

                  <CtButton
                    type={"primary"}
                    icon={<Save size={14} />}
                    loading={loadingStates.updateProject}
                    onClick={handleSave}
                  >
                    {t("buttons.save")}
                  </CtButton>
                </Box>

                <Alert
                  message={
                    <Box
                      display={"flex"}
                      flexDirection={isMobile ? "column" : "row"}
                      justifyContent={"space-between"}
                      alignItems={isMobile ? "flex-end" : "center"}
                      gap={isMobile ? "16px" : 0}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"4px"}
                        width={"100%"}
                      >
                        <Typography fontWeight={"bold"}>
                          {t("labels.delete_project")}
                        </Typography>
                        <Typography variant={"caption"}>
                          {t("labels.delete_project_subtitle", {
                            projectName: (
                              <strong>{appState.selectedProject?.name}</strong>
                            ),
                          })}
                        </Typography>
                      </Box>

                      <CtButton
                        type={"primary"}
                        danger={true}
                        icon={<Trash size={14} />}
                        loading={loadingStates.deleteProject}
                        onClick={() => setShowConfirmModal(true)}
                      >
                        {t("buttons.delete_project")}
                      </CtButton>
                    </Box>
                  }
                  type="error"
                />
              </Box>
            </SettingsContent>

            <Modal
              title={
                <Typography variant={"h2"}>
                  {t("title.delete_project")}
                </Typography>
              }
              centered
              open={showConfirmModal}
              onOk={() =>
                execWithLoader("deleteProject", async () => {
                  await dispatch(handleDeleteProject());
                  setShowConfirmModal(false);
                })
              }
              onCancel={() => setShowConfirmModal(false)}
              width={550}
              okText={t("buttons.continue")}
              okButtonProps={{
                disabled: confirmProjectName !== appState.selectedProject?.name,
                loading: loadingStates.deleteProject,
                danger: true,
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                mt={"16px"}
                mb={"24px"}
                gap={"24px"}
              >
                <Typography>
                  {t("labels.project_and_topics")}
                  <strong>{t("labels.users_no_longer_access")}</strong>
                </Typography>

                <Alert
                  message={
                    <strong>{t("labels.warning_delete_project")}</strong>
                  }
                  type="error"
                />

                <Divider style={{ margin: 0 }} />

                <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                  <Typography>
                    {t("labels.project_name_to_confirm", {
                      projectName: (
                        <strong>{appState.selectedProject?.name}</strong>
                      ),
                    })}
                  </Typography>

                  <Input
                    placeholder={t("labels.confirm_deletion")}
                    prefix={
                      <Box display={"flex"} mr={"4px"}>
                        <TextCursor size={14} color={theme.palette.grey[400]} />
                      </Box>
                    }
                    onChange={(e) => setConfirmProjectName(e.target.value)}
                  />
                </Box>
              </Box>
            </Modal>
          </Box>
        </CtContent>
      </CtLayout>
    </SubNavLayout>
  );
};

export default General;
