import ForgotPassword from "../../../features/Auth/ForgotPassword";
import Login from "../../../features/Auth/Login";
import Signup from "../../../features/Auth/Signup";
import InvitationLink from "../../../features/Auth/components/InvitationLink";
import ContextualHelp from "../../../features/ContextualHelp";
import NewProject from "../../../features/NewProject";
import Account from "../../../features/Settings/views/Account";
import General from "../../../features/Settings/views/General";
import Installation from "../../../features/Settings/views/Installation";
import Notifications from "../../../features/Settings/views/Notifications";
import Team from "../../../features/Settings/views/Team";
import Statistics from "../../../features/Statistics";
import Welcome from "../../../features/Welcome";
import { NavigationProps } from "../types/app.type";

export const Navigation: NavigationProps[] = [
  {
    id: 0,
    link: "/",
    element: <Welcome />,
    name: "Welcome",
  },
  {
    id: 1,
    link: "/new-project",
    element: <NewProject />,
    name: "New Project",
  },
  {
    id: 2,
    link: "/contextual-help",
    element: <ContextualHelp />,
    name: "Contextual Help",
  },
  {
    id: 3,
    link: "/statistics",
    element: <Statistics />,
    name: "My Users",
  },
  {
    id: 4,
    link: "/settings/general",
    element: <General />,
    name: "Settings",
  },
  {
    id: 5,
    link: "/settings/installation",
    element: <Installation />,
    name: "Installation",
  },
  {
    id: 6,
    link: "/settings/team",
    element: <Team />,
    name: "Team",
  },
  {
    id: 7,
    link: "/settings/account",
    element: <Account />,
    name: "Account",
  },
  {
    id: 8,
    link: "/settings/notifications",
    element: <Notifications />,
    name: "Notifications",
  },
];

export const AuthNavigation: NavigationProps[] = [
  {
    id: 1,
    link: "/login",
    element: <Login />,
    name: "Login",
  },
  {
    id: 2,
    link: "/signup",
    element: <Signup />,
    name: "Signup",
  },
  {
    id: 3,
    link: "/forgot-password",
    element: <ForgotPassword />,
    name: "Forgot Password",
  },
  {
    id: 4,
    link: "/register/:tokenId/:teamId/:userId?",
    element: <InvitationLink />,
    name: "Invitation",
  },
];
