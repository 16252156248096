import { Topic, TopicStatus } from "./types/topics.type";

const EMAIL_CHECK = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/;

export const isValidEmail = (email: string): boolean => EMAIL_CHECK.test(email);

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const getStatus = (status: string | null) => {
  switch (status) {
    case "LIVE":
      return { color: "green", text: "Live" };

    case "DRAFT":
      return { color: "cyan", text: "Draft" };

    case "EXPIRED":
      return { color: "red", text: "Expired" };

    default:
      return { color: "red", text: "Expired" };
  }
};

export const debounce = (func: any, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const calculateReadingTime = (text, wordsPerMinute = 225) => {
  const wordCount = text.trim().split(/\s+/).length;
  if (wordCount <= 0) {
    return 0;
  }

  const readingTime = wordCount / wordsPerMinute;

  return Math.ceil(readingTime); // On arrondit à l'entier supérieur pour ne pas sous-estimer le temps de lecture
};

export const isPageActive = (to: string) => {
  return window.location.pathname === to;
};

export const downloadJson = (projectId: string, listTopic: Topic[]) => {
  const jsonData: any = {
    projectId: projectId,
    topics: [],
  };

  jsonData.topics = listTopic
    ?.filter((item) => item.status === TopicStatus.LIVE)
    .map((item: Topic) => ({
      id: item.id,
      title: item.title,
      category: item.settings.displayTag.name,
    }));

  const jsonString = JSON.stringify(jsonData, null, 2);

  const blob = new Blob([jsonString], { type: "application/json" });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "data.json";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(url);
};
