import AuthLayout from "./AuthLayout";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LockKeyhole, Mail, Rocket } from "lucide-react";
import styled from "styled-components";
import CtButton from "../../common/components/CtButton/CtButton";
import UserIcon from "./components/UserIcon";
import { useNavigate } from "react-router-dom";
import { FunctionComponent, useState } from "react";
import { useLoading } from "../../common/utils/hooks/useLoading";
import { AuthType } from "../../common/utils/types/auth.type";
import { useAppDispatch } from "../../common/utils/hooks/reduxHook";
import { handleLogin } from "../../app/store/actions/user.action";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../common/utils/types/app.type";
import CtInput from "../../common/components/CtInput/CtInput";
import CtInputPassword from "../../common/components/CtInput/CtInputPassword";

const StyledTypoHover = styled(Typography)`
  cursor: pointer;
  transition: 0.3s;
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Login: FunctionComponent = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loadingStates, execWithLoader } = useLoading();

  const [formLogin, setFormLogin] = useState<AuthType>({
    email: "",
    password: "",
  });

  const handleSubmitLogin = () => {
    if (formLogin.email && formLogin.password) {
      execWithLoader("login", async () => {
        dispatch(handleLogin(formLogin));
      });
    }
  };

  return (
    <AuthLayout>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        height={"calc(100% - 75px)"}
        justifyContent={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"32px"}
          width={isMobile ? "100%" : "75%"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            textAlign={"center"}
            gap={"8px"}
          >
            <UserIcon />
            <Typography variant={"h1"}>
              {t("title.login_to_account")}
            </Typography>
            <Typography variant={"caption"}>
              {t("labels.login_to_account")}
            </Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"16px"}
            width={isMobile ? "100%" : "75%"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Typography fontWeight={"bold"} variant={"caption"}>
                {t("labels.email")}
              </Typography>
              <CtInput
                placeholder={"michael.jackson@youhou.com"}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <Mail size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
                onChange={(e) =>
                  setFormLogin((prev: AuthType) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                value={formLogin.email}
                handleOnPressEnter={handleSubmitLogin}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography fontWeight={"bold"} variant={"caption"}>
                  {t("labels.password")}
                </Typography>
                <Box onClick={() => navigate("/forgot-password")}>
                  <StyledTypoHover variant={"caption"}>
                    {t("labels.forgot_password")}
                  </StyledTypoHover>
                </Box>
              </Box>
              <CtInputPassword
                placeholder={t("labels.type_password")}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <LockKeyhole size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
                handleOnPressEnter={handleSubmitLogin}
                onChange={(e) =>
                  setFormLogin((prev: AuthType) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                value={formLogin.password}
              />
            </Box>
            <CtButton
              type={"primary"}
              icon={<Rocket size={14} />}
              style={{ width: "100%" }}
              loading={loadingStates.login}
              disabled={
                !formLogin?.email?.length || !formLogin.password?.length
              }
              onClick={handleSubmitLogin}
            >
              {t("buttons.lets_go")}
            </CtButton>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Login;
