import { Typography } from "@mui/material";
import { Drawer, DrawerProps, Modal } from "antd";
import { X } from "lucide-react";
import { Dispatch, FunctionComponent, ReactNode, SetStateAction } from "react";
import styled from "styled-components";

const StyleDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
  }
`;

const CtDrawer: FunctionComponent<CtDrawerProps> = (props: CtDrawerProps) => {
  const [modal, contextHolder] = Modal.useModal();

  const config = {
    title: <Typography fontWeight={"bold"}>{props?.confirm?.title}</Typography>,
    content: <Typography>{props?.confirm?.description}</Typography>,
  };

  return (
    <StyleDrawer
      width={"100%"}
      onClose={() =>
        props.displayConfirm
          ? modal.confirm({
              ...config,
              onOk: async () => {
                props?.onCloseExtra?.();
                props.setIsOpen(false);
              },
            })
          : props.setIsOpen(false)
      }
      closeIcon={<X size={16} />}
      open={props.isOpen}
      {...props}
    >
      {contextHolder}
      {props.children}
    </StyleDrawer>
  );
};

export interface CtDrawerProps extends DrawerProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  displayConfirm?: boolean;
  children?: ReactNode;
  onCloseExtra?: () => void;
  confirm?: {
    title: string;
    description: string;
  };
}

export default CtDrawer;
