import { Input, Skeleton } from "antd";
import {
  Plus,
  LayoutGrid,
  Rows3,
  Search,
  Rocket,
  Brush,
  Trash2,
} from "lucide-react";
import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import CtButton from "../../../common/components/CtButton/CtButton";
import CtHeader from "../../../common/components/CtLayout/CtHeader";
import CtLine from "../../../common/components/CtLine/CtLine";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../common/utils/hooks/reduxHook";
import {
  handleDeleteTopic,
  handleSetTopicsSettings,
} from "../../../app/store/actions/topics.action";
import {
  Category,
  TopicAllProps,
  TopicDisplayMode,
} from "../../../common/utils/types/topics.type";
import NewTopic from "./Topics/NewTopic";
import CtSelect from "../../../common/components/CtSelect/CtSelect";
import noResult from "../../../assets/images/noResult.svg";
import { StatusType } from "../../../common/utils/types/status.type";
import {
  sortTopic,
  statusTopic,
} from "../../../common/utils/constants/topics.consts";
import { tagRender } from "./TagsManagement";
import modal from "antd/es/modal";
import DownloadJSON from "./Topics/DownloadJSON";
import CtGradientButton from "../../../common/components/CtButton/CtGradientButton";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../../common/utils/types/app.type";
import { downloadJson } from "../../../common/utils/functions";

const ChHeader: FunctionComponent<ChHeaderProps> = (props: ChHeaderProps) => {
  const { t } = useTranslation();
  const [openCustomize, setOpenCustomize] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const userState = useAppSelector((state) => state.user);
  const appState = useAppSelector((state) => state.app);
  const topicsState = useAppSelector((state) => state.topics);
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const lengthTopics = topicsState?.filteredList === null;

  const constructCategoryValue = () => {
    if (Object.keys(topicsState?.categories).length > 0) {
      const foundCategory = topicsState?.categories?.find(
        (item) => item.id === props.settings.categoryId
      );

      return foundCategory
        ? {
            id: foundCategory?.id,
            value: foundCategory?.color,
            label: foundCategory?.name,
          }
        : null;
    }
  };

  const handleDeleteSelectedTopic = (e?: any) => {
    e?.stopPropagation();
    const config = {
      title: (
        <Typography fontWeight={"bold"}>{t("title.remove_topic")}</Typography>
      ),
      content: <Typography>{t("labels.deletion_topic")}</Typography>,
    };

    if (props.selectedItems.length) {
      modal.confirm({
        ...config,
        onOk: async () => {
          dispatch(handleDeleteTopic(props.selectedItems));
          props.setSelectedItems([]);
        },
      });
    }
  };

  return (
    <>
      <CtHeader
        title={
          topicsState?.status === StatusType.Loading ? (
            <Skeleton.Button
              active={true}
              shape={"square"}
              style={{
                width: "33%",
              }}
            />
          ) : (
            <Box display={"flex"} alignItems={"center"} gap={"18px"}>
              <Typography variant={"h1"}>{t("title.topics")}</Typography>
              <CtGradientButton />
            </Box>
          )
        }
        actions={
          topicsState?.status === StatusType.Loading ? (
            <Skeleton.Button
              active={true}
              shape={"square"}
              style={{
                width: "33%",
              }}
            />
          ) : isMobile ? (
            <CtSelect
              value={t("labels.actions")}
              options={[
                { value: "export", label: t("buttons.export_for_developers") },
                { value: "personalize", label: t("buttons.customize_me") },
                {
                  value: "delete",
                  label: t("buttons.delete"),
                  disabled: props.selectedItems.length === 0,
                },
              ]}
              onChange={(newValue) => {
                if (newValue === "export") {
                  if (appState.selectedProject?.id && topicsState.list) {
                    downloadJson(
                      appState.selectedProject?.id,
                      topicsState.list
                    );
                  }
                } else if (newValue === "personalize") {
                  setOpenCustomize(true);
                } else if (newValue === "delete") {
                  handleDeleteSelectedTopic();
                }
              }}
              popupMatchSelectWidth={false}
            />
          ) : !lengthTopics && userState.status === StatusType.Succeeded ? (
            isMobile ? (
              <></>
            ) : (
              <Box display={"flex"} gap={"16px"} alignItems={"center"}>
                <CtButton
                  type={"dashed"}
                  icon={<Brush size={14} />}
                  onClick={() => setOpenCustomize(true)}
                  disabled={openCustomize}
                >
                  {t("buttons.customize_me")}
                </CtButton>

                <DownloadJSON disabled={openCustomize} />

                <CtButton
                  type={"primary"}
                  icon={<Plus size={14} />}
                  disabled={openCustomize}
                  onClick={() => props.setOpenTopic(true)}
                >
                  {t("buttons.new_topic")}
                </CtButton>
              </Box>
            )
          ) : null
        }
      >
        {lengthTopics && userState.status === StatusType.Succeeded ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
          >
            <img src={noResult} width={"25%"} />

            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"16px"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                width={"300px"}
              >
                <Typography variant={"h1"}>{t("title.no_topic")}</Typography>
                <Typography variant={"h3"} fontWeight={500}>
                  {t("labels.no_topic")}
                </Typography>
              </Box>
              <CtButton
                icon={<Rocket size={14} />}
                onClick={() => props.setOpenTopic(true)}
              >
                {t("buttons.create_first_topic")}
              </CtButton>
            </Box>
          </Box>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"24px"}
            padding={"24px 26px"}
          >
            <Box
              display={"flex"}
              flexDirection={isMobile ? "column" : "row"}
              gap={"8px"}
              justifyContent={"space-between"}
              alignItems={isMobile ? "flex-start" : "center"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Box
                  display={"flex"}
                  gap={"6px"}
                  width={isMobile ? "100%" : "50%"}
                  alignItems={"center"}
                >
                  <Typography variant="h1" fontWeight={100}>
                    {t("labels.showing")}
                  </Typography>
                  <Typography variant="h1">
                    {topicsState?.filteredList?.length}{" "}
                    {t("labels.result", {
                      count: topicsState?.filteredList?.length,
                    })}
                  </Typography>
                </Box>

                {isMobile ? (
                  <CtButton
                    type={"primary"}
                    danger={true}
                    icon={<Trash2 size={14} />}
                    disabled={props.selectedItems.length === 0}
                    onClick={handleDeleteSelectedTopic}
                  />
                ) : null}
              </Box>

              <Box
                display={"flex"}
                gap={"8px"}
                alignItems={isMobile ? "flex-end" : "center"}
                justifyContent={"flex-end"}
                width={isMobile ? "100%" : "50%"}
              >
                <Box
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  gap={"8px"}
                  alignItems={isMobile ? "flex-start" : "center"}
                >
                  <Typography fontWeight={"bold"}>
                    {t("labels.sort_by")}
                  </Typography>
                  <CtSelect
                    value={sortTopic.find(
                      (item) => item.id === props.settings.sort
                    )}
                    style={{ minWidth: 160 }}
                    options={sortTopic}
                    onChange={(_, option: any) => {
                      props.setSettings((prev) => ({
                        ...prev,
                        sort: option?.id ?? null,
                      }));
                    }}
                  />
                </Box>
                <Input
                  value={props.settings.search ?? ""}
                  placeholder={`${t("labels.search")} ...`}
                  prefix={
                    <Box display={"flex"} mr={"4px"}>
                      <Search size={14} color={theme.palette.grey[400]} />
                    </Box>
                  }
                  allowClear={true}
                  style={{ width: 300 }}
                  onChange={(e) =>
                    props.setSettings((prev) => ({
                      ...prev,
                      search: e.target.value,
                    }))
                  }
                />
              </Box>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                <Typography fontWeight={"bold"}>
                  {t("labels.filters")}
                </Typography>
                <Box display={"flex"} gap={"16px"} alignItems={"center"}>
                  <Box display={"flex"} gap={"12px"}>
                    <CtSelect
                      value={statusTopic.find(
                        (item) => item.id === props.settings.status
                      )}
                      placeholder={t("labels.status")}
                      tagRender={tagRender}
                      mode="tags"
                      maxCount={1}
                      allowClear={true}
                      style={{ minWidth: 160 }}
                      options={statusTopic}
                      onChange={(_, option: any) => {
                        props.setSettings((prev) => ({
                          ...prev,
                          status: option?.[0]?.id ?? null,
                        }));
                      }}
                    />

                    {topicsState?.categories?.length ? (
                      <CtSelect
                        value={constructCategoryValue()}
                        placeholder={t("labels.pick_category")}
                        tagRender={tagRender}
                        mode="tags"
                        maxCount={1}
                        allowClear={true}
                        style={{ minWidth: 160 }}
                        options={topicsState?.categories?.map(
                          (category: Category) => ({
                            id: category.id,
                            value: category.color,
                            label: category.name,
                          })
                        )}
                        onChange={(_, option: any) => {
                          props.setSettings((prev) => ({
                            ...prev,
                            categoryId: option?.[0]?.id ?? null,
                          }));
                        }}
                      />
                    ) : null}
                  </Box>
                  {!isMobile ? (
                    <>
                      <CtLine direction={"vertical"} />

                      <Box display={"flex"} gap={"8px"}>
                        <CtButton
                          icon={<LayoutGrid size={14} />}
                          type={
                            topicsState.settings?.displayMode ===
                            TopicDisplayMode.CARD
                              ? "primary"
                              : "default"
                          }
                          onClick={() =>
                            dispatch(
                              handleSetTopicsSettings({
                                displayMode: TopicDisplayMode.CARD,
                              })
                            )
                          }
                        />

                        <CtButton
                          type={
                            topicsState.settings?.displayMode ===
                            TopicDisplayMode.ROW
                              ? "primary"
                              : "default"
                          }
                          icon={<Rows3 size={14} />}
                          onClick={() =>
                            dispatch(
                              handleSetTopicsSettings({
                                displayMode: TopicDisplayMode.ROW,
                              })
                            )
                          }
                        />
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Box>

              {!isMobile ? (
                <CtButton
                  type={"primary"}
                  danger={true}
                  icon={<Trash2 size={14} />}
                  disabled={props.selectedItems.length === 0}
                  onClick={handleDeleteSelectedTopic}
                >
                  {t("buttons.delete")}
                </CtButton>
              ) : null}
            </Box>
          </Box>
        )}
      </CtHeader>

      <NewTopic
        isOpen={props.openTopic}
        setIsOpen={props.setOpenTopic}
        openCustomize={openCustomize}
        setOpenCustomize={setOpenCustomize}
      />
    </>
  );
};

interface ChHeaderProps {
  setOpenTopic: Dispatch<SetStateAction<boolean>>;
  openTopic: boolean;
  setSettings: Dispatch<SetStateAction<Omit<TopicAllProps, "projectId">>>;
  settings: Omit<TopicAllProps, "projectId">;
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
}

export default ChHeader;
