import { toast } from "react-toastify";
import pkAxios from "./axios";
import { Topic, TopicAllProps } from "../types/topics.type";
import i18n from "../../../i18n";

class TopicsService {
  // Topics
  async getTopics(props: TopicAllProps) {
    try {
      const params = new URLSearchParams({
        projectId: props.projectId,
        ...(props.categoryId && { categoryId: props.categoryId }),
        ...(props.search && { search: props.search }),
        ...(props.sort && { sort: props.sort }),
        ...(props.status && { status: props.status }),
      });

      const response = await pkAxios.get(`/api/topics`, { params });

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async getTopicId(topicId: Topic["id"]) {
    try {
      const response = await pkAxios.get(`/api/topics/${topicId}`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async createTopic(props: Partial<Topic>) {
    try {
      const response = await pkAxios.post(`/api/topics`, { ...props });

      toast.success(i18n.t("toast.create_topic"));

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async updateTopic(props: Partial<Topic>) {
    const topicId = props.id;
    try {
      const response = await pkAxios.patch(`/api/topics/${topicId}`, {
        ...props,
      });

      toast.success(i18n.t("toast.update_topic"));

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async deleteTopic(topicIds: Topic["id"][]) {
    try {
      const response = await pkAxios.delete(`/api/topics`, {
        data: { topicIds },
      });

      toast.success(i18n.t("toast.delete_topic", { count: topicIds.length }));

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }
}

export default TopicsService;
