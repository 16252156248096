import React, { FunctionComponent, ReactNode } from "react";
import { Box } from "@mui/material";

const CtCard: FunctionComponent<CtCardProps> = (props: CtCardProps) => {
  return (
    <Box
      bgcolor={(theme) =>
        !props.hideBg ? theme.palette.common.white : "transparent"
      }
      padding={!props.hideBg ? "24px" : 0}
      borderRadius={"12px"}
    >
      {props.children}
    </Box>
  );
};

interface CtCardProps {
  children: ReactNode;
  hideBg?: boolean;
}

export default CtCard;
