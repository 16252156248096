import { Input, InputProps } from "antd";
import React, { FunctionComponent } from "react";

export interface CtInputProps extends InputProps {
  handleOnPressEnter?: () => void;
}

const CtInput: FunctionComponent<CtInputProps> = (props: CtInputProps) => {
  const handleSubmitOnEnter = () => {
    if (props.handleOnPressEnter) {
      props.handleOnPressEnter();
    }
  };

  return (
    <Input
      {...props}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmitOnEnter();
        }
        if (props.onKeyDown) {
          props.onKeyDown(e);
        }
      }}
    />
  );
};

export default CtInput;
