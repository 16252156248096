import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { LockKeyhole, User as UserIcon, UserPlus } from "lucide-react";
import CtButton from "../../../common/components/CtButton/CtButton";
import { useNavigate, useParams } from "react-router-dom";
import { authService } from "../../../common/utils/services";
import { Input } from "antd";
import { toast } from "react-toastify";
import useDebounce from "../../../common/utils/hooks/useDebounce";
import { useLoading } from "../../../common/utils/hooks/useLoading";
import { AuthType } from "../../../common/utils/types/auth.type";
import { useTranslation } from "react-i18next";

const FormAcceptInvitation = () => {
  const { t } = useTranslation();
  const { tokenId } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();

  const { loadingStates, execWithLoader } = useLoading();
  const [errors, setErrors] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [formRegister, setFormRegister] = useState<AuthType>({
    firstName: "",
    lastName: "",
    password: "",
  });

  const debouncedPassword = useDebounce(formRegister.password, 500);
  const debouncedConfirmPassword = useDebounce(confirmPassword, 500);

  const handleRegister = () => {
    execWithLoader("register", async () => {
      if (
        confirmPassword === formRegister.password &&
        formRegister?.firstName?.length &&
        formRegister?.lastName?.length &&
        formRegister?.password?.length &&
        tokenId &&
        !loadingStates.register
      ) {
        const result = await authService.createUserFromInvitation(
          tokenId,
          formRegister.firstName,
          formRegister.lastName,
          formRegister.password
        );

        if (result?.status === 200) {
          toast.success(t("toast.invitation_accepted"));
          await setTimeout(() => {
            navigate("/login");
          }, 1500);
        }
      }
    });
  };

  useEffect(() => {
    const newErrors: {
      password?: string;
      confirmPassword?: string;
    } = {};
    if (debouncedPassword.length < 5 && debouncedPassword.length > 0) {
      newErrors.password = t("labels.password_five_characters_long");
    }
    if (debouncedConfirmPassword !== debouncedPassword) {
      newErrors.confirmPassword = t("labels.password_mismatch");
    }

    setErrors(newErrors);
  }, [debouncedPassword, debouncedConfirmPassword]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={"16px"}
      width={"75%"}
      mt={"24px"}
    >
      <Box display={"flex"} flexDirection={"column"} gap={"4px"} width={"100%"}>
        <Typography fontWeight={"bold"} variant={"caption"}>
          {t("labels.first_name")}
        </Typography>
        <Input
          placeholder={"Michael"}
          prefix={
            <Box display={"flex"} mr={"4px"}>
              <UserIcon size={14} color={theme.palette.grey[400]} />
            </Box>
          }
          value={formRegister.firstName}
          onChange={(e) =>
            setFormRegister((prev: AuthType) => ({
              ...prev,
              firstName: e.target.value,
            }))
          }
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"4px"} width={"100%"}>
        <Typography fontWeight={"bold"} variant={"caption"}>
          {t("labels.last_name")}
        </Typography>
        <Input
          placeholder={"Jackson"}
          prefix={
            <Box display={"flex"} mr={"4px"}>
              <UserIcon size={14} color={theme.palette.grey[400]} />
            </Box>
          }
          value={formRegister.lastName}
          onChange={(e) =>
            setFormRegister((prev: AuthType) => ({
              ...prev,
              lastName: e.target.value,
            }))
          }
        />
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={"4px"} width={"100%"}>
        <Typography fontWeight={"bold"} variant={"caption"}>
          {t("labels.password")}
        </Typography>
        <Input.Password
          placeholder={t("labels.type_password")}
          autoComplete="new-password"
          status={errors.password ? "error" : undefined}
          prefix={
            <Box display={"flex"} mr={"4px"}>
              <LockKeyhole size={14} color={theme.palette.grey[400]} />
            </Box>
          }
          value={formRegister.password}
          onChange={(e) =>
            setFormRegister((prev: AuthType) => ({
              ...prev,
              password: e.target.value,
            }))
          }
        />
        {errors.password && (
          <Typography color="error" variant="caption">
            {errors.password}
          </Typography>
        )}
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={"4px"} width={"100%"}>
        <Typography fontWeight={"bold"} variant={"caption"}>
          {t("labels.confirm_password")}
        </Typography>
        <Input.Password
          placeholder={t("labels.confirm_password")}
          status={errors.confirmPassword ? "error" : undefined}
          prefix={
            <Box display={"flex"} mr={"4px"}>
              <LockKeyhole size={14} color={theme.palette.grey[400]} />
            </Box>
          }
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {errors.confirmPassword && (
          <Typography color="error" variant="caption">
            {errors.confirmPassword}
          </Typography>
        )}
      </Box>

      <CtButton
        type={"primary"}
        icon={<UserPlus size={14} />}
        style={{ width: "100%" }}
        onClick={handleRegister}
        disabled={
          confirmPassword !== formRegister.password ||
          !confirmPassword.length ||
          !formRegister.password.length ||
          !formRegister.firstName?.length ||
          !formRegister.lastName?.length ||
          loadingStates.register
        }
        loading={loadingStates.register}
      >
        {t("labels.join_team")}
      </CtButton>
    </Box>
  );
};

export default FormAcceptInvitation;
