import { FunctionComponent } from "react";
import CtLayout from "../../../common/components/CtLayout/CtLayout";
import CtHeader from "../../../common/components/CtLayout/CtHeader";
import CtContent from "../../../common/components/CtLayout/CtContent";
import { Box, Typography } from "@mui/material";
import CtComingSoon from "../../../common/components/CtInfoUser/CtComingSoon";
import CtGradientButton from "../../../common/components/CtButton/CtGradientButton";
import { useTranslation } from "react-i18next";

const Statistics: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <CtLayout>
      <CtContent>
        <CtHeader
          title={
            <Box display={"flex"} alignItems={"center"} gap={"18px"}>
              <Typography variant={"h1"}>{t("title.statistics")}</Typography>
              <CtGradientButton />
            </Box>
          }
        />
        <Box padding={"24px"}>
          <CtComingSoon />
        </Box>
      </CtContent>
    </CtLayout>
  );
};

export default Statistics;
