import { Button, ButtonProps } from "antd";
import { FunctionComponent } from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  &.ant-btn > span {
    display: flex;
  }
`;

const CtButton: FunctionComponent<CtButtonProps> = (props: CtButtonProps) => {
  return (
    <StyledButton
      style={{ display: "flex", alignItems: "center" }}
      {...props}
    />
  );
};

export default CtButton;

interface CtButtonProps extends ButtonProps {}
