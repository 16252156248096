import { Node, mergeAttributes } from "@tiptap/core";

const ImageResizePercentage = Node.create({
  name: "image",

  group: "block",

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: "100%",
      },
      height: {
        default: "auto",
      },
      containerStyle: {
        default: "width: 80%; position: relative; display: inline-block;",
      },
      alignStyle: {
        // Attribut pour le conteneur principal qui définit l'alignement
        default: "text-align: center;", // Alignement par défaut
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-align-container]", // Conteneur principal pour l'alignement
        getAttrs: (element) => {
          const container: any = element.querySelector(
            'div[data-type="image-container"]'
          );
          const img = container.querySelector("img");
          return {
            src: img?.getAttribute("src"),
            width: img?.style.width || "100%",
            height: img?.style.height || "auto",
            containerStyle:
              container.style.cssText ||
              "width: 80%; position: relative; display: inline-block;",
            alignStyle: element.style.cssText || "text-align: center;",
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div", // Conteneur principal pour l'alignement
      {
        "data-align-container": "", // Indicateur de conteneur d'alignement
        style: HTMLAttributes.alignStyle, // Appliquer le style d'alignement ici
      },
      [
        "div", // Conteneur de l'image pour le redimensionnement
        {
          "data-type": "image-container",
          style: HTMLAttributes.containerStyle,
        },
        [
          "img",
          mergeAttributes({
            src: HTMLAttributes.src,
            style: `width: ${HTMLAttributes.width}; height: ${HTMLAttributes.height}; cursor: pointer;`,
          }),
        ],
      ],
    ];
  },

  addNodeView() {
    return ({ node, editor, getPos }) => {
      const { src, width, height, containerStyle, alignStyle } = node.attrs;

      const $alignWrapper = document.createElement("div"); // Conteneur principal pour l'alignement
      const $container = document.createElement("div"); // Conteneur pour le redimensionnement
      const $img = document.createElement("img");

      $alignWrapper.setAttribute("style", alignStyle); // Définir l'alignement
      $alignWrapper.setAttribute("data-align-container", ""); // Ajouter un attribut pour le conteneur d'alignement

      $container.setAttribute("style", containerStyle); // Appliquer le style du conteneur de redimensionnement
      $container.setAttribute("data-type", "image-container"); // Ajouter un attribut pour le conteneur de redimensionnement
      $img.setAttribute("src", src);
      $img.style.width = width;
      $img.style.height = height;

      $container.appendChild($img);
      $alignWrapper.appendChild($container);

      // Logique de redimensionnement
      let isResizing = false;
      let startX, startWidth;

      const showResizeHandles = () => {
        // Ajoutez les poignées de redimensionnement
        Array.from({ length: 4 }, (_, index) => {
          const $dot = document.createElement("div");
          $dot.setAttribute(
            "style",
            `position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; cursor: nwse-resize;`
          );
          if (index === 0)
            ($dot.style.top = "-4px"), ($dot.style.left = "-4px");
          if (index === 1)
            ($dot.style.top = "-4px"), ($dot.style.right = "-4px");
          if (index === 2)
            ($dot.style.bottom = "-4px"), ($dot.style.left = "-4px");
          if (index === 3)
            ($dot.style.bottom = "-4px"), ($dot.style.right = "-4px");

          $dot.addEventListener("mousedown", (e) => {
            e.preventDefault();
            isResizing = true;
            startX = e.clientX;
            startWidth = $container.offsetWidth;

            const onMouseMove = (e) => {
              if (!isResizing) return;
              const deltaX =
                index % 2 === 0 ? -(e.clientX - startX) : e.clientX - startX;
              const newWidthPx = startWidth + deltaX;
              const newWidthPercent =
                (newWidthPx / $alignWrapper.offsetWidth) * 100;
              $container.style.width = `${newWidthPercent}%`;
            };

            const onMouseUp = () => {
              if (isResizing) {
                isResizing = false;
              }
              dispatchNodeView();
              document.removeEventListener("mousemove", onMouseMove);
              document.removeEventListener("mouseup", onMouseUp);
            };

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
          });

          $container.appendChild($dot);
        });
      };

      const hideResizeHandles = () => {
        // Retirer la bordure et les poignées de redimensionnement
        $container.style.border = "none";
        while ($container.childElementCount > 1) {
          $container.removeChild($container.lastChild as any);
        }
      };

      $container.addEventListener("click", () => {
        hideResizeHandles();
        showResizeHandles();
      });

      document.addEventListener("click", (e) => {
        const $target = e.target;
        if (!$container.contains($target as any) && $container !== $target) {
          hideResizeHandles();
        }
      });

      const dispatchNodeView = () => {
        if (typeof getPos === "function") {
          const newAttrs = {
            ...node.attrs,
            containerStyle: $container.style.cssText, // Mettre à jour le style du conteneur
            alignStyle: $alignWrapper.style.cssText, // Mettre à jour le style d'alignement
          };
          editor.view.dispatch(
            editor.view.state.tr.setNodeMarkup(getPos(), null, newAttrs)
          );
        }
      };

      return {
        dom: $alignWrapper,
      };
    };
  },
});

export default ImageResizePercentage;
