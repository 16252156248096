import { User } from "lucide-react";
import styled from "styled-components";
import { convertHexToRGBA } from "../../../common/utils/functions";

const StyledBgUserIcon = styled.div`
  position: relative;

  padding: 18px;
  border-radius: 50%;
  background: rgb(245 245 245 / 29%);
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    position: absolute;
    z-index: 0;
    content: "";
    border-radius: 50%;
    top: 0;
    background: linear-gradient(
      to bottom,
      rgb(238 244 252) 20%,
      rgb(244 249 255) 100%
    );
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const StyledUserIcon = styled.div`
  position: relative;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 14px;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) =>
    `${convertHexToRGBA(theme.palette.text.primary, 0.05)} 0px 12px 24px`};
`;

const UserIcon = () => {
  return (
    <StyledBgUserIcon>
      <StyledUserIcon>
        <User />
      </StyledUserIcon>
    </StyledBgUserIcon>
  );
};

export default UserIcon;
