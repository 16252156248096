import axios from "axios";
import { authHeader } from "./axios-header";

export const apiBaseUrl = import.meta.env.VITE_BACK_URL;

const ctAxios = axios.create({
  baseURL: apiBaseUrl,
  responseType: "json",
});

ctAxios.interceptors.request.use(
  async function (config: any) {
    config.headers = { ...config.headers, ...authHeader() };
    return config;
  },
  function (error?: unknown) {
    return Promise.reject(error);
  }
);

ctAxios.interceptors.response.use(
  async function (response) {
    return Promise.resolve(response);
  },
  async function (error) {
    const errorMessage = error.response?.data?.error;

    if (
      error.response.status === 401 &&
      errorMessage !== "Invalid email or password"
    ) {
      localStorage.clear();
      window.location.href = "/login";
    }

    if (error.response.status === 403 || error.response.status === 504) {
      localStorage.clear();
      window.location.href = "/login";
    }

    if (error.response.status === 429) {
      // Afficher erreur
    }

    return Promise.reject(error);
  }
);

export default ctAxios;
