import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import CtDrawer, {
  CtDrawerProps,
} from "../../../../common/components/CtDrawer/CtDrawer";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Divider, Dropdown, Input, Tag, Tooltip } from "antd";
import CtWysiwyg from "../../../../common/components/CtWysiwyg/CtWysiwyg";
import PostPreview from "./PostPreview";
import CtButton from "../../../../common/components/CtButton/CtButton";
import { Brush, CirclePlus, Copy, Save } from "lucide-react";
import DropdownActions from "../DropdownActions";
import {
  ElementsSettings,
  TopicStatus,
} from "../../../../common/utils/types/topics.type";
import TagDetails from "../TagDetails";
import CTADetails from "../CTADetails";
import {
  handleCreateNewTopic,
  handleSelectTopic,
  handleUpdateExistingTopic,
} from "../../../../app/store/actions/topics.action";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../common/utils/hooks/reduxHook";
import CtSelect from "../../../../common/components/CtSelect/CtSelect";
import { useLoading } from "../../../../common/utils/hooks/useLoading";
import CategoriesList from "./CategoriesList";
import TopicBlockButtons from "./BlockButtons";
import DrawerTheme from "./DrawerTheme";
import { toast } from "react-toastify";
import CtGradientButton from "../../../../common/components/CtButton/CtGradientButton";
import { useTranslation } from "react-i18next";
import { statusTopic } from "../../../../common/utils/constants/topics.consts";
import { Breakpoints } from "../../../../common/utils/types/app.type";

const initialValue: ElementsSettings = {
  id: "",
  displayTag: {
    finalRender: false,
    visible: false,
    id: "",
    name: "",
    color: "",
  },
  displayMinutes: true,
  displayCTA: {
    id: "",
    visible: false,
    text: "Button",
    link: "",
  },
  displayLikes: true,
};

const NewTopic: FunctionComponent<NewTopicProps> = (props: NewTopicProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const selectedTopic = useAppSelector((state) => state.topics.selectedTopic);

  const { loadingStates, execWithLoader } = useLoading();

  const [title, setTitle] = useState<string>("");
  const [editorValue, setEditorValue] = useState<string>("");
  const [plainText, setPlainText] = useState<string>("");

  const [topicStatus, setTopicStatus] = useState<TopicStatus | null>(null);
  const [settings, setSettings] = useState<ElementsSettings>(initialValue);

  useEffect(() => {
    if (props.isOpen && !selectedTopic) {
      setTopicStatus(TopicStatus.DRAFT);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (selectedTopic) {
      setTitle(selectedTopic?.title);
      setEditorValue(selectedTopic?.body);
      setPlainText(selectedTopic?.plainText);
      setTopicStatus(selectedTopic?.status);
      setSettings({
        ...selectedTopic.settings,
        id: selectedTopic.settings.id,
        displayCTA: {
          ...selectedTopic.settings.displayCTA,
          id: selectedTopic.settings.displayCTA.id,
        },
      });
    }
  }, [selectedTopic]);

  const handleCloseNewTopic = () => {
    props.setIsOpen(false);
    setSettings(initialValue);
    setTitle("");
    setEditorValue("");
    setPlainText("");
    setTopicStatus(null);
    dispatch(handleSelectTopic(null));
  };

  const handleCreateTopic = async () => {
    execWithLoader("createTopic", async () => {
      const setCategoryId = () => {
        return !settings.displayTag.visible ||
          settings?.displayTag?.id?.length === 0
          ? null
          : settings?.displayTag?.id;
      };

      await dispatch(
        handleCreateNewTopic({
          title,
          body: editorValue,
          plainText: plainText,
          categoryId: setCategoryId(),
          status: topicStatus,
          projectId: appState?.selectedProject?.id,
          cta: settings?.displayCTA,
          settings: {
            displayMinutes: settings?.displayMinutes,
            displayLikes: settings?.displayLikes,
            displayCTAId: settings?.displayCTA?.visible,
          },
        })
      );

      await handleCloseNewTopic();
    });
  };

  const handleUpdateTopic = async () => {
    execWithLoader("updateTopic", async () => {
      const setCategoryId = () => {
        return !settings.displayTag.visible ||
          settings?.displayTag?.id?.length === 0
          ? null
          : settings?.displayTag?.id;
      };

      if (selectedTopic?.id) {
        await dispatch(
          handleUpdateExistingTopic({
            id: selectedTopic?.id,
            title,
            body: editorValue,
            plainText: plainText,
            categoryId: setCategoryId(),
            status: topicStatus,
            cta: {
              visible: settings.displayCTA.visible,
              text: settings.displayCTA.text,
              link: settings.displayCTA.link,
            },
            settings: {
              id: settings.id,
              displayMinutes: settings.displayMinutes,
              displayLikes: settings.displayLikes,
              displayCTA: settings.displayCTA,
              displayTag: {
                id: settings.displayTag.id,
                visible: settings.displayTag.visible,
                finalRender: settings.displayTag.finalRender,
                name: settings.displayTag.name,
                color: settings.displayTag.color,
              },
            },
          })
        );
      }

      handleCloseNewTopic();
    });
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(selectedTopic?.id ?? "");
    toast.success(t("toast_topic_id_copied"));
  };

  return (
    <>
      <CtDrawer
        {...props}
        title={
          selectedTopic ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={"bold"}>
                {isMobile
                  ? t("title.previzualisation")
                  : t("title.update_topic")}
              </Typography>

              {selectedTopic?.id ? (
                <>
                  <Tag style={{ display: "flex", alignItems: "center" }}>
                    <Typography noWrap width={isMobile ? "25vw" : "100%"}>
                      {selectedTopic?.id}
                    </Typography>

                    <CtButton
                      type={"link"}
                      icon={<Copy size={14} />}
                      onClick={handleCopy}
                    />
                  </Tag>
                  {isMobile ? null : <div></div>}
                </>
              ) : null}
            </Box>
          ) : (
            <Box display={"flex"} alignItems={"center"} gap={"16px"}>
              <Typography fontWeight={"bold"}>
                {t("title.new_topic")}
              </Typography>
              <CtGradientButton />
            </Box>
          )
        }
        zIndex={40}
        style={{ padding: 0 }}
        displayConfirm={!isMobile}
        onCloseExtra={handleCloseNewTopic}
        confirm={{
          title: t("title.leave_topic_creation"),
          description: t("labels.leave_topic_creation"),
        }}
        extra={
          !isMobile && (
            <Box display={"flex"} gap={"8px"} alignItems={"center"}>
              {!props.openCustomize ? (
                <>
                  <CtButton
                    type={"dashed"}
                    icon={<Brush size={14} />}
                    onClick={() => props.setOpenCustomize(true)}
                  >
                    {t("buttons.customize_me")}
                  </CtButton>
                  <Divider type={"vertical"} style={{ height: "24px" }} />
                </>
              ) : null}
              {selectedTopic ? (
                <>
                  <TopicBlockButtons
                    isHovered={true}
                    topic={selectedTopic}
                    hideEdit={true}
                    extraDelete={handleCloseNewTopic}
                  />
                  <Divider type={"vertical"} style={{ height: "24px" }} />
                </>
              ) : null}
              <Typography fontWeight={"bold"}>{t("labels.status")}:</Typography>
              <CtSelect
                placeholder={t("labels.pick_status")}
                value={statusTopic.find((item) => item.id === topicStatus)}
                options={statusTopic}
                onChange={(_, item: any) => {
                  setTopicStatus(item.id);
                }}
                style={{ minWidth: "125px" }}
              />
              <Tooltip
                title={
                  props.openCustomize
                    ? t("labels.finish_customize_first")
                    : title === ""
                    ? t("labels.fill_title_first")
                    : null
                }
              >
                <CtButton
                  type={"primary"}
                  icon={<Save size={14} />}
                  loading={loadingStates.createTopic}
                  disabled={title === "" || props.openCustomize}
                  onClick={
                    selectedTopic ? handleUpdateTopic : handleCreateTopic
                  }
                >
                  {selectedTopic
                    ? t("buttons.update_topic")
                    : t("buttons.save_topic")}
                </CtButton>
              </Tooltip>
            </Box>
          )
        }
      >
        <Box display={"flex"} height={"100%"}>
          {!isMobile ? (
            <>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"24px"}
                width={"25%"}
                bgcolor={"#028cf30d"}
                padding={"16px 24px"}
                overflow={"auto"}
              >
                <CategoriesList />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                width={"45%"}
                height={"100%"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"16px"}
                  width={"80%"}
                  height={"100%"}
                  padding={"36px 0"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                    <Dropdown
                      dropdownRender={() => (
                        <DropdownActions
                          settings={settings}
                          setSettings={setSettings}
                        />
                      )}
                      trigger={["click"]}
                    >
                      <CtButton icon={<CirclePlus size={14} />} />
                    </Dropdown>

                    {settings.displayTag.visible ? (
                      <>
                        <Divider type={"vertical"} style={{ height: "75%" }} />

                        <TagDetails
                          settings={settings}
                          setSettings={setSettings}
                        />
                      </>
                    ) : null}

                    {settings.displayCTA.visible ? (
                      <>
                        <Divider type={"vertical"} style={{ height: "75%" }} />

                        <CTADetails
                          settings={settings}
                          setSettings={setSettings}
                        />
                      </>
                    ) : null}
                  </Box>

                  <Input
                    placeholder={t("labels.awesome_title")}
                    allowClear={true}
                    autoFocus={true}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <CtWysiwyg
                    defaultValue={editorValue}
                    onChange={(value) => setEditorValue(value)}
                    setPlainText={(value) => setPlainText(value)}
                  />
                </Box>
              </Box>
            </>
          ) : null}

          <PostPreview
            plainText={plainText}
            editorValue={editorValue}
            title={title}
            setSettings={setSettings}
            settings={settings}
          />
        </Box>
      </CtDrawer>

      <DrawerTheme
        openCustomize={props.openCustomize}
        setOpenCustomize={props.setOpenCustomize}
      />
    </>
  );
};

interface NewTopicProps extends CtDrawerProps {
  openCustomize: boolean;
  setOpenCustomize: Dispatch<SetStateAction<boolean>>;
}

export default NewTopic;
