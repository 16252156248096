import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
} from "react";
import { Box, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Clock, Heart, X } from "lucide-react";
import CtButton from "../../../../common/components/CtButton/CtButton";
import {
  calculateReadingTime,
  convertHexToRGBA,
} from "../../../../common/utils/functions";
import { Tag } from "antd";
import { ElementsSettings } from "../../../../common/utils/types/topics.type";
import { useAppSelector } from "../../../../common/utils/hooks/reduxHook";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../../../common/utils/types/app.type";

const StyledPreview = styled(Box)<{ $bgColor?: string }>`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: -24px;
    left: -24px;
    width: calc(100% + 48px);
    height: 150px;
    background-color: ${({ theme, $bgColor }) =>
      $bgColor ?? theme.palette.secondary.main};
  }
`;

const StyledBody = styled.div<{ textColor: string }>`
  overflow-wrap: break-word;
  color: ${({ textColor }) => textColor};

  p,
  h1,
  h2 {
    margin: 0;
  }
`;

const PostPreview: FunctionComponent<PostPreviewProps> = (
  props: PostPreviewProps
) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const appState = useAppSelector((state) => state.app);
  const topicsState = useAppSelector((state) => state.topics);
  const theme = useTheme();

  useEffect(() => {
    const findItem = topicsState?.categories?.find(
      (item) => item.id === props.settings.displayTag.id
    );

    props.setSettings((prev: ElementsSettings) => ({
      ...prev,
      displayTag: {
        ...prev.displayTag,
        id: findItem?.id ?? prev.displayTag.id,
        name: findItem?.name ?? prev.displayTag.name,
        color: findItem?.color ?? prev.displayTag.color,
      },
    }));
  }, [topicsState.categories]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"24px"}
      width={isMobile ? "100%" : "30%"}
      bgcolor={"#028cf30d"}
      padding={"16px"}
    >
      <Typography variant={"h2"}>{t("title.topic_preview")}</Typography>

      <Box
        bgcolor={appState.selectedProject?.settings?.background}
        height={"100%"}
        borderRadius={"0 0 8px 8px"}
        position={"relative"}
        overflow={"auto"}
        sx={{
          scrollbarColor: "transparent rgba(0, 0, 0, .5)",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, .5)",
            borderRadius: "8px",
          },
        }}
      >
        <Box
          bgcolor={(theme) =>
            appState?.selectedProject?.settings?.backgroundTopColor ??
            theme.palette.secondary.main
          }
          padding={"24px"}
          color={(theme) => theme.palette.text.secondary}
          position={"sticky"}
          top={0}
          zIndex={"10"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              variant={"h3"}
              sx={{ textTransform: "uppercase" }}
              color={(theme) =>
                appState.selectedProject?.settings?.titleColor ??
                theme.palette.common.white
              }
            >
              {appState.selectedProject?.settings?.title}
            </Typography>
            <X
              size={20}
              style={{
                display: "flex",
                color: appState.selectedProject?.settings?.titleColor,
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>

        <StyledPreview
          minHeight={"100%"}
          m={"24px"}
          $bgColor={appState.selectedProject?.settings?.backgroundTopColor}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            gap={"32px"}
            bgcolor={(theme) =>
              appState.selectedProject?.settings?.backgroundBody ??
              theme.palette.common.white
            }
            color={(theme) => theme.palette.text.primary}
            boxShadow={(theme) =>
              `${convertHexToRGBA(
                theme.palette.text.primary,
                0.1
              )} 0px 12px 24px`
            }
            minHeight={"200px"}
            borderRadius={"8px"}
            padding={"16px"}
            justifyContent={"space-between"}
            position={"relative"}
            zIndex={"9"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"8px"}
              width={"100%"}
              height={"100%"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={
                  props.settings.displayTag.finalRender
                    ? "space-between"
                    : "flex-start"
                }
                gap={"16px"}
              >
                {props.settings.displayTag.finalRender ? (
                  <Tag
                    color={props.settings.displayTag.color ?? ""}
                    style={{ margin: 0 }}
                  >
                    {props.settings.displayTag.name}
                  </Tag>
                ) : (
                  <Typography
                    variant={"h3"}
                    sx={{ flex: 5, overflowWrap: "anywhere" }}
                  >
                    {props.title}
                  </Typography>
                )}

                {props.settings.displayMinutes ? (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"4px"}
                    flex={1}
                    justifyContent={"flex-end"}
                  >
                    <Clock
                      size={12}
                      color={appState.selectedProject?.settings?.textColor}
                    />
                    <Typography
                      variant={"caption"}
                      color={appState.selectedProject?.settings?.textColor}
                      style={{ textWrap: "nowrap" }}
                    >
                      {`${calculateReadingTime(props.plainText)}" ${t(
                        "labels.read"
                      )}`}
                    </Typography>
                  </Box>
                ) : null}
              </Box>

              {props.settings.displayTag.finalRender ? (
                <Typography
                  variant={"h3"}
                  sx={{ flex: 5, overflowWrap: "anywhere" }}
                >
                  {props.title}
                </Typography>
              ) : null}

              <StyledBody
                textColor={
                  appState.selectedProject?.settings?.textColor ??
                  theme.palette.common.black
                }
                dangerouslySetInnerHTML={{ __html: props.editorValue }}
              />
            </Box>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={
                props.settings.displayTag ? "space-between" : "flex-start"
              }
              gap={"16px"}
            >
              {props.settings.displayLikes ? (
                <Box display={"flex"}>
                  <CtButton icon={<Heart size={14} />}>
                    {topicsState.selectedTopic?.likeCount}
                  </CtButton>
                </Box>
              ) : (
                <div></div>
              )}

              {props.settings.displayCTA.visible ? (
                <CtButton
                  type={"primary"}
                  onClick={() =>
                    window.open(
                      props.settings.displayCTA.link,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  style={{
                    backgroundColor:
                      appState.selectedProject?.settings?.ctaBgColor ??
                      undefined,
                    color:
                      appState.selectedProject?.settings?.ctaTextColor ??
                      undefined,
                  }}
                >
                  {props.settings.displayCTA.text}
                </CtButton>
              ) : null}
            </Box>
          </Box>
        </StyledPreview>

        <Box
          position={"sticky"}
          width={"100%"}
          textAlign={"center"}
          bottom={0}
          p={"8px 0"}
          bgcolor={appState.selectedProject?.settings?.background}
          borderRadius={"10px 10px 8px 8px"}
          zIndex={10}
        >
          <Typography color={appState.selectedProject?.settings?.textColor}>
            {t("labels.powered_by")}{" "}
            <Link
              sx={{
                color: appState.selectedProject?.settings?.textColor,
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              Toolio
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface PostPreviewProps {
  plainText: string;
  editorValue: string;
  title: string;
  settings: ElementsSettings;
  setSettings: Dispatch<SetStateAction<ElementsSettings>>;
}

export default PostPreview;
