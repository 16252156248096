import React, { Fragment } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../common/utils/hooks/reduxHook";
import { Category, Topic } from "../../../../common/utils/types/topics.type";
import { Tag } from "antd";
import { Box, Typography } from "@mui/material";
import CtLine from "../../../../common/components/CtLine/CtLine";
import { handleSelectTopic } from "../../../../app/store/actions/topics.action";
import { StyledNavItem } from "../../../../common/components/Navbar/Items";

interface CategorizedTopic {
  name: string;
  color: string | null;
  topics: Topic[];
}

type CategorizedTopics = Record<string, CategorizedTopic>;

const CategoriesList = () => {
  const dispatch = useAppDispatch();
  const topicsState = useAppSelector((state) => state.topics);

  const categorizedTopics: CategorizedTopics = {};

  topicsState?.categories?.forEach((category: Category) => {
    categorizedTopics[category.id] = {
      name: category.name,
      color: category.color,
      topics: [],
    };
  });

  categorizedTopics["others"] = {
    name: "Autre",
    color: "",
    topics: [],
  };

  topicsState?.list?.forEach((topic: Topic) => {
    if (
      topic?.settings?.displayTag?.id &&
      categorizedTopics[topic?.settings?.displayTag?.id]
    ) {
      categorizedTopics[topic?.settings?.displayTag?.id].topics.push(topic);
    } else {
      categorizedTopics["others"].topics.push(topic);
    }
  });

  let isFirstCategory = true;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"24px"}
    >
      {Object.keys(categorizedTopics).map((categoryId) => {
        if (categorizedTopics[categoryId].topics.length > 0) {
          const content = (
            <Fragment key={categoryId}>
              {!isFirstCategory && <CtLine />}
              <Tag color={categorizedTopics[categoryId].color ?? "red"}>
                {categorizedTopics[categoryId].name}
              </Tag>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"24px"}
                width={"100%"}
              >
                {categorizedTopics[categoryId].topics.map((topic: Topic) => {
                  return (
                    <StyledNavItem
                      key={topic.id}
                      isActive={topic.id === topicsState.selectedTopic?.id}
                      onClick={() => dispatch(handleSelectTopic(topic.id))}
                    >
                      <Typography
                        fontWeight={"bold"}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {topic.title}
                      </Typography>
                    </StyledNavItem>
                  );
                })}
              </Box>
            </Fragment>
          );
          isFirstCategory = false;
          return content;
        }
        return null;
      })}
    </Box>
  );
};

export default CategoriesList;
