import AuthLayout from "./AuthLayout";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Key, Mail } from "lucide-react";
import { Input } from "antd";
import CtButton from "../../common/components/CtButton/CtButton";
import UserIcon from "./components/UserIcon";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../common/utils/types/app.type";

const ForgotPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  return (
    <AuthLayout>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        height={"calc(100% - 75px)"}
        justifyContent={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"32px"}
          width={isMobile ? "100%" : "75%"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            textAlign={"center"}
            gap={"8px"}
          >
            <UserIcon />
            <Typography variant={"h1"}>{t("title.forgot_password")}</Typography>
            <Typography variant={"caption"}>
              {t("labels.forgot_password_subtitle")}
            </Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"16px"}
            width={isMobile ? "100%" : "75%"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Typography fontWeight={"bold"} variant={"caption"}>
                {t("labels.email")}
              </Typography>
              <Input
                placeholder={"michael.jackson@youhou.com"}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <Mail size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
              />
            </Box>
            <CtButton
              type={"primary"}
              icon={<Key size={14} />}
              style={{ width: "100%" }}
            >
              {t("buttons.forgot_password")}
            </CtButton>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ForgotPassword;
