import Input, { PasswordProps } from "antd/es/input";
import React, { FunctionComponent } from "react";
import { CtInputProps } from "./CtInput";

interface CtInputPasswordProps extends CtInputProps, PasswordProps {}

const CtInputPassword: FunctionComponent<CtInputPasswordProps> = (
  props: CtInputPasswordProps
) => {
  return (
    <Input.Password
      {...props}
      onKeyDown={(e) => {
        if (e.key === "Enter" && props.handleOnPressEnter) {
          props.handleOnPressEnter();
        }
        if (props.onKeyDown) {
          props.onKeyDown(e);
        }
      }}
    />
  );
};

export default CtInputPassword;
