import { createTheme } from "@mui/material";
import {
  ctBlack,
  ctBlue,
  ctBlueGreyMiddle,
  ctDarkBlue,
  ctDarkGreen,
  ctDarkRed,
  ctDarkSecondary,
  ctDarkYellow,
  ctGreen,
  ctLightBlue,
  ctLightGreen,
  ctLightRed,
  ctLightSecondary,
  ctLightYellow,
  ctOriginalBlack,
  ctOriginalWhite,
  ctRed,
  ctSecondary,
  ctWhite,
  ctYellow,
  lineColor,
} from "./colors";

export const ctTheme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "Inter", "sans-serif"].join(","),
    h1: {
      fontFamily: "Montserrat, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      textTransform: "none",
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "20px",
      textTransform: "none",
    },
    h3: {
      fontFamily: "Montserrat, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      textTransform: "none",
    },
    subtitle1: {
      fontFamily: "Montserrat, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      textTransform: "none",
    },
    body1: {
      fontFamily: "Montserrat, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      textTransform: "none",
    },
    body2: {
      fontFamily: "Inter, sans-serif",
      fontSize: "14px",
      textTransform: "none",
    },
    caption: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      textTransform: "none",
    },
    button: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "12px",
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      dark: ctDarkBlue,
      main: ctBlue,
      light: ctLightBlue,
    },
    secondary: {
      dark: ctDarkSecondary,
      main: ctSecondary,
      light: ctLightSecondary,
    },
    warning: {
      dark: ctDarkYellow,
      main: ctYellow,
      light: ctLightYellow,
    },
    error: {
      dark: ctDarkRed,
      main: ctRed,
      light: ctLightRed,
    },
    success: {
      dark: ctDarkGreen,
      main: ctGreen,
      light: ctLightGreen,
    },
    text: {
      primary: ctBlack,
      secondary: ctWhite,
      disabled: ctBlueGreyMiddle,
    },
    common: {
      black: ctOriginalBlack,
      white: ctOriginalWhite,
    },
    divider: lineColor,
  },
});
