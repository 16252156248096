import { toast } from "react-toastify";
import pkAxios from "./axios";

class CategoriesService {
  async getCategories(projectId: string) {
    try {
      const response = await pkAxios.get(`/api/categories/${projectId}`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async createCategories(name: string, color: string, projectId: string) {
    try {
      const response = await pkAxios.post(`/api/categories`, {
        name,
        color,
        projectId,
      });

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async updateCategory(id: string, name: string, color: string) {
    try {
      const response = await pkAxios.put(`/api/categories/${id}`, {
        name,
        color,
      });

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async deleteCategory(id: string) {
    try {
      const response = await pkAxios.delete(`/api/categories/${id}`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }
}

export default CategoriesService;
