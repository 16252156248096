import { Box, Typography, useMediaQuery } from "@mui/material";
import { Tag } from "antd";
import { CalendarFold, Eye } from "lucide-react";
import { FunctionComponent, useState } from "react";
import { styled } from "styled-components";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../common/utils/hooks/reduxHook";
import { TopicDisplayMode } from "../../../../common/utils/types/topics.type";
import TopicBlockButtons, { StyledButtons } from "./BlockButtons";
import dayjs from "dayjs";
import { handleSelectTopic } from "../../../../app/store/actions/topics.action";
import { getStatus } from "../../../../common/utils/functions";
import AddToListButton from "./AddToListButton";
import { TopicItemProps } from "../../../../common/utils/constants/topics.consts";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../../../common/utils/types/app.type";

const StyledTopic = styled.div<{
  $isHovered: boolean;
  isRowMode?: boolean;
  isMobile?: boolean;
  isSelected: boolean;
}>`
  position: relative;
  display: flex;
  align-items: ${({ isRowMode }) => (isRowMode ? "center" : "flex-start")};
  flex-direction: ${({ isRowMode }) => (isRowMode ? "row" : "column")};
  gap: ${({ isRowMode }) => (isRowMode ? "8px" : "16px")};
  width: ${({ isRowMode, isMobile }) =>
    isMobile || isRowMode ? "100%" : "calc(33% - 7px)"};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.palette.primary.main : theme.palette.divider};
  border-radius: 8px;
  padding: 16px 22px;
  cursor: pointer;
  color: ${({ $isHovered, theme }) =>
    $isHovered ? theme.palette.primary.main : theme.palette.text.primary};
  transition: 0.3s;
`;

const TopicItem: FunctionComponent<TopicItemProps> = (
  props: TopicItemProps
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const [isHovered, setIsHovered] = useState(false);
  const topics = useAppSelector((state) => state.topics);
  const isRowMode = topics.settings?.displayMode === TopicDisplayMode.ROW;

  const selectTopic = () => {
    dispatch(handleSelectTopic(props.topic.id));
    props.setOpenTopic(true);
    props.setSelectedItems([]);
  };

  return (
    <StyledTopic
      isMobile={isMobile}
      isSelected={Boolean(
        props.topic.id && props.selectedItems.includes(props.topic.id)
      )}
      isRowMode={isRowMode}
      $isHovered={isHovered}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={selectTopic}
    >
      {isHovered ||
      (props.topic.id && props.selectedItems.includes(props.topic.id)) ? (
        <AddToListButton {...props} />
      ) : null}

      {isRowMode ? (
        <Box display={"flex"} position={"absolute"} top={"-12px"}>
          <Tag
            color={getStatus(props?.topic?.status)?.color}
            style={{ display: "flex", alignItems: "center", gap: "4px" }}
          >
            {getStatus(props?.topic?.status)?.text}
          </Tag>
          <Tag color={props.topic?.settings?.displayTag?.color ?? ""}>
            {props.topic?.settings?.displayTag?.name ?? "Autre"}
          </Tag>
        </Box>
      ) : null}

      {!isRowMode ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={!isRowMode ? "100%" : "auto"}
          height={!isRowMode ? "32px" : "auto"}
        >
          <Box display={"flex"} minWidth={"60px"} flex={1}>
            <Tag
              color={getStatus(props?.topic?.status)?.color}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              {getStatus(props?.topic?.status)?.text}
            </Tag>

            <Tag
              color={props.topic?.settings?.displayTag?.color ?? ""}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              {props.topic?.settings?.displayTag?.name ?? "Autre"}
            </Tag>
          </Box>

          <TopicBlockButtons isHovered={isHovered} topic={props.topic} />
        </Box>
      ) : null}

      <Box
        position={"relative"}
        display={"flex"}
        flexDirection={isRowMode ? "row" : "column"}
        alignItems={isRowMode ? "center" : "flex-start"}
        justifyContent={"space-between"}
        gap={isRowMode ? "48px" : "24px"}
        width={"100%"}
        height={"100%"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"4px"} flex={4}>
          <Box display={"flex"} alignItems={"center"} gap={"8px"}>
            <Typography
              variant={"h3"}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {props?.topic?.title}
            </Typography>
          </Box>
          <Typography
            color={(theme) => theme.palette.secondary.main}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: isRowMode ? "2" : "4",
              WebkitBoxOrient: "vertical",
            }}
          >
            {props?.topic?.plainText}
          </Typography>
        </Box>

        {isRowMode ? (
          <Box
            position={"absolute"}
            top={"50%"}
            right={0}
            zIndex={9}
            sx={{ transform: "translateY(-50%)" }}
          >
            <TopicBlockButtons isHovered={isHovered} topic={props.topic} />
          </Box>
        ) : null}

        <StyledButtons
          display={"flex"}
          justifyContent={"flex-end"}
          flex={1}
          $isHovered={!isRowMode || !isHovered}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Tag
              icon={<CalendarFold size={12} />}
              color="default"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography variant={"caption"}>
                {!isMobile ? `${t("labels.last_updated")} - ` : null}
                {dayjs(props?.topic?.updatedAt).format("DD/MM/YYYY")}
              </Typography>
            </Tag>

            {props.topic?.viewCount !== undefined &&
            props.topic?.viewCount > 0 ? (
              <Tag
                icon={<Eye size={12} />}
                color="default"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography variant={"caption"}>
                  {props?.topic?.viewCount}
                </Typography>
              </Tag>
            ) : null}
          </Box>
        </StyledButtons>
      </Box>
    </StyledTopic>
  );
};

export default TopicItem;
