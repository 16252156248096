/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectSettings } from "../../../common/utils/types/project.type";
import { projectService } from "../../../common/utils/services";

export const handleUpdateProjectsettings = createAsyncThunk(
  "app/settings",
  async (settings: ProjectSettings, { rejectWithValue, getState }) => {
    try {
      const state = getState() as any;
      const appState = state.app;
      const { id, ...rest } = settings;

      const response = await projectService.updateSettings({
        projectId: appState.selectedProject?.id,
        settings: rest,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleCreateProject = createAsyncThunk(
  "app/newProject",
  async (projectName: string, { rejectWithValue }) => {
    try {
      const response = await projectService.createProject(projectName);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleUpdateProject = createAsyncThunk(
  "app/updateProject",
  async (projectName: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as any;
      const appState = state.app;

      const response = await projectService.updateProject(
        appState.selectedProject?.id,
        projectName
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleDeleteProject = createAsyncThunk(
  "app/deleteProject",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as any;
      const appState = state.app;
      const userState = state.user;

      const projectId = appState.selectedProject?.id;
      const listProjectFiltered = userState.infos.projects.filter(
        (item) => item.id !== projectId
      );

      const response = await projectService.deleteProject(projectId);

      setTimeout(() => {
        if (response?.status === 204) {
          if (listProjectFiltered.length > 0) {
            window.location.href = "/";
          }
        }
      }, 250);

      return projectId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
