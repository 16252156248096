import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Typography)`
  color: ${({ theme, color }) => color ?? theme.palette.primary.main};
  transition: 0.5s;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    transition: 0.5s;
    opacity: 0.5;
  }
`;

const StyledA = styled.a<{ $color?: string }>`
  text-decoration: none;
  color: ${({ theme, $color }) => $color ?? theme.palette.primary.main};
  transition: 0.5s;
  cursor: pointer;
  font-weight: bold;

  font-size: 14px;
  &:hover {
    transition: 0.5s;
    opacity: 0.5;
  }
`;

const CtLink: React.FunctionComponent<CtLinkProps> = (props: CtLinkProps) => {
  const navigate = useNavigate();

  const handleNavigate = (link: string) => {
    navigate(link);
  };

  return props.external ? (
    <StyledA
      href={props.href}
      rel="noreferrer"
      target={"_blank"}
      $color={props.color}
    >
      {props.label}
    </StyledA>
  ) : (
    <StyledLink
      as={"a"}
      fontWeight={"bold"}
      color={props.color}
      onClick={() => handleNavigate(props.href)}
    >
      {props.label}
    </StyledLink>
  );
};

interface CtLinkProps {
  external?: boolean;
  color?: string;
  href: string;
  label: string | React.ReactNode;
}

export default CtLink;
