import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { Divider, Tag } from "antd";
import { convertHexToRGBA } from "../../../common/utils/functions";
import { Clock, Heart } from "lucide-react";
import CtButton from "../../../common/components/CtButton/CtButton";
import { ElementsSettings } from "../../../common/utils/types/topics.type";
import { useAppSelector } from "../../../common/utils/hooks/reduxHook";
import { useTranslation } from "react-i18next";

export const StyledDropdown = styled.div<{
  minWidth?: string;
  padding?: string;
  marginTop?: string;
}>`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: ${({ padding }) => padding ?? "16px"};
  min-width: ${({ minWidth }) => minWidth ?? "360px"};
  margin-top: ${({ marginTop }) => marginTop ?? 0};
`;

const StyledCard = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? convertHexToRGBA(theme.palette.primary.main, 0.1)
      : theme.palette.common.white};
  box-shadow: ${({ theme }) =>
    `${convertHexToRGBA(theme.palette.text.primary, 0.02)} 0px 12px 24px`};
  border-radius: 8px;
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.palette.primary.main : theme.palette.divider};
  padding: 16px;
  width: 100px;
  height: 100px;
`;

const DropdownActions: FunctionComponent<DropdownActionsProps> = (
  props: DropdownActionsProps
) => {
  const { t } = useTranslation();
  const topicsState = useAppSelector((state) => state.topics);

  return (
    <StyledDropdown>
      <Typography variant={"h3"} fontWeight={600}>
        {t("title.add_element")}
      </Typography>

      <Divider style={{ margin: "16px 0" }} />

      <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
        <Typography
          variant={"h3"}
          color={(theme) => theme.palette.secondary.main}
          fontWeight={"bold"}
        >
          {t("title.text")}
        </Typography>
        <Box display={"flex"} flexWrap={"wrap"} gap={"12px"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"4px"}
            onClick={() => {
              const findItem = topicsState.categories?.[0];
              const { id = null, name = null, color = null } = findItem || {};

              props.setSettings((prev) => ({
                ...prev,
                displayTag: {
                  ...prev.displayTag,
                  visible: !prev.displayTag.visible,
                  finalRender: !prev.displayTag.visible,
                  id,
                  name,
                  color,
                },
              }));
            }}
          >
            <StyledCard isActive={props.settings.displayTag.visible}>
              <Tag color="blue" style={{ margin: 0 }}>
                {t("labels.blue")}
              </Tag>
            </StyledCard>

            <Typography
              color={(theme) => theme.palette.secondary.main}
              fontWeight={500}
            >
              {t("labels.add_a_category")}
            </Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"4px"}
            onClick={() =>
              props.setSettings((prev) => ({
                ...prev,
                displayMinutes: !prev.displayMinutes,
              }))
            }
          >
            <StyledCard isActive={props.settings.displayMinutes}>
              <Clock />
            </StyledCard>

            <Typography
              color={(theme) => theme.palette.secondary.main}
              fontWeight={500}
            >
              {t("labels.minutes_read")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider style={{ margin: "16px 0" }} />

      <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
        <Typography
          variant={"h3"}
          color={(theme) => theme.palette.secondary.main}
          fontWeight={"bold"}
        >
          {t("title.actions")}
        </Typography>
        <Box display={"flex"} flexWrap={"wrap"} gap={"8px"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"4px"}
            onClick={() =>
              props.setSettings((prev) => ({
                ...prev,
                displayCTA: {
                  ...prev.displayCTA,
                  visible: !prev.displayCTA.visible,
                },
              }))
            }
          >
            <StyledCard isActive={props.settings.displayCTA.visible}>
              <CtButton type={"primary"}>{t("buttons.button")}</CtButton>
            </StyledCard>

            <Typography
              color={(theme) => theme.palette.secondary.main}
              fontWeight={500}
            >
              {t("labels.cta")}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"4px"}
            onClick={() =>
              props.setSettings((prev) => ({
                ...prev,
                displayLikes: !prev.displayLikes,
              }))
            }
          >
            <StyledCard isActive={props.settings.displayLikes}>
              <Heart />
            </StyledCard>

            <Typography
              color={(theme) => theme.palette.secondary.main}
              fontWeight={500}
            >
              {t("labels.likes")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledDropdown>
  );
};

interface DropdownActionsProps {
  settings: ElementsSettings;
  setSettings: Dispatch<SetStateAction<ElementsSettings>>;
}

export default DropdownActions;
