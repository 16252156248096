import { Backdrop, Box, useMediaQuery } from "@mui/material";
import React, {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
} from "react";
import { Breakpoints } from "../../../common/utils/types/app.type";
import Navbar from "../../../common/components/Navbar/Navbar";
import SubNav from "./SubNav";

const SubNavLayout: FunctionComponent<SubNavLayoutProps> = (
  props: SubNavLayoutProps
) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  return (
    <>
      {isMobile && props.openMobileNavbar && (
        <>
          <Backdrop
            open={true}
            sx={{ position: "absolute", zIndex: 8 }}
            onClick={() => props.setOpenMobileNavbar(false)}
          />
          <Box position={"absolute"} zIndex={9}>
            <Navbar isCollapsed={true} />
            <Box position={"absolute"} top={0} left={"100px"} height={"100%"}>
              <SubNav />
            </Box>
          </Box>
        </>
      )}
      {props.children}
    </>
  );
};

interface SubNavLayoutProps {
  children: ReactNode;
  setOpenMobileNavbar: Dispatch<SetStateAction<boolean>>;
  openMobileNavbar: boolean;
}

export default SubNavLayout;
