import { FunctionComponent } from "react";
import CtButton from "../../../../common/components/CtButton/CtButton";
import styled from "styled-components";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Pencil, Copy, Trash2 } from "lucide-react";
import { fadeIn, fadeOut } from "../../../../common/utils/constants/styledKeys";
import { Tooltip } from "antd";
import {
  handleDeleteTopic,
  handleDuplicateTopic,
} from "../../../../app/store/actions/topics.action";
import { Topic, TopicStatus } from "../../../../common/utils/types/topics.type";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../common/utils/hooks/reduxHook";
import modal from "antd/es/modal";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../../../common/utils/types/app.type";

export const StyledButtons = styled(Box)<{ $isHovered: boolean }>`
  display: flex;
  gap: 6px;
  opacity: ${({ $isHovered }) => ($isHovered ? 1 : 0)};
  animation: ${({ $isHovered }) => ($isHovered ? fadeIn : fadeOut)} 0.3s
    forwards;
`;

const TopicBlockButtons: FunctionComponent<TopicBlockButtonsProps> = (
  props: TopicBlockButtonsProps
) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const appState = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const handleDeleteSelectedTopic = (e: any) => {
    e.stopPropagation();
    const config = {
      title: (
        <Typography fontWeight={"bold"}>{t("title.remove_topic")}</Typography>
      ),
      content: (
        <Typography>
          {t("labels.remove_topic", {
            topicName: <strong>{props.topic?.title}</strong>,
          })}
        </Typography>
      ),
    };

    if (props.topic) {
      modal.confirm({
        ...config,
        onOk: async () => {
          dispatch(handleDeleteTopic([props.topic.id]));
          if (props.extraDelete) props.extraDelete();
        },
      });
    }
  };

  const handleDuplicateSelectedTopic = (e: any) => {
    e.stopPropagation();
    const config = {
      title: (
        <Typography fontWeight={"bold"}>
          {t("title.duplicate_topic")}
        </Typography>
      ),
      content: (
        <Typography>
          {t("labels.duplication_topic", {
            topicName: <strong>{props.topic?.title}</strong>,
          })}
        </Typography>
      ),
    };

    if (props.topic) {
      modal.confirm({
        ...config,
        onOk: async () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, createdAt, updatedAt, title, ...rest } = props.topic;

          const newTitle = `${title} Copy`;

          const newTopic = {
            title: newTitle,
            body: rest.body,
            categoryId: rest?.settings?.displayTag?.id,
            status: TopicStatus.DRAFT,
            projectId: appState.selectedProject?.id,
            cta: {
              visible: rest.settings.displayCTA.visible,
              text: rest.ctaTitle,
              link: rest.ctaLink,
            },
            settings: {
              displayMinutes: rest.settings.displayMinutes,
              displayLikes: rest.settings.displayLikes,
              displayCTAId: rest.settings.displayCTA.visible,
            },
          };

          dispatch(handleDuplicateTopic(newTopic));
        },
      });
    }
  };

  return (
    <StyledButtons $isHovered={props.isHovered}>
      {!isMobile ? (
        <>
          {!props.hideEdit ? (
            <Tooltip title={t("tooltip.edit")}>
              <CtButton icon={<Pencil size={14} />} />
            </Tooltip>
          ) : null}

          <Tooltip title={t("tooltip.duplicate")}>
            <CtButton
              icon={<Copy size={14} />}
              onClick={handleDuplicateSelectedTopic}
            />
          </Tooltip>
        </>
      ) : null}

      <Tooltip title={t("tooltip.delete")}>
        <CtButton
          type={"primary"}
          danger={true}
          icon={<Trash2 size={14} />}
          onClick={handleDeleteSelectedTopic}
        />
      </Tooltip>
    </StyledButtons>
  );
};

interface TopicBlockButtonsProps {
  topic: Topic;
  isHovered: boolean;
  hideEdit?: boolean;
  extraDelete?: () => void;
}

export default TopicBlockButtons;
