import { Box, useMediaQuery } from "@mui/material";
import { FunctionComponent, ReactNode, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import { useAppSelector } from "../../utils/hooks/reduxHook";
import { useNavigate } from "react-router-dom";
import { authService } from "../../utils/services";
import { isAuthenticated } from "../../utils/constants/app.consts";
import { Breakpoints } from "../../utils/types/app.type";

const CtLayout: FunctionComponent<CtLayoutProps> = (props: CtLayoutProps) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const userState = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState?.infos) {
      if (
        !userState?.infos?.projects ||
        userState.infos.projects.length === 0
      ) {
        navigate("/new-project");
      }
    }
    if (!isAuthenticated) {
      authService.logout();
    }
  }, [userState?.infos?.projects, navigate]);

  return (
    <Box display={"flex"}>
      {isMobile ? <></> : <Navbar isCollapsed={props.isCollapsed} />}

      {props.children}
    </Box>
  );
};

export default CtLayout;

interface CtLayoutProps {
  children: ReactNode;
  isCollapsed?: boolean;
}
