import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Topic,
  TopicAllProps,
  TopicsSettings,
} from "../../../common/utils/types/topics.type";
import {
  categoriyService,
  projectService,
  topicService,
} from "../../../common/utils/services";
import { StatusType } from "../../../common/utils/types/status.type";
import { Project } from "../../../common/utils/types/project.type";

export const handleSetTopicsSettings = createAsyncThunk(
  "topics/settings",
  async (settings: TopicsSettings) => {
    return settings;
  }
);

export const handleGetProjectById = createAsyncThunk(
  "topics/getProject",
  async (
    { projectId }: { projectId?: Project["id"] },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as any;
      const appState = state.app;

      if (appState.status === StatusType.Succeeded) {
        if (!appState.selectedProject || !appState.selectedProject.id) {
          return rejectWithValue(
            new Error("Current project or project ID is undefined")
          );
        }

        const response = await projectService.getProjectId(
          projectId ?? appState.selectedProject.id
        );

        return response;
      } else {
        return rejectWithValue(new Error("App status is not succeeded"));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Categories
export const handleGetCategories = createAsyncThunk(
  "topics/getCategories",
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as any;
      const appState = state.app;

      const response = await categoriyService.getCategories(
        appState.selectedProject.id
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleCreateCategory = createAsyncThunk(
  "topics/createCategory",
  async (
    props: { name: string; color: string; projectId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await categoriyService.createCategories(
        props.name,
        props.color,
        props.projectId
      );

      dispatch(handleGetCategories());
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleUpdateCategory = createAsyncThunk(
  "topics/updateCategory",
  async (
    props: { id: string; name: string; color: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await categoriyService.updateCategory(
        props.id,
        props.name,
        props.color
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleDeleteCategory = createAsyncThunk(
  "topics/deleteCategory",
  async (props: { id: string }, { rejectWithValue }) => {
    try {
      await categoriyService.deleteCategory(props.id);

      return props.id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Topics
export const handleGetTopics = createAsyncThunk(
  "topics/get",
  async (
    props: Omit<TopicAllProps, "projectId">,
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as any;
      const appState = state.app;

      if (appState.status === StatusType.Succeeded) {
        if (!appState.selectedProject || !appState.selectedProject.id) {
          return rejectWithValue(
            new Error("Current project or project ID is undefined")
          );
        }

        const addProps: TopicAllProps = {
          ...props,
          projectId: appState?.selectedProject?.id,
        };

        const response = await topicService.getTopics({ ...addProps });

        return response;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleCreateNewTopic = createAsyncThunk(
  "topics/create",
  async (props: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await topicService.createTopic({ ...props });

      dispatch(handleGetProjectById({}));
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleDuplicateTopic = createAsyncThunk(
  "topics/duplicate",
  async (props: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await topicService.createTopic({ ...props });

      await dispatch(handleGetProjectById({}));

      await dispatch(handleSelectTopic(response.id));

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleUpdateExistingTopic = createAsyncThunk(
  "topics/update",
  async (props: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await topicService.updateTopic({ ...props });

      dispatch(handleGetProjectById({}));
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleSelectTopic = createAsyncThunk(
  "topics/selectTopic",
  async (topicId: Topic["id"] | null, { rejectWithValue }) => {
    try {
      if (topicId) return await topicService.getTopicId(topicId);

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleDeleteTopic = createAsyncThunk(
  "topics/deleteTopic",
  async (topicIds: Topic["id"][] | null, { rejectWithValue }) => {
    try {
      if (topicIds) {
        await topicService.deleteTopic(topicIds);
        return topicIds;
      }

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
