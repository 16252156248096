import { Box, Typography, useTheme } from "@mui/material";
import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../common/utils/hooks/reduxHook";
import { Input } from "antd";
import { FileInput, TextCursor, X } from "lucide-react";
import CtButton from "../../../common/components/CtButton/CtButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import heroNewProject from "../../../assets/images/HeroNewtProject.svg";
import { handleCreateProject } from "../../../app/store/actions/app.action";
import { useTranslation } from "react-i18next";

const StyledGoBack = styled(Box)`
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledBackground = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 128px;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    135deg,
    rgba(2, 140, 243, 0.46964723389355745) 0%,
    rgba(255, 255, 255, 1) 29%
  );
`;

const StyledHero = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
`;

const NewProject: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.user);

  const [projectName, setProjectName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitMyProject = async () => {
    if (projectName.length) {
      setIsLoading(true);
      const result = await dispatch(handleCreateProject(projectName));

      if (result?.payload?.id) {
        toast.success(t("toast.project_created"));
        await setTimeout(() => {
          navigate("/");
        }, 1500);
      } else {
        toast.error(t("toast.error_occurred"));
      }
    }
  };

  return (
    <StyledBackground>
      {userState?.infos?.projects && userState?.infos?.projects?.length > 0 ? (
        <StyledGoBack
          position={"absolute"}
          top={20}
          right={25}
          zIndex={999}
          onClick={() => {
            navigate("/");
          }}
        >
          <X size={32} color={theme.palette.secondary.main} />
        </StyledGoBack>
      ) : null}

      <Box display={"flex"} flexDirection={"column"} width={"450px"}>
        <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
          <Typography
            variant={"h3"}
            color={(theme) => theme.palette.primary.main}
          >
            {t("labels.hey")} {userState.infos?.firstName}!
          </Typography>
          <Typography variant={"h1"}>{t("title.new_project")}</Typography>
          <Typography variant={"h3"} fontWeight={400}>
            {t("labels.new_project_subtitle")}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"flex-start"}
          flexDirection={"column"}
          gap={"24px"}
          width={"75%"}
          m={"24px 0"}
        >
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            flexDirection={"column"}
            width={"100%"}
            gap={"8px"}
          >
            <Typography
              fontWeight={"bold"}
              variant={"caption"}
              whiteSpace={"nowrap"}
            >
              {t("labels.project_name")}
            </Typography>
            <Input
              placeholder={"Projet Starship"}
              autoFocus={true}
              prefix={
                <Box display={"flex"} mr={"4px"}>
                  <TextCursor size={14} color={theme.palette.grey[400]} />
                </Box>
              }
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Box>

          <CtButton
            type={"primary"}
            icon={<FileInput size={14} />}
            onClick={handleSubmitMyProject}
            loading={isLoading}
            disabled={isLoading}
          >
            {t("buttons.create_project")}
          </CtButton>
        </Box>
      </Box>
      <StyledHero src={heroNewProject} />
    </StyledBackground>
  );
};

export default NewProject;
