import { StatusType } from "./status.type";

export enum TopicDisplayMode {
  CARD = "CARD",
  ROW = "ROW",
}

export interface TopicsSettings {
  displayMode?: TopicDisplayMode;
}

export interface TopicsState {
  list: Topic[] | null;
  filteredList: Topic[] | null;
  categories: Category[];
  selectedTopic: Topic | null;
  settings?: TopicsSettings;
  status?: StatusType;
  error?: string | null;
}

export interface ElementsSettings {
  id: string;
  displayMinutes: boolean;
  displayLikes: boolean;
  displayTag: {
    finalRender: boolean;
    visible: boolean;
    id: string | null;
    name: string | null;
    color: string | null;
  };
  displayCTA: {
    id: string;
    visible: boolean;
    text: string;
    link: string;
  };
}

export enum TopicStatus {
  LIVE = "LIVE",
  EXPIRED = "EXPIRED",
  DRAFT = "DRAFT",
}

export interface Category {
  id: string;
  name: string;
  color: string | null;
}

export enum TopicSort {
  ALPHABETICAL = "alphabetical",
  MOSTRECENT = "mostRecent",
  LEASTRECENT = "leastRecent",
  MOSTVIEWED = "mostViewed",
}

export interface TopicAllProps {
  projectId: string;
  status: TopicStatus | null;
  categoryId: string | null;
  search: string | null;
  sort: TopicSort;
}

export interface Topic {
  id?: string;
  title: string;
  body: string;
  plainText: string;
  ctaLink: string;
  ctaTitle: string;
  status: TopicStatus | null;
  likeCount: number;
  viewCount?: number;
  settings: ElementsSettings;
  createdAt?: Date;
  updatedAt?: Date;
}
