import { Dropdown, Input } from "antd";
import { MousePointerClick } from "lucide-react";
import { FunctionComponent } from "react";
import { StyledDropdown } from "./DropdownActions";
import CtButton from "../../../common/components/CtButton/CtButton";
import { Box, Typography } from "@mui/material";
import { ElementsSettings } from "../../../common/utils/types/topics.type";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const Details: FunctionComponent<CTADetailsProps> = (
  props: CTADetailsProps
) => {
  const { t } = useTranslation();

  return (
    <StyledDropdown>
      <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography>{t("labels.text")}:</Typography>
          <Input
            placeholder={t("labels.fill_text")}
            value={props.settings.displayCTA.text}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              props.setSettings((prev: ElementsSettings) => ({
                ...prev,
                displayCTA: {
                  ...prev.displayCTA,
                  text: e.target.value,
                },
              }))
            }
          />
        </Box>

        <Box display={"flex"} flexDirection={"column"}>
          <Typography>{t("labels.link")}:</Typography>
          <Input
            placeholder={t("labels.fill_link")}
            value={props.settings.displayCTA.link}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              props.setSettings((prev: ElementsSettings) => ({
                ...prev,
                displayCTA: {
                  ...prev.displayCTA,
                  link: e.target.value,
                },
              }))
            }
          />
        </Box>
      </Box>
    </StyledDropdown>
  );
};

const CTADetails: FunctionComponent<CTADetailsProps> = (
  props: CTADetailsProps
) => {
  return (
    <Box display={"flex"}>
      <Dropdown
        dropdownRender={() => (
          <Details settings={props.settings} setSettings={props.setSettings} />
        )}
        trigger={["click"]}
      >
        <CtButton icon={<MousePointerClick size={14} />}>
          {t("buttons.call_to_action")}
        </CtButton>
      </Dropdown>
    </Box>
  );
};

interface CTADetailsProps {
  setSettings: React.Dispatch<React.SetStateAction<ElementsSettings>>;
  settings: ElementsSettings;
}

export default CTADetails;
