import React from "react";
import { Tabs, TabsProps } from "antd";
import CtLink from "../../../common/components/CtLink/CtLink";
import { CopyBlock, monoBlue } from "react-code-blocks";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../common/utils/hooks/reduxHook";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ReactInstallation = () => {
  const { t } = useTranslation();
  const appState = useAppSelector((state) => state.app);

  const handleCopyText = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(t("toast.copied_clipboard"));
    } catch (err) {
      console.error("Erreur lors de la copie : ", err);
    }
  };

  const npmText = "npm install @usetoolio/contextual_help --save";
  const pnpmText = "pnpm install @usetoolio/contextual_help --save";
  const bunText = "bun add @usetoolio/contextual_help";
  const yarnText = "yarn add @usetoolio/contextual_help";
  const reactCode = `import { ToolioProvider } from "@usetoolio/contextual_help";

ReactDOM.createRoot(rootElement).render(
  <ToolioProvider projectId={"${appState?.selectedProject?.id}"}>
    <App />
  </ToolioProvider>
);

import { ToolioHelp } from "@usetoolio/contextual_help";

const App = () => {
  return (<ToolioHelp 
    topicId={"your-topic-id"} 
    triggerButton={<button>${t("buttons.need_help")}</button>} 
  />);
};
`;

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "npm",
      children: (
        <CopyBlock
          language={"bash"}
          text={npmText}
          showLineNumbers={false}
          theme={monoBlue}
          codeBlock={true}
          onCopy={() => handleCopyText(npmText)}
        />
      ),
    },
    {
      key: "2",
      label: "yarn",
      children: (
        <CopyBlock
          language={"bash"}
          text={yarnText}
          showLineNumbers={false}
          theme={monoBlue}
          codeBlock={true}
          onCopy={() => handleCopyText(yarnText)}
        />
      ),
    },
    {
      key: "3",
      label: "pnpm",
      children: (
        <CopyBlock
          language={"bash"}
          text={pnpmText}
          showLineNumbers={false}
          theme={monoBlue}
          codeBlock={true}
          onCopy={() => handleCopyText(pnpmText)}
        />
      ),
    },
    {
      key: "4",
      label: "Bun",
      children: (
        <CopyBlock
          language={"bash"}
          text={bunText}
          showLineNumbers={false}
          theme={monoBlue}
          codeBlock={true}
          onCopy={() => handleCopyText(bunText)}
        />
      ),
    },
  ];

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} mt={"16px"} gap={"8px"}>
        <Typography variant={"h2"}>{t("labels.version")}</Typography>
        <Box display={"flex"} alignItems={"center"} gap={"4px"}>
          <Typography>{t("labels.stable")}: </Typography>
          <a
            href={"https://www.npmjs.com/package/npmlibhelpcontextual"}
            target={"_blank"}
            style={{ display: "flex" }}
          >
            <img
              src="https://img.shields.io/npm/v/npmlibhelpcontextual"
              alt="npm lib"
            />
          </a>
        </Box>
      </Box>

      <Box display={"flex"} flexDirection={"column"} mt={"16px"} gap={"8px"}>
        <Typography variant={"h2"}>{t("title.installation")}</Typography>
        <Box display={"flex"} alignItems={"center"} gap={"4px"}>
          <Typography>
            <strong>
              {t("labels.we_recommend")}{" "}
              <CtLink
                href={"https://www.npmjs.com/"}
                label={"npm"}
                external={true}
              />{" "}
              {t("labels.or")}{" "}
              <CtLink
                href={"https://github.com/yarnpkg/yarn/"}
                label={"yarn"}
                external={true}
              />{" "}
              {t("labels.or")}{" "}
              <CtLink
                href={"https://pnpm.io/"}
                label={"pnpm"}
                external={true}
              />{" "}
              {t("labels.or")}{" "}
              <CtLink href={"https://bun.sh/"} label={"bun"} external={true} />{" "}
            </strong>
            {t("labels.to_install_lib")}
          </Typography>
        </Box>

        <Tabs defaultActiveKey="1" items={items} />

        <Typography>
          {t("labels.bad_network_env")}{" "}
          <CtLink
            href={"https://github.com/cnpm/cnpm"}
            label={"cnpm"}
            external={true}
          />
          .
        </Typography>
      </Box>

      <Box display={"flex"} flexDirection={"column"} mt={"16px"} gap={"8px"}>
        <Typography variant={"h2"}>{t("labels.project_id")}</Typography>
        <CopyBlock
          language={"jsx"}
          text={appState.selectedProject?.id ?? ""}
          showLineNumbers={false}
          theme={monoBlue}
          codeBlock={true}
          onCopy={() => handleCopyText(appState?.selectedProject?.id ?? "")}
        />
      </Box>

      <Box display={"flex"} flexDirection={"column"} mt={"16px"} gap={"8px"}>
        <Typography variant={"h2"}>{t("labels.usage")}</Typography>
        <CopyBlock
          language={"jsx"}
          text={reactCode}
          showLineNumbers={false}
          theme={monoBlue}
          codeBlock={true}
          onCopy={() => handleCopyText(reactCode)}
        />
      </Box>
    </>
  );
};

export default ReactInstallation;
