import React, { useEffect, useState } from "react";
import CtSelect from "../CtSelect/CtSelect";
import i18n from "../../../i18n";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/reduxHook";
import { Language } from "../../utils/types/user.type";
import { handleUpdateMe } from "../../../app/store/actions/user.action";
import { languagesList } from "../../utils/constants/app.consts";

const LanguageManagement = () => {
  const dispatch = useAppDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    Language.FR
  );
  const userState = useAppSelector((state) => state.user);

  const handleLanguageUpdate = (newValue: Language) => {
    i18n.changeLanguage(newValue.toLowerCase());
    setSelectedLanguage(newValue);

    dispatch(
      handleUpdateMe({
        formAccount: {
          firstName: userState.infos?.firstName,
          lastName: userState.infos?.lastName,
          email: userState.infos?.email,
          language: newValue,
        },
        hideToast: true,
      })
    );
  };

  useEffect(() => {
    if (userState.infos?.userSettings?.language) {
      setSelectedLanguage(userState.infos?.userSettings?.language);
      i18n.changeLanguage(
        userState.infos?.userSettings?.language.toLowerCase()
      );
    }
  }, []);

  return (
    <CtSelect
      defaultValue={Language.FR}
      style={{ width: "60px" }}
      value={languagesList.find((item) => item.value === selectedLanguage)}
      variant="borderless"
      onChange={handleLanguageUpdate}
      options={languagesList}
    />
  );
};

export default LanguageManagement;
