import { FunctionComponent, useEffect } from "react";
import MantineEditor from "./MantineEditor";
import { useAppSelector } from "../../utils/hooks/reduxHook";
import { Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Image from "@tiptap/extension-image";
import ImageResizePercentage from "./components/ImageResizePercent";
import VideoResize from "./components/VideoResize";
import HardBreak from "@tiptap/extension-hard-break";

const CtWysiwyg: FunctionComponent<CtWysiwygProps> = (
  props: CtWysiwygProps
) => {
  const topicState = useAppSelector((state) => state.topics);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Color,
      TextStyle,
      Color,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Image,
      ImageResizePercentage,
      VideoResize,
      HardBreak,
    ],
    content: topicState.selectedTopic?.body,
  });

  useEffect(() => {
    if (editor && topicState.selectedTopic?.body) {
      editor.commands.setContent(topicState.selectedTopic.body);
    }
  }, [editor, topicState.selectedTopic?.body]);

  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      props.setPlainText(editor.getText());
      const htmlContent = editor.getHTML().replace("<p></p>", "<p><br /></p>");
      props.onChange(htmlContent);
    }
  }, [editor?.getText(), editor?.getHTML()]);

  return (
    <>
      <MantineEditor editor={editor} />
    </>
  );
};

interface CtWysiwygProps {
  defaultValue: string;
  onChange: (value: string) => void;
  setPlainText: (value: string) => void;
}

export default CtWysiwyg;
