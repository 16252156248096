import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { isPageActive } from "../../../common/utils/functions";
import { StyledNavItem } from "../../../common/components/Navbar/Items";
import { authService } from "../../../common/utils/services";
import {
  Bell,
  CircleUser,
  LogOut,
  Settings,
  UsersRound,
  Wrench,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../../common/utils/types/app.type";

const StyledSubNavbar = styled.div<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "200px" : "300px")};
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 20px 26px;
`;

const SubNav: FunctionComponent = () => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <StyledSubNavbar isMobile={isMobile}>
      <Typography fontWeight={"bold"}>{t("title.project")}</Typography>
      <Box display={"flex"} flexDirection={"column"} gap={"12px"} mt={"16px"}>
        <StyledNavItem
          noPadding={true}
          isActive={isPageActive("/settings/general")}
          $height={"calc(100% + 10px)"}
          top={"-5px"}
          borderRadius={"6px"}
          onClick={() => navigate("/settings/general")}
        >
          <Box display={"flex"} alignItems={"center"} gap={"4px"}>
            <Settings size={14} />
            {t("navigation.general")}
          </Box>
        </StyledNavItem>

        <StyledNavItem
          noPadding={true}
          isActive={isPageActive("/settings/installation")}
          $height={"calc(100% + 10px)"}
          top={"-5px"}
          borderRadius={"6px"}
          onClick={() => navigate("/settings/installation")}
        >
          <Box display={"flex"} alignItems={"center"} gap={"4px"}>
            <Wrench size={14} />
            {t("navigation.installation")}
          </Box>
        </StyledNavItem>

        <StyledNavItem
          noPadding={true}
          $height={"calc(100% + 10px)"}
          isActive={isPageActive("/settings/team")}
          top={"-5px"}
          borderRadius={"6px"}
          onClick={() => navigate("/settings/team")}
        >
          <Box display={"flex"} alignItems={"center"} gap={"4px"}>
            <UsersRound size={14} />
            {t("navigation.team")}
          </Box>
        </StyledNavItem>
      </Box>

      <Divider />
      <Typography fontWeight={"bold"}>{t("navigation.account")}</Typography>
      <Box display={"flex"} flexDirection={"column"} gap={"16px"} mt={"16px"}>
        <StyledNavItem
          noPadding={true}
          $height={"calc(100% + 10px)"}
          isActive={isPageActive("/settings/account")}
          top={"-5px"}
          borderRadius={"6px"}
          onClick={() => navigate("/settings/account")}
        >
          <Box display={"flex"} alignItems={"center"} gap={"4px"}>
            <CircleUser size={14} />
            {t("navigation.my_account")}
          </Box>
        </StyledNavItem>

        <StyledNavItem
          noPadding={true}
          $height={"calc(100% + 10px)"}
          isActive={isPageActive("/settings/notifications")}
          top={"-5px"}
          borderRadius={"6px"}
          onClick={() => navigate("/settings/notifications")}
        >
          <Box display={"flex"} alignItems={"center"} gap={"4px"}>
            <Bell size={14} />
            {t("navigation.notifications")}
          </Box>
        </StyledNavItem>

        <StyledNavItem
          noPadding={true}
          $height={"calc(100% + 10px)"}
          top={"-5px"}
          borderRadius={"6px"}
          onClick={() => authService.logout()}
          color={theme.palette.error.main}
        >
          <Box display={"flex"} alignItems={"center"} gap={"4px"}>
            <LogOut size={14} />
            {t("navigation.log_out")}
          </Box>
        </StyledNavItem>
      </Box>
    </StyledSubNavbar>
  );
};

export default SubNav;
