import React, { FunctionComponent, useCallback, useRef } from "react";
import { RichTextEditor } from "@mantine/tiptap";
import { BubbleMenu, Editor, FloatingMenu } from "@tiptap/react";
import { Clapperboard, ImageUp } from "lucide-react";
import ImageFromUrl from "../../../assets/icons/ImageFromUrl.svg";
import ctAxios from "../../utils/services/axios";
import { useAppSelector } from "../../utils/hooks/reduxHook";

const MantineEditor: FunctionComponent<MantineEditorProps> = (
  props: MantineEditorProps
) => {
  const fileInputRef = useRef<any>(null);
  const appState = useAppSelector((state) => state.app);

  const addImageByUrl = () => {
    const url = window.prompt("URL de l'image");
    if (url) {
      props.editor?.chain().focus().setImage({ src: url }).run();
    }
  };

  const addVideoByUrl = () => {
    const url = window.prompt("URL de la vidéo");
    if (url) {
      props.editor
        ?.chain()
        .focus()
        .insertContent({
          type: "iframe",
          attrs: {
            src: url,
            width: "80%", // Valeur par défaut, modifiable par la suite
            height: "auto",
          },
        })
        .run();
    }
  };

  const handleImageUpload = useCallback(
    async (file: File) => {
      try {
        const formData = new FormData();
        formData.append("image", file);

        const response = await ctAxios.post(
          `/api/topics/${appState.selectedProject?.id}/upload-image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = await response.data;
        const imageUrl = data.fileUrl; // URL de l'image renvoyée par l'API

        // Ajouter l'image à l'éditeur en utilisant l'URL de l'image
        props.editor?.chain().focus().setImage({ src: imageUrl }).run();
      } catch (error) {
        console.error("Échec du téléchargement de l'image :", error);
      }
    },
    [props.editor]
  );

  // Fonction pour gérer l'importation d'image depuis le fichier sélectionné
  const addImageFromFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Appeler la fonction de téléchargement d'image
      handleImageUpload(file);

      // Réinitialiser l'entrée de fichier après l'importation
      event.target.value = ""; // Réinitialisation ici
    }
  };

  const alignImage = (alignment) => {
    // Appliquer les styles d'alignement au parent (wrapper)
    const alignments = {
      left: "text-align: left;",
      center: "text-align: center;",
      right: "text-align: right;",
    };

    const { editor } = props; // Assurez-vous que vous avez bien la référence de l'éditeur

    if (!editor) return; // Vérifiez que l'éditeur est défini

    const { selection } = editor.state;
    const selectedNode = editor.state.doc.nodeAt(selection.from); // Obtenir le nœud à la position de la sélection

    if (selectedNode && selectedNode.type.name === "image") {
      // Mettre à jour l'attribut alignStyle de l'image sélectionnée
      editor
        .chain()
        .focus()
        .updateAttributes("image", { alignStyle: alignments[alignment] })
        .run();
    }
  };

  return (
    <RichTextEditor editor={props.editor} style={{ overflow: "scroll" }}>
      {props.editor && (
        <BubbleMenu editor={props.editor}>
          <RichTextEditor.ControlsGroup>
            {props.editor.isActive("image") ? (
              <>
                <RichTextEditor.Control onClick={() => alignImage("left")}>
                  <RichTextEditor.AlignLeft />
                </RichTextEditor.Control>
                <RichTextEditor.Control onClick={() => alignImage("center")}>
                  <RichTextEditor.AlignCenter />
                </RichTextEditor.Control>
                <RichTextEditor.Control onClick={() => alignImage("right")}>
                  <RichTextEditor.AlignRight />
                </RichTextEditor.Control>
              </>
            ) : (
              <>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
                <RichTextEditor.ColorPicker
                  colors={[
                    "#25262b",
                    "#868e96",
                    "#fa5252",
                    "#e64980",
                    "#be4bdb",
                    "#7950f2",
                    "#4c6ef5",
                    "#228be6",
                    "#15aabf",
                    "#12b886",
                    "#40c057",
                    "#82c91e",
                    "#fab005",
                    "#fd7e14",
                  ]}
                />
              </>
            )}
          </RichTextEditor.ControlsGroup>
        </BubbleMenu>
      )}

      {props.editor && (
        <FloatingMenu editor={props.editor}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
            <RichTextEditor.BulletList />
          </RichTextEditor.ControlsGroup>
        </FloatingMenu>
      )}
      <RichTextEditor.Toolbar sticky stickyOffset={60} top={0}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
          <RichTextEditor.ColorPicker
            colors={[
              "#25262b",
              "#868e96",
              "#fa5252",
              "#e64980",
              "#be4bdb",
              "#7950f2",
              "#4c6ef5",
              "#228be6",
              "#15aabf",
              "#12b886",
              "#40c057",
              "#82c91e",
              "#fab005",
              "#fd7e14",
            ]}
          />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>

        {/* Ajouter un bouton pour insérer des images */}
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Control onClick={addImageByUrl}>
            <img src={ImageFromUrl} />
          </RichTextEditor.Control>
          <RichTextEditor.Control
            onClick={() => fileInputRef?.current?.click()}
          >
            <ImageUp size={16} strokeWidth={1.5} />
          </RichTextEditor.Control>
          <RichTextEditor.Control onClick={addVideoByUrl}>
            <Clapperboard size={16} strokeWidth={1.5} />
          </RichTextEditor.Control>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={addImageFromFile}
          />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Undo />
          <RichTextEditor.Redo />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  );
};

interface MantineEditorProps {
  editor: Editor | null;
}

export default MantineEditor;
