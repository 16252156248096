import { FunctionComponent, useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LockKeyhole, Mail, User, UserPlus } from "lucide-react";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthLayout from "./AuthLayout";
import CtButton from "../../common/components/CtButton/CtButton";
import UserIcon from "./components/UserIcon";
import { authService } from "../../common/utils/services";
import { AuthType } from "../../common/utils/types/auth.type";
import { useLoading } from "../../common/utils/hooks/useLoading";
import { isValidEmail } from "../../common/utils/functions";
import useDebounce from "../../common/utils/hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../common/utils/types/app.type";

const Signup: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const { loadingStates, execWithLoader } = useLoading();
  const [errors, setErrors] = useState<{
    email?: string;
    password?: string;
    confirmPassword?: string;
  }>({});
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [formRegister, setFormRegister] = useState<AuthType>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const debouncedEmail = useDebounce(formRegister.email, 500);
  const debouncedPassword = useDebounce(formRegister.password, 500);
  const debouncedConfirmPassword = useDebounce(confirmPassword, 500);

  const handleRegister = () => {
    execWithLoader("register", async () => {
      if (confirmPassword === formRegister.password) {
        const result = await authService.register(formRegister);

        if (result?.data?.status === "success") {
          navigate("/login");
          toast.success(t("toast.account_created"));
        }
      }
    });
  };

  useEffect(() => {
    const newErrors: {
      email?: string;
      password?: string;
      confirmPassword?: string;
    } = {};
    if (!isValidEmail(debouncedEmail) && debouncedEmail.length > 0) {
      newErrors.email = t("labels.email_incorrect");
    }
    if (debouncedPassword.length < 5 && debouncedPassword.length > 0) {
      newErrors.password = t("labels.password_five_characters_long");
    }
    if (debouncedConfirmPassword !== debouncedPassword) {
      newErrors.confirmPassword = t("labels.password_mismatch");
    }

    setErrors(newErrors);
  }, [debouncedEmail, debouncedPassword, debouncedConfirmPassword]);

  return (
    <AuthLayout>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        height={"calc(100% - 75px)"}
        justifyContent={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"32px"}
          width={isMobile ? "100%" : "75%"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"8px"}
          >
            <UserIcon />
            <Typography variant={"h1"}>
              {t("title.create_new_account")}
            </Typography>
            <Typography variant={"caption"}>
              {t("labels.create_new_account")}
            </Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"16px"}
            width={isMobile ? "100%" : "75%"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Typography fontWeight={"bold"} variant={"caption"}>
                {t("labels.first_name")}
              </Typography>
              <Input
                placeholder={"Michael"}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <User size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
                value={formRegister.firstName}
                onChange={(e) =>
                  setFormRegister((prev: AuthType) => ({
                    ...prev,
                    firstName: e.target.value,
                  }))
                }
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Typography fontWeight={"bold"} variant={"caption"}>
                {t("labels.last_name")}
              </Typography>
              <Input
                placeholder={"Jackson"}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <User size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
                value={formRegister.lastName}
                onChange={(e) =>
                  setFormRegister((prev: AuthType) => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Typography fontWeight={"bold"} variant={"caption"}>
                {t("labels.email")}
              </Typography>
              <Input
                placeholder={"michael.jackson@youhou.com"}
                status={errors.email ? "error" : undefined}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <Mail size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
                value={formRegister.email}
                onChange={(e) =>
                  setFormRegister((prev: AuthType) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
              />
              {errors.email && (
                <Typography color="error" variant="caption">
                  {errors.email}
                </Typography>
              )}
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Typography fontWeight={"bold"} variant={"caption"}>
                {t("labels.password")}
              </Typography>
              <Input.Password
                placeholder={t("labels.type_password")}
                autoComplete="new-password"
                status={errors.password ? "error" : undefined}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <LockKeyhole size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
                value={formRegister.password}
                onChange={(e) =>
                  setFormRegister((prev: AuthType) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
              />
              {errors.password && (
                <Typography color="error" variant="caption">
                  {errors.password}
                </Typography>
              )}
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Typography fontWeight={"bold"} variant={"caption"}>
                {t("labels.confirm_password")}
              </Typography>
              <Input.Password
                placeholder="Confirm your password"
                status={errors.confirmPassword ? "error" : undefined}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <LockKeyhole size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {errors.confirmPassword && (
                <Typography color="error" variant="caption">
                  {errors.confirmPassword}
                </Typography>
              )}
            </Box>

            <CtButton
              type={"primary"}
              icon={<UserPlus size={14} />}
              style={{ width: "100%" }}
              onClick={handleRegister}
              disabled={
                confirmPassword !== formRegister.password ||
                !confirmPassword.length ||
                !formRegister.password.length
              }
              loading={loadingStates.register}
            >
              {t("buttons.register")}
            </CtButton>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Signup;
