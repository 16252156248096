export const ctOriginalWhite = "#FFFFFF";
export const ctOriginalBlack = "#000000";

export const ctBlack = "#212121";
export const ctBlueGreyMiddle = "#D3DDE8";
export const ctWhite = "#fcfcfc";

export const ctSecondary = "#424242";
export const ctDarkSecondary = "#000000";
export const ctLightSecondary = "#e0e0e0";

export const ctBlue = "#028CF3";
export const ctDarkBlue = "#166FDF";
export const ctLightBlue = "#5AAEEC";

export const ctGreen = "#62EFBD";
export const ctDarkGreen = "#2BE3A5";
export const ctLightGreen = "#82F2CA";

export const ctYellow = "#FF983F";
export const ctDarkYellow = "#EC8B37";
export const ctLightYellow = "#FFAC66";

export const ctRed = "#EB5757";
export const ctDarkRed = "#CD4C4C";
export const ctLightRed = "#EA7F7F";

export const lineColor = "#E6ECEF";
