import { Box, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { Ban, Check } from "lucide-react";
import CtButton from "../../../common/components/CtButton/CtButton";
import { useNavigate, useParams } from "react-router-dom";
import heroNewProject from "../../../assets/images/HeroNewtProject.svg";
import {
  authService,
  teamService,
  userService,
} from "../../../common/utils/services";
import { User } from "../../../common/utils/types/user.type";
import FormAcceptInvitation from "./FormAcceptInvitation";
import { useLoading } from "../../../common/utils/hooks/useLoading";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const StyledBackground = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 128px;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    135deg,
    rgba(2, 140, 243, 0.46964723389355745) 0%,
    rgba(255, 255, 255, 1) 29%
  );
`;

const StyledHero = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
`;

const InvitationLink: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tokenId, teamId, userId } = useParams();
  const { loadingStates, execWithLoader } = useLoading();

  const [user, setUser] = useState<User>();
  const [team, setTeam] = useState<any>();

  useEffect(() => {
    const getInformations = async () => {
      if (userId) {
        const data = await userService.getPublicUser(userId);
        setUser(data);
      }

      if (teamId) {
        const data = await teamService.getPublicTeam(teamId);
        setTeam(data);
      }
    };

    getInformations();
  }, []);

  const handleInvitation = (status: "Accepted" | "Refused") => {
    execWithLoader("invitation", async () => {
      if (status && userId && tokenId && !loadingStates.invitation) {
        const result = await authService.inviteUserFromInvitation(
          userId,
          tokenId,
          status
        );

        if (result?.status === 200) {
          toast.success(
            status === "Accepted"
              ? t("toast.invitation_accepted")
              : t("toast.invitation_declined")
          );
          await setTimeout(() => {
            navigate("/login");
          }, 1500);
        }
      }
    });
  };

  return (
    <StyledBackground>
      <Box display={"flex"} flexDirection={"column"} width={"450px"}>
        <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
          <Typography
            variant={"h3"}
            color={(theme) => theme.palette.primary.main}
          >
            {t("labels.hey", { lng: "en" })} {user?.firstName}!
          </Typography>
          <Typography variant={"h1"}>
            {t("labels.invited_to_join_team", {
              teamName: <u>{team ? team?.name : null}</u>,
              lng: "en",
            })}
          </Typography>

          <Typography variant={"h3"} fontWeight={400}>
            {t("labels.by_accepting_this_invitation")}
          </Typography>
        </Box>

        {user ? (
          <Box display={"flex"} gap={"8px"} mt={"24px"}>
            <CtButton
              icon={<Check size={14} />}
              type={"primary"}
              loading={loadingStates.invitation}
              disabled={loadingStates.invitation}
              onClick={() => handleInvitation("Accepted")}
            >
              {t("buttons.accept")}
            </CtButton>

            <CtButton
              icon={<Ban size={14} />}
              type={"primary"}
              danger={true}
              loading={loadingStates.invitation}
              disabled={loadingStates.invitation}
              onClick={() => handleInvitation("Refused")}
            >
              {t("buttons.decline")}
            </CtButton>
          </Box>
        ) : (
          <FormAcceptInvitation />
        )}
      </Box>
      <StyledHero src={heroNewProject} />
    </StyledBackground>
  );
};

export default InvitationLink;
