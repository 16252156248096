import { FunctionComponent } from "react";
import { StyledDropdown } from "../../../features/ContextualHelp/components/DropdownActions";
import { Plus } from "lucide-react";
import { Avatar, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/reduxHook";
import { Project } from "../../utils/types/project.type";
import { handleGetProjectById } from "../../../app/store/actions/topics.action";
import { StyledNavItem } from "./Items";
import { useTranslation } from "react-i18next";

const ListProject: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const appState = useAppSelector((state) => state.app);
  const userState = useAppSelector((state) => state.user);

  const handleSelectProject = (projectId: Project["id"]) => {
    if (appState.selectedProject?.id !== projectId) {
      dispatch(handleGetProjectById({ projectId }));
      navigate("/");
    }
  };

  return (
    <StyledDropdown
      minWidth="250px"
      padding={"16px 8px"}
      marginTop="50px"
      style={{
        maxHeight: "300px",
        overflow: "scroll",
      }}
    >
      <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
        {userState?.infos?.projects.map((project: Project) => {
          return (
            <StyledNavItem
              onClick={() => handleSelectProject(project.id)}
              isActive={appState.selectedProject?.id === project.id}
            >
              <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                <Avatar
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: (theme) => theme.palette.primary.main,
                    width: "24px",
                    height: "24px",
                  }}
                  variant="square"
                >
                  <Typography variant={"caption"}>
                    {project?.name[0].toUpperCase()}
                  </Typography>
                </Avatar>
                <Typography>{project.name}</Typography>
              </Box>
            </StyledNavItem>
          );
        })}

        <StyledNavItem onClick={() => navigate("/new-project")}>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Plus size={20} />
            <Typography>{t("labels.new_project")}</Typography>
          </Box>
        </StyledNavItem>
      </Box>
    </StyledDropdown>
  );
};

export default ListProject;
