import React, { FunctionComponent } from "react";
import styled from "styled-components";
import heroNewProject from "../../../assets/images/HeroNewtProject.svg";
import { Box, Typography, useMediaQuery } from "@mui/material";
import CtButton from "../CtButton/CtButton";
import { Mail } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../utils/types/app.type";

const StyledComingSoon = styled.div`
  padding: 64px;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 300px;
  background-color: #fbfdff;
  border-radius: 8px;
`;

const StyledHero = styled.img<{ isMobile: boolean }>`
  position: absolute;
  right: 0;
  top: -10%;
  width: ${({ isMobile }) => (isMobile ? "150%" : "30%")};
`;

const CtComingSoon: FunctionComponent = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  return (
    <StyledComingSoon>
      <StyledHero src={heroNewProject} isMobile={isMobile} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"16px"}
        position={"relative"}
        zIndex={1}
      >
        <Typography variant={isMobile ? "h2" : "h1"}>
          {t("title.component.comingsoon")}
        </Typography>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          gap={"16px"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"2px"}>
            <Typography fontSize={isMobile ? "14px" : "16px"}>
              {t("labels.comingsoon.one")}
            </Typography>
            <Typography fontSize={isMobile ? "14px" : "16px"}>
              {t("labels.comingsoon.two")}
            </Typography>
          </Box>
          <CtButton
            icon={<Mail size={14} />}
            onClick={() =>
              (window.location.href = "mailto:contact@usetoolio.com")
            }
          >
            {t("buttons.contact_us")}
          </CtButton>
        </Box>
      </Box>
    </StyledComingSoon>
  );
};

export default CtComingSoon;
