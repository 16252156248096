import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import CtDrawer from "../../../../common/components/CtDrawer/CtDrawer";
import { lineColor } from "../../../../common/utils/theme/colors";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CtButton from "../../../../common/components/CtButton/CtButton";
import { RotateCcw, Save, X } from "lucide-react";
import {
  ColorPicker,
  Divider,
  Input,
  InputNumber,
  Segmented,
  Slider,
  Tooltip,
} from "antd";
import OutsideImg from "../../../../assets/images/Outside.svg";
import CloseIconImg from "../../../../assets/images/CloseIcon.svg";
import BothImg from "../../../../assets/images/Both.svg";
import FromLeft from "../../../../assets/images/FromLeft.svg";
import FromRight from "../../../../assets/images/FromRight.svg";
import styled, { css } from "styled-components";
import {
  ClosureType,
  ProjectPosition,
  ProjectSettings,
  WidthType,
} from "../../../../common/utils/types/project.type";
import {
  initialWidthTypeValues,
  projectInitialValues,
} from "../../../../common/utils/constants/project.consts";
import { handleUpdateProjectsettings } from "../../../../app/store/actions/app.action";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../common/utils/hooks/reduxHook";
import { useLoading } from "../../../../common/utils/hooks/useLoading";
import { useTranslation } from "react-i18next";
import CtGradientButton from "../../../../common/components/CtButton/CtGradientButton";
import { Breakpoints } from "../../../../common/utils/types/app.type";

const StyledImg = styled.img<{ isActive: boolean }>`
  cursor: pointer;
  transition: 0.3s;
  width: 185px;
  height: 185px;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 2px solid ${({ theme }) => theme.palette.primary.main};
          border-radius: 8px;
          opacity: 0.8;
        `
      : css`
          border: 1px solid ${({ theme }) => theme.palette.divider};
          border-radius: 8px;
          opacity: 1;
        `}
`;

const DrawerTheme: FunctionComponent<DrawerThemeProps> = (
  props: DrawerThemeProps
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const { loadingStates, execWithLoader } = useLoading();
  const appState = useAppSelector((state) => state.app);

  const theme = useTheme();
  const [projectSettings, setProjectSettings] =
    useState<ProjectSettings>(projectInitialValues);

  const handleSaveTheme = () => {
    execWithLoader("saveSettings", async () => {
      await dispatch(handleUpdateProjectsettings(projectSettings));
      props.setOpenCustomize(false);
    });
  };

  useEffect(() => {
    if (appState.selectedProject?.settings) {
      setProjectSettings(appState.selectedProject?.settings);
    }
  }, [props.openCustomize, appState.selectedProject?.settings]);

  return (
    <CtDrawer
      title={
        <Box display={"flex"} gap={"8px"} alignItems={"center"}>
          {t("title.customize_topic")} {!isMobile ? <CtGradientButton /> : null}
        </Box>
      }
      placement={"left"}
      width={isMobile ? "100%" : "70%"}
      onClose={() => props.setOpenCustomize(false)}
      open={props.openCustomize}
      isOpen={false}
      setIsOpen={props.setOpenCustomize}
      styles={{
        wrapper: {
          boxShadow: "none",
          borderRight: `1px solid ${lineColor}`,
        },
        mask: {
          background: "transparent",
        },
      }}
      closeIcon={null}
      extra={
        <Box display={"flex"} gap={"8px"}>
          <Tooltip title={t("tooltip.reset_filters")}>
            <CtButton
              icon={<RotateCcw size={14} />}
              onClick={() => setProjectSettings(projectInitialValues)}
            />
          </Tooltip>
          <CtButton
            type={"primary"}
            loading={loadingStates.saveSettings}
            icon={<Save size={14} />}
            onClick={handleSaveTheme}
            disabled={
              projectSettings.widthType === WidthType.PERCENTAGE &&
              projectSettings.width > 100
            }
          >
            {!isMobile && t("buttons.save_my_theme")}
          </CtButton>
          <CtButton
            icon={<X size={14} />}
            onClick={() => props.setOpenCustomize(false)}
          />
        </Box>
      }
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"16px 24px"}
        overflow={"auto"}
        height={"100%"}
      >
        <Box width={isMobile ? "100%" : "50%"}>
          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography fontWeight={"bold"} variant={"h3"}>
                {t("labels.title")}
              </Typography>
              <Typography variant={"caption"}>
                {t("labels.theme.sub_title")}
              </Typography>
            </Box>
            <Box display={"flex"} gap={"8px"}>
              <Input
                value={projectSettings?.title}
                onChange={(e) =>
                  setProjectSettings((prev: ProjectSettings) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
              />

              <ColorPicker
                value={projectSettings.titleColor}
                showText={true}
                onChange={(_, hex) =>
                  setProjectSettings((prev: ProjectSettings) => ({
                    ...prev,
                    titleColor: hex,
                  }))
                }
                style={{ minWidth: "125px" }}
              />
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box width={"100%"}>
          <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography fontWeight={"bold"} variant={"h3"}>
                {t("labels.closure_type")}
              </Typography>
              <Typography variant={"caption"}>
                {t("labels.theme.closure_type")}
              </Typography>
            </Box>

            <Box
              display={"flex"}
              gap={"24px"}
              overflow={isMobile ? "scroll" : "visible"}
            >
              <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                <Typography fontWeight={"bold"}>
                  {t("labels.from_outside")}
                </Typography>
                <StyledImg
                  src={OutsideImg}
                  isActive={projectSettings.closureType === ClosureType.OUTSIDE}
                  onClick={() =>
                    setProjectSettings((prev: ProjectSettings) => ({
                      ...prev,
                      closureType: ClosureType.OUTSIDE,
                    }))
                  }
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                <Typography fontWeight={"bold"}>
                  {t("labels.with_close_icon")}
                </Typography>
                <StyledImg
                  src={CloseIconImg}
                  isActive={projectSettings.closureType === ClosureType.CROSS}
                  onClick={() =>
                    setProjectSettings((prev: ProjectSettings) => ({
                      ...prev,
                      closureType: ClosureType.CROSS,
                    }))
                  }
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                <Typography fontWeight={"bold"}>{t("labels.both")}</Typography>
                <StyledImg
                  src={BothImg}
                  isActive={projectSettings.closureType === ClosureType.BOTH}
                  onClick={() =>
                    setProjectSettings((prev: ProjectSettings) => ({
                      ...prev,
                      closureType: ClosureType.BOTH,
                    }))
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box display={"flex"} alignItems={"center"}>
          <Box width={"100%"}>
            <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontWeight={"bold"} variant={"h3"}>
                  {t("labels.colors")}
                </Typography>
                <Typography variant={"caption"}>
                  {t("labels.theme.colors")}
                </Typography>
              </Box>

              <Box display={"flex"} flexDirection={"column"} gap={"48px"}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"8px"}
                  overflow={isMobile ? "scroll" : "visible"}
                >
                  <Typography fontWeight={"bold"}>
                    {t("labels.main_colors")}
                  </Typography>
                  <Box
                    display={"flex"}
                    gap={"24px"}
                    border={`1px dashed ${theme.palette.divider}`}
                    padding={"16px"}
                    borderRadius={"8px"}
                    width={"fit-content"}
                  >
                    <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                      <Typography fontWeight={"bold"}>
                        {t("labels.top_background")}
                      </Typography>
                      <ColorPicker
                        value={projectSettings.backgroundTopColor}
                        showText={true}
                        onChange={(_, hex) =>
                          setProjectSettings((prev: ProjectSettings) => ({
                            ...prev,
                            backgroundTopColor: hex,
                          }))
                        }
                        style={{ minWidth: "125px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                      <Typography fontWeight={"bold"}>
                        {t("labels.body_text_background")}
                      </Typography>
                      <ColorPicker
                        value={projectSettings.backgroundBody}
                        showText={true}
                        onChange={(_, hex) =>
                          setProjectSettings((prev: ProjectSettings) => ({
                            ...prev,
                            backgroundBody: hex,
                          }))
                        }
                        style={{ minWidth: "125px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                      <Typography fontWeight={"bold"}>
                        {t("labels.body_background")}
                      </Typography>
                      <ColorPicker
                        value={projectSettings.background}
                        showText={true}
                        onChange={(_, hex) =>
                          setProjectSettings((prev: ProjectSettings) => ({
                            ...prev,
                            background: hex,
                          }))
                        }
                        style={{ minWidth: "125px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                      <Typography fontWeight={"bold"}>
                        {t("labels.text_color")}
                      </Typography>
                      <ColorPicker
                        value={projectSettings.textColor}
                        showText={true}
                        onChange={(_, hex) =>
                          setProjectSettings((prev: ProjectSettings) => ({
                            ...prev,
                            textColor: hex,
                          }))
                        }
                        style={{ minWidth: "125px" }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"8px"}
                  overflow={isMobile ? "scroll" : "visible"}
                >
                  <Typography fontWeight={"bold"}>
                    {t("labels.cta_color")}
                  </Typography>
                  <Box
                    display={"flex"}
                    gap={"40px"}
                    border={`1px dashed ${theme.palette.divider}`}
                    padding={"16px"}
                    borderRadius={"8px"}
                    width={"fit-content"}
                  >
                    <Box display={"flex"} gap={"24px"}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"4px"}
                      >
                        <Typography fontWeight={"bold"}>
                          {t("labels.background_color")}
                        </Typography>
                        <ColorPicker
                          value={projectSettings.ctaBgColor}
                          showText={true}
                          onChange={(_, hex) =>
                            setProjectSettings((prev: ProjectSettings) => ({
                              ...prev,
                              ctaBgColor: hex,
                            }))
                          }
                          style={{ minWidth: "125px" }}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"4px"}
                      >
                        <Typography fontWeight={"bold"}>
                          {t("labels.text_color")}
                        </Typography>
                        <ColorPicker
                          value={projectSettings.ctaTextColor}
                          showText={true}
                          onChange={(_, hex) =>
                            setProjectSettings((prev: ProjectSettings) => ({
                              ...prev,
                              ctaTextColor: hex,
                            }))
                          }
                          style={{ minWidth: "125px" }}
                        />
                      </Box>
                    </Box>

                    <Divider type="vertical" style={{ height: "100%" }} />

                    <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                      <Typography fontWeight={"bold"}>
                        {t("labels.render")}
                      </Typography>
                      <CtButton
                        type={"primary"}
                        style={{
                          backgroundColor: projectSettings.ctaBgColor,
                          color: projectSettings.ctaTextColor,
                        }}
                      >
                        {t("buttons.example")}
                      </CtButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box width={"100%"}>
          <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography fontWeight={"bold"} variant={"h3"}>
                {t("labels.width")}
              </Typography>
              <Typography variant={"caption"}>
                {t("labels.theme.width")}
              </Typography>
            </Box>

            <Box display={"flex"} gap={"24px"}>
              <Box display={"flex"} width={"100%"}>
                <Slider
                  min={1}
                  max={
                    projectSettings.widthType === WidthType.PERCENTAGE
                      ? 100
                      : 1500
                  }
                  onChange={(newValue) =>
                    setProjectSettings((prev: ProjectSettings) => ({
                      ...prev,
                      width: newValue,
                    }))
                  }
                  tooltip={{
                    formatter: (value) =>
                      `${value}${
                        projectSettings.widthType === WidthType.PERCENTAGE
                          ? "%"
                          : "px"
                      }`,
                  }}
                  value={projectSettings.width}
                  style={{ width: "250px" }}
                />
                <InputNumber
                  min={1}
                  max={
                    projectSettings.widthType === WidthType.PERCENTAGE
                      ? 100
                      : 1500
                  }
                  style={{ margin: "0 16px" }}
                  addonAfter={
                    projectSettings.widthType === WidthType.PERCENTAGE
                      ? "%"
                      : "px"
                  }
                  value={projectSettings.width}
                  onChange={(newValue) =>
                    setProjectSettings((prev: ProjectSettings) => ({
                      ...prev,
                      width: newValue as number,
                    }))
                  }
                />
                <Segmented
                  value={projectSettings.widthType}
                  options={initialWidthTypeValues}
                  style={{ display: "flex" }}
                  onChange={(newValue) =>
                    setProjectSettings((prev: ProjectSettings) => ({
                      ...prev,
                      widthType: newValue,
                    }))
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box width={"100%"}>
          <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography fontWeight={"bold"} variant={"h3"}>
                {t("labels.position")}
              </Typography>
              <Typography variant={"caption"}>
                {t("labels.theme.position")}
              </Typography>
            </Box>

            <Box
              display={"flex"}
              gap={"24px"}
              overflow={isMobile ? "scroll" : "visible"}
            >
              <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                <Typography fontWeight={"bold"}>
                  {t("labels.from_left")}
                </Typography>
                <StyledImg
                  src={FromLeft}
                  isActive={projectSettings.position === ProjectPosition.LEFT}
                  onClick={() =>
                    setProjectSettings((prev: ProjectSettings) => ({
                      ...prev,
                      position: ProjectPosition.LEFT,
                    }))
                  }
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                <Typography fontWeight={"bold"}>
                  {t("labels.from_right")}
                </Typography>
                <StyledImg
                  src={FromRight}
                  isActive={projectSettings.position === ProjectPosition.RIGHT}
                  onClick={() =>
                    setProjectSettings((prev: ProjectSettings) => ({
                      ...prev,
                      position: ProjectPosition.RIGHT,
                    }))
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CtDrawer>
  );
};

interface DrawerThemeProps {
  openCustomize: boolean;
  setOpenCustomize: Dispatch<SetStateAction<boolean>>;
}

export default DrawerTheme;
