import { toast } from "react-toastify";
import pkAxios from "./axios";
import { AuthType } from "../types/auth.type";
import i18n from "../../../i18n";

class UserService {
  async getMe() {
    try {
      const response = await pkAxios.get(`/api/user/me`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async updateMe(formAccount: AuthType, hideToast?: boolean) {
    try {
      const response = await pkAxios.put(`/api/user/me`, formAccount);

      if (response?.status === 200 && !hideToast) {
        toast.success(i18n.t("toast.update_me"));
      }

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async getPublicUser(userId?: string) {
    try {
      const response = await pkAxios.get(`/api/user/public/${userId}`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }
}

export default UserService;
