import { Dispatch, SetStateAction } from "react";
import { Topic, TopicSort, TopicStatus } from "../types/topics.type";
import i18n from "../../../i18n";

export const sortTopic = [
  {
    id: TopicSort.ALPHABETICAL,
    value: "alphabetical",
    label: i18n.t("labels.alphabetical"),
  },
  {
    id: TopicSort.MOSTRECENT,
    value: "newest",
    label: i18n.t("labels.newest_first"),
  },
  {
    id: TopicSort.LEASTRECENT,
    value: "oldest",
    label: i18n.t("labels.oldest_first"),
  },
  {
    id: TopicSort.MOSTVIEWED,
    value: "viewed",
    label: i18n.t("labels.most_viewed"),
  },
];

export const statusTopic = [
  { id: TopicStatus.DRAFT, value: "cyan", label: i18n.t("labels.draft") },
  { id: TopicStatus.LIVE, value: "green", label: i18n.t("labels.live") },
  {
    id: TopicStatus.EXPIRED,
    value: "red",
    label: i18n.t("labels.expired"),
  },
];

export const filtersInitialValue = {
  status: null,
  categoryId: null,
  search: null,
  sort: TopicSort.MOSTRECENT,
};

export interface TopicItemProps {
  topic: Topic;
  setOpenTopic: Dispatch<SetStateAction<boolean>>;
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  selectedItems: string[];
}
