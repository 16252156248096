import { FunctionComponent, useEffect, useState } from "react";
import CtLayout from "../../../common/components/CtLayout/CtLayout";
import CtContent from "../../../common/components/CtLayout/CtContent";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SubNav from "../components/SubNav";
import SettingsContent from "../components/SettingsContent";
import { Input, Tooltip } from "antd";
import { Info, LockKeyhole, Mail, Save, TextCursor } from "lucide-react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../common/utils/hooks/reduxHook";
import { AuthType } from "../../../common/utils/types/auth.type";
import useDebounce from "../../../common/utils/hooks/useDebounce";
import { isValidEmail } from "../../../common/utils/functions";
import CtButton from "../../../common/components/CtButton/CtButton";
import { useLoading } from "../../../common/utils/hooks/useLoading";
import { useTranslation } from "react-i18next";
import { handleUpdateMe } from "../../../app/store/actions/user.action";
import { Breakpoints } from "../../../common/utils/types/app.type";
import SubNavLayout from "../components/SubNavLayout";

const Account: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);
  const theme = useTheme();
  const { loadingStates, execWithLoader } = useLoading();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errors, setErrors] = useState<{
    email?: string;
    password?: string;
    confirmPassword?: string;
  }>({});

  const [formAccount, setFormAccount] = useState<AuthType>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    setFormAccount({
      firstName: userState.infos?.firstName,
      lastName: userState.infos?.lastName,
      email: userState.infos?.email,
      password: "",
    });
  }, [userState.infos]);

  const debouncedEmail = useDebounce(formAccount.email, 500);
  const debouncedPassword = useDebounce(formAccount.password, 500);
  const debouncedConfirmPassword = useDebounce(confirmPassword, 500);

  const handleSaveAccount = () => {
    execWithLoader("saveAccount", async () => {
      if (confirmPassword === formAccount.password) {
        dispatch(handleUpdateMe({ formAccount }));
      }
    });
  };

  useEffect(() => {
    const newErrors: {
      email?: string;
      password?: string;
      confirmPassword?: string;
    } = {};
    if (!isValidEmail(debouncedEmail) && debouncedEmail.length > 0) {
      newErrors.email = t("labels.email_incorrect");
    }
    if (debouncedPassword.length < 5 && debouncedPassword.length > 0) {
      newErrors.password = t("labels.password_five_characters_long");
    }
    if (debouncedConfirmPassword !== debouncedPassword) {
      newErrors.confirmPassword = t("labels.password_mismatch");
    }

    setErrors(newErrors);
  }, [debouncedEmail, debouncedPassword, debouncedConfirmPassword]);

  return (
    <SubNavLayout
      openMobileNavbar={openMobileNavbar}
      setOpenMobileNavbar={setOpenMobileNavbar}
    >
      <CtLayout isCollapsed={true}>
        <CtContent>
          <Box display={"flex"} height={"100%"}>
            {isMobile ? <></> : <SubNav />}

            <SettingsContent
              title={t("title.my_account")}
              openMobileNavbar={openMobileNavbar}
              setOpenMobileNavbar={setOpenMobileNavbar}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                gap={"16px"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  width={"100%"}
                >
                  <Typography fontWeight={"bold"} variant={"caption"}>
                    {t("labels.first_name")}
                  </Typography>
                  <Input
                    value={formAccount.firstName}
                    prefix={
                      <Box display={"flex"} mr={"4px"}>
                        <TextCursor size={14} color={theme.palette.grey[400]} />
                      </Box>
                    }
                    onChange={(e) =>
                      setFormAccount((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }))
                    }
                  />
                </Box>

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  width={"100%"}
                >
                  <Typography fontWeight={"bold"} variant={"caption"}>
                    {t("labels.last_name")}
                  </Typography>
                  <Input
                    value={formAccount.lastName}
                    prefix={
                      <Box display={"flex"} mr={"4px"}>
                        <TextCursor size={14} color={theme.palette.grey[400]} />
                      </Box>
                    }
                    onChange={(e) =>
                      setFormAccount((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }))
                    }
                  />
                </Box>

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  width={"100%"}
                >
                  <Typography fontWeight={"bold"} variant={"caption"}>
                    {t("labels.email")}
                  </Typography>
                  <Input
                    value={formAccount.email}
                    prefix={
                      <Box display={"flex"} mr={"4px"}>
                        <Mail size={14} color={theme.palette.grey[400]} />
                      </Box>
                    }
                    onChange={(e) =>
                      setFormAccount((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                  {errors.email && (
                    <Typography color="error" variant="caption">
                      {errors.email}
                    </Typography>
                  )}
                </Box>

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  width={"100%"}
                >
                  <Typography fontWeight={"bold"} variant={"caption"}>
                    {t("labels.password")}
                  </Typography>
                  <Input.Password
                    placeholder={t("labels.type_password")}
                    autoComplete="new-password"
                    status={errors.password ? "error" : undefined}
                    prefix={
                      <Box display={"flex"} mr={"4px"}>
                        <LockKeyhole
                          size={14}
                          color={theme.palette.grey[400]}
                        />
                      </Box>
                    }
                    value={formAccount.password}
                    onChange={(e) =>
                      setFormAccount((prev: AuthType) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />
                  {errors.password && (
                    <Typography color="error" variant="caption">
                      {errors.password}
                    </Typography>
                  )}
                </Box>

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  width={"100%"}
                >
                  <Typography fontWeight={"bold"} variant={"caption"}>
                    {t("labels.confirm_password")}
                  </Typography>
                  <Input.Password
                    placeholder={t("labels.confirm_password")}
                    autoComplete="new-password"
                    status={errors.confirmPassword ? "error" : undefined}
                    prefix={
                      <Box display={"flex"} mr={"4px"}>
                        <LockKeyhole
                          size={14}
                          color={theme.palette.grey[400]}
                        />
                      </Box>
                    }
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {errors.confirmPassword && (
                    <Typography color="error" variant="caption">
                      {errors.confirmPassword}
                    </Typography>
                  )}
                </Box>

                <Box display={"flex"} alignItems={"center"} gap={"12px"}>
                  <Tooltip title={t("tooltip.password_unchanged")}>
                    <Info size={20} color={theme.palette.grey[800]} />
                  </Tooltip>
                  <CtButton
                    type={"primary"}
                    icon={<Save size={14} />}
                    style={{ width: "100%" }}
                    onClick={handleSaveAccount}
                    disabled={confirmPassword !== formAccount.password}
                    loading={loadingStates.saveAccount}
                  >
                    {t("buttons.save")}
                  </CtButton>
                </Box>
              </Box>
            </SettingsContent>
          </Box>
        </CtContent>
      </CtLayout>
    </SubNavLayout>
  );
};

export default Account;
