import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App.tsx";
import { CssBaseline, ThemeProvider as MUIThemeProvider } from "@mui/material";
import { ctTheme } from "./common/utils/theme/theme.ts";
import {
  StyleSheetManager,
  ThemeProvider as SCThemeProvider,
} from "styled-components";
import "./assets/styles/main.css";
import "./assets/styles/rte.css";
import isPropValid from "@emotion/is-prop-valid";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./app/store/store.ts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToolioProvider } from "npmlibhelpcontextual";
import { MantineProvider } from "@mantine/core";

function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === "string") {
    return isPropValid(propName);
  }

  return true;
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ToolioProvider projectId={"f07cf804-2865-4856-bf12-a1c5e139dc5e"}>
    <MantineProvider>
      <MUIThemeProvider theme={ctTheme}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <SCThemeProvider theme={ctTheme}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Router>
                  <CssBaseline />
                  <App />
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                  />
                </Router>
              </PersistGate>
            </Provider>
          </SCThemeProvider>
        </StyleSheetManager>
      </MUIThemeProvider>
    </MantineProvider>
  </ToolioProvider>
);
