import { toast } from "react-toastify";
import pkAxios from "./axios";
import i18n from "../../../i18n";

class TeamService {
  async getTeam(teamId: string) {
    try {
      const response = await pkAxios.get(`/api/team/${teamId}`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async getPublicTeam(teamId: string) {
    try {
      const response = await pkAxios.get(`/api/team/public/${teamId}`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async inviteNewMember(teamId: string, email: string) {
    try {
      const response = await pkAxios.post(`/api/team/${teamId}/invite`, {
        email,
      });

      if (response?.status === 200) {
        toast.success(i18n.t("toast.invitation_sent"));
      }

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async removeMember(teamId: string, userId: string) {
    try {
      const response = await pkAxios.delete(
        `/api/team/${teamId}/remove/${userId}`
      );

      if (response?.status === 200) {
        toast.success(i18n.t("toast.remove_member"));
      }
      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }
}

export default TeamService;
