import { Box, Typography, useMediaQuery } from "@mui/material";
import { FunctionComponent, ReactNode, useEffect } from "react";
import backgroundAuth from "../../assets/images/backgroundAuth.png";
import styled from "styled-components";
import CtButton from "../../common/components/CtButton/CtButton";
import { useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../common/utils/constants/app.consts";
import { useTranslation } from "react-i18next";
import CtSelect from "../../common/components/CtSelect/CtSelect";
import { Globe } from "lucide-react";
import i18n from "../../i18n";
import iconLogo from "../../assets/images/iconLogo.svg";
import { Breakpoints } from "../../common/utils/types/app.type";
import CtLink from "../../common/components/CtLink/CtLink";

const StyledBackground = styled.img`
  border-radius: 16px;
  width: 100%;
  height: 100%;
`;

const AuthLayout: FunctionComponent<AuthLayoutProps> = (
  props: AuthLayoutProps
) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = "/";
    }
  }, [isAuthenticated]);

  return (
    <Box display={"flex"} height={"100%"} padding={"16px"} gap={"32px"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={isMobile ? "100%" : "40%"}
        pl={isMobile ? "0" : "16px"}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          margin={"16px 0"}
        >
          <CtLink
            href={"https://www.usetoolio.com/"}
            external={true}
            label={<img src={iconLogo} alt="Icon Logo" width={"30px"} />}
          />

          <Box display={"flex"} alignItems={"center"} gap={"8px"}>
            {location.pathname === "/login" ? (
              <>
                <Typography variant={"caption"}>
                  {t("labels.new_to_toolio")}
                </Typography>
                <CtButton onClick={() => navigate("/signup")}>
                  {t("buttons.register")}
                </CtButton>
              </>
            ) : (
              <>
                <Typography variant={"caption"}>
                  {t("labels.already_have_account")}
                </Typography>
                <CtButton onClick={() => navigate("/login")}>
                  {t("buttons.login")}
                </CtButton>
              </>
            )}
          </Box>
        </Box>
        {props.children}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Typography>&copy; Toolio 2024</Typography>
          <Box display={"flex"} alignItems={"center"}>
            <Globe size={14} />
            <CtSelect
              defaultValue="FR"
              value={localStorage.getItem("i18nextLng")?.toUpperCase()}
              variant="borderless"
              options={[
                { value: "FR", label: "FR" },
                { value: "EN", label: "EN" },
              ]}
              onChange={(newValue) =>
                i18n.changeLanguage(newValue.toLowerCase())
              }
            />
          </Box>
        </Box>
      </Box>
      {!isMobile ? (
        <Box width={"60%"}>
          <StyledBackground src={backgroundAuth} alt="Auth Background" />
        </Box>
      ) : null}
    </Box>
  );
};

interface AuthLayoutProps {
  children: ReactNode;
}

export default AuthLayout;
