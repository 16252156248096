import { Box, Typography, useMediaQuery } from "@mui/material";
import { Tag } from "antd";
import React, {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
} from "react";
import CtGradientButton from "../../../common/components/CtButton/CtGradientButton";
import { Breakpoints } from "../../../common/utils/types/app.type";
import { Menu } from "lucide-react";
import CtCard from "../../../common/components/CtCard/CtCard";

const SettingsContent: FunctionComponent<SettingsContentProps> = (
  props: SettingsContentProps
) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  return (
    <Box
      p={isMobile ? "32px" : "32px 64px"}
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      overflow={"scroll"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        gap={"16px"}
        maxWidth={"1024px"}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"} gap={"8px"}>
            {isMobile ? (
              <Menu
                onClick={() =>
                  props.setOpenMobileNavbar(!props.openMobileNavbar)
                }
              />
            ) : null}
            <Typography variant="h1">{props.title}</Typography>
            {props.tag ? <Tag>{props.tag}</Tag> : null}
            <Box ml={"10px"}>
              <CtGradientButton />
            </Box>
          </Box>

          {props.extraAttributes}
        </Box>

        <CtCard hideBg={props.hideBg}>{props.children}</CtCard>
      </Box>
    </Box>
  );
};

interface SettingsContentProps {
  children: ReactNode;
  title: string;
  tag?: string;
  hideBg?: boolean;
  extraAttributes?: ReactNode;

  setOpenMobileNavbar: Dispatch<SetStateAction<boolean>>;
  openMobileNavbar: boolean;
}

export default SettingsContent;
