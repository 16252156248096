import { Box } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";

const CtContent: FunctionComponent<CtContentProps> = (
  props: CtContentProps
) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      height={"100vh"}
      overflow={"scroll"}
      {...props}
    />
  );
};

interface CtContentProps {
  children: ReactNode;
}

export default CtContent;
