"use client";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { NavigationProps } from "../common/utils/types/app.type";
import { FunctionComponent, ReactNode, useEffect } from "react";
import {
  AuthNavigation,
  Navigation,
} from "../common/utils/constants/navigation";
import { isAuthenticated } from "../common/utils/constants/app.consts";
import { useAppDispatch } from "../common/utils/hooks/reduxHook";
import { handleInitMe } from "./store/actions/user.action";

const App = () => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!AuthNavigation.some((item) => item.link === currentRoute)) {
      dispatch(handleInitMe());
    }
  }, [currentRoute, dispatch]);

  return (
    <Routes location={location} key={location.pathname}>
      {Navigation.map((item: NavigationProps, index: number) => {
        return (
          <Route
            key={index}
            path={item.link}
            element={
              <ProtectedRoute isLoggedIn={Boolean(isAuthenticated)}>
                {item.element}
              </ProtectedRoute>
            }
          />
        );
      })}

      {AuthNavigation.map((item: NavigationProps, index: number) => {
        return <Route key={index} path={item.link} element={item.element} />;
      })}
    </Routes>
  );
};

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  isLoggedIn,
  children,
}: ProtectedRouteProps) => {
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
};

interface ProtectedRouteProps {
  isLoggedIn: boolean;
  children: ReactNode;
}

export default App;
