import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CtLayout from "../../../common/components/CtLayout/CtLayout";
import ChHeader from "../components/ChHeader";
import { Box, Typography, useMediaQuery } from "@mui/material";
import CtContent from "../../../common/components/CtLayout/CtContent";
import TopicItem from "../components/Topics/TopicItem";
import {
  handleGetCategories,
  handleGetProjectById,
  handleGetTopics,
} from "../../../app/store/actions/topics.action";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../common/utils/hooks/reduxHook";
import {
  Topic,
  TopicAllProps,
  TopicDisplayMode,
} from "../../../common/utils/types/topics.type";
import { FilterX } from "lucide-react";
import CtButton from "../../../common/components/CtButton/CtButton";
import notFound from "../../../assets/images/notFound.svg";
import { StatusType } from "../../../common/utils/types/status.type";
import { filtersInitialValue } from "../../../common/utils/constants/topics.consts";
import { debounce } from "../../../common/utils/functions";
import { useTranslation } from "react-i18next";
import { Breakpoints } from "../../../common/utils/types/app.type";
import { Skeleton } from "antd";

const ContextualHelp: FunctionComponent = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const [openTopic, setOpenTopic] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const [settings, setSettings] =
    useState<Omit<TopicAllProps, "projectId">>(filtersInitialValue);
  const isInitialized = useRef(false);

  const topicsState = useAppSelector((state) => state.topics);
  const isRowMode = topicsState.settings?.displayMode === TopicDisplayMode.ROW;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(handleGetProjectById({}));
    dispatch(handleGetCategories());
  }, []);

  const debouncedGetTopics = useCallback(
    debounce((settings: Omit<TopicAllProps, "projectId">) => {
      if (isInitialized.current) {
        dispatch(handleGetTopics(settings));
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
    } else {
      debouncedGetTopics(settings);
    }
  }, [settings]);

  return (
    <CtLayout>
      <CtContent>
        {topicsState?.status === StatusType.Loading ? (
          <>
            <Box
              display={"flex"}
              padding={"24px 26px"}
              justifyContent={"space-between"}
            >
              <Skeleton.Button
                active={true}
                size={"large"}
                shape={"square"}
                block={true}
                style={{ width: "30%" }}
              />
              <Skeleton.Button
                active={true}
                size={"large"}
                shape={"square"}
                block={true}
                style={{ width: "100%" }}
              />
            </Box>
            <Box
              display={"flex"}
              paddingX={"26px"}
              justifyContent={"space-between"}
            >
              <Skeleton.Button
                active={true}
                size={"large"}
                shape={"square"}
                block={true}
                style={{ width: "30%" }}
              />
            </Box>
          </>
        ) : (
          <ChHeader
            openTopic={openTopic}
            setOpenTopic={setOpenTopic}
            setSettings={setSettings}
            settings={settings}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        )}

        {topicsState?.status === StatusType.Loading ? (
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            gap={isRowMode ? "30px" : "16px"}
            padding={"24px 26px"}
          >
            <Skeleton.Button
              active={true}
              size={"large"}
              shape={"square"}
              block={true}
              style={{
                height: "150px",
                width: isRowMode ? "100%" : "calc(33% - 7px)",
              }}
            />
          </Box>
        ) : topicsState?.filteredList?.length ? (
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            gap={isRowMode ? "30px" : "16px"}
            padding={"24px 26px"}
          >
            {topicsState?.filteredList?.map((topic: Topic) => {
              return (
                <TopicItem
                  key={topic.id}
                  topic={topic}
                  setOpenTopic={setOpenTopic}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                />
              );
            })}
          </Box>
        ) : topicsState?.filteredList?.length === 0 &&
          topicsState?.status === StatusType.Succeeded ? (
          <Box m={"24px 0"}>
            <Box
              display={"flex"}
              flexDirection={isMobile ? "column" : "row"}
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
              gap={"24px"}
            >
              <img src={notFound} width={isMobile ? "250px" : "25%"} />

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={isMobile ? "center" : "flex-start"}
                gap={"16px"}
              >
                <Box
                  display={"flex"}
                  textAlign={isMobile ? "center" : "left"}
                  flexDirection={"column"}
                  gap={"4px"}
                  width={"300px"}
                >
                  <Typography variant={"h1"}>
                    {t("title.no_results")}
                  </Typography>
                  <Typography variant={"h3"} fontWeight={500}>
                    {t("title.no_topic_found")}
                  </Typography>
                </Box>
                <CtButton
                  icon={<FilterX size={14} />}
                  onClick={() => setSettings(filtersInitialValue)}
                >
                  {t("buttons.reset_filters")}
                </CtButton>
              </Box>
            </Box>
          </Box>
        ) : null}
      </CtContent>
    </CtLayout>
  );
};

export default ContextualHelp;
