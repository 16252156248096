import AuthService from "./AuthService";
import CategoriesService from "./CategoriesService";
import ProjectService from "./ProjectService";
import TeamService from "./TeamService";
import TopicsService from "./TopicsService";
import UserService from "./UserService";

export const authService = new AuthService();
export const projectService = new ProjectService();
export const userService = new UserService();
export const topicService = new TopicsService();
export const teamService = new TeamService();
export const categoriyService = new CategoriesService();
