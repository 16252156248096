import React, { FunctionComponent, useRef, useState } from "react";
import {
  Button,
  Input,
  InputRef,
  Space,
  Table,
  TableColumnType,
  TableColumnsType,
  Tag,
  Tooltip,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { Mail, Search, Trash } from "lucide-react";
import Highlighter from "react-highlight-words";
import { User } from "../../../common/utils/types/user.type";
import dayjs from "dayjs";
import CtButton from "../../../common/components/CtButton/CtButton";
import { Box, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../../../common/utils/hooks/reduxHook";
import modal from "antd/es/modal";
import { teamService } from "../../../common/utils/services";
import { useTranslation } from "react-i18next";

const TableUsers: FunctionComponent<TableUsersProps> = (
  props: TableUsersProps
) => {
  const { t } = useTranslation();
  const userState = useAppSelector((state) => state.user);
  const appState = useAppSelector((state) => state.app);
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<Search size={14} />}
            size="small"
            style={{ width: 90 }}
          >
            {t("buttons.search")}
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                handleReset(clearFilters);
              }
              handleSearch([] as string[], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("buttons.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <Search size={14} style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] ?? "")
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const removeMember = (item) => {
    const config = {
      title: (
        <Typography fontWeight={"bold"}>{t("title.remove_member")}</Typography>
      ),
      content: (
        <Typography variant={"caption"}>
          {t("labels.remove_member", {
            memberName: <strong>{item.name}</strong>,
          })}
        </Typography>
      ),
    };

    modal.confirm({
      ...config,
      onOk: async () => {
        if (appState.selectedProject?.teamId) {
          await teamService.removeMember(
            appState.selectedProject?.teamId,
            item.key
          );

          props.getTeamUsers();
        }
      },
    });
  };

  const handleResendInvitation = async (item) => {
    if (appState?.selectedProject?.teamId) {
      await teamService.inviteNewMember(
        appState?.selectedProject?.teamId,
        item.email
      );
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: t("table.name"),
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => (a.name ?? "").length - (b.name ?? "").length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("table.email"),
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => (a.email ?? "").length - (b.email ?? "").length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => (a.createdAt ?? "").length - (b.createdAt ?? "").length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("table.created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      ...getColumnSearchProps("createdAt"),
      sorter: (a, b) => (a.createdAt ?? "").length - (b.createdAt ?? "").length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "",
      key: "operation",
      width: 50,
      render: (item) => {
        return (
          <Box display={"flex"} gap={"16px"} justifyContent={"flex-end"}>
            {userState.infos?.id !== item.key && !item.createdAt ? (
              <Tooltip title={t("tooltip.resend_invitation")}>
                <CtButton
                  type={"link"}
                  onClick={() => handleResendInvitation(item)}
                  style={{ padding: "inherit" }}
                >
                  <Mail size={14} color={theme.palette.primary.main} />
                </CtButton>
              </Tooltip>
            ) : null}

            {userState.infos?.id !== item.key ? (
              <Tooltip title={t("tooltip.remove_member")}>
                <CtButton
                  type={"link"}
                  onClick={() => removeMember(item)}
                  style={{ padding: "inherit" }}
                >
                  <Trash size={14} color={theme.palette.error.main} />
                </CtButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={[
        ...props.listUsers.map((item: Partial<User>) => ({
          key: item.id,
          name:
            item.firstName && item.lastName
              ? item.firstName + " " + item.lastName
              : "",
          email: item.email ?? "",
          status: <Tag color="green">Created</Tag>,
          createdAt: item.createdAt
            ? dayjs(item.createdAt).format("DD/MM/YYYY")
            : "",
        })),
        ...props.invitedMembers.map((item: Partial<User>) => ({
          key: item.id,
          name:
            item.firstName && item.lastName
              ? item.firstName + " " + item.lastName
              : "",
          email: item.email ?? "",
          status: <Tag color="orange">Pending</Tag>,
          createdAt: item.createdAt
            ? dayjs(item.createdAt).format("DD/MM/YYYY")
            : "",
        })),
      ]}
    />
  );
};

interface DataType {
  key: string | undefined;
  name: string;
  email: string | undefined;
  createdAt: string;
}

type DataIndex = keyof DataType;

interface TableUsersProps {
  listUsers: Partial<User>[];
  invitedMembers: Partial<User>[];
  getTeamUsers: () => Promise<void>;
}

export default TableUsers;
