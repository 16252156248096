import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app.reducer";
import topicsReducer from "./reducers/topics.reducer";
import userReducer from "./reducers/user.reducer";
import storageSession from "redux-persist/lib/storage/session";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whiteList: ["topics"],
};

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  topics: topicsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
