import { createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../../common/utils/types/status.type";
import { AppState } from "../../../common/utils/types/app.type";
import { handleInitMe } from "../actions/user.action";
import { handleGetProjectById } from "../actions/topics.action";
import {
  handleCreateProject,
  handleDeleteProject,
  handleUpdateProject,
  handleUpdateProjectsettings,
} from "../actions/app.action";

const initialState: AppState = {
  selectedProject: null,
  status: StatusType.Idle,
  error: null,
};

const { reducer } = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(handleDeleteProject.fulfilled, (state) => {
      state.selectedProject = null;
    });

    builder.addCase(handleInitMe.fulfilled, (state, { payload }) => {
      if (!state.selectedProject?.id) {
        state.selectedProject = payload.projects[0];
      }
      state.status = StatusType.Succeeded;
    });

    builder.addCase(handleGetProjectById.fulfilled, (state, { payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { topics, ...rest } = payload;

      state.selectedProject = rest;
      state.status = StatusType.Succeeded;
    });

    builder.addCase(
      handleUpdateProjectsettings.fulfilled,
      (state, { payload }) => {
        if (state.selectedProject) {
          state.selectedProject.settings = payload;
        }
      }
    );

    builder.addCase(handleCreateProject.fulfilled, (state, { payload }) => {
      state.selectedProject = {
        id: payload.id,
        name: payload.name,
      };
    });

    builder.addCase(handleUpdateProject.fulfilled, (state, { payload }) => {
      if (state.selectedProject?.name) {
        state.selectedProject.name = payload.name;
      }
    });
  },
});

export default reducer;
