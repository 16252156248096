import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import ICU from "i18next-icu";
import translationEN from "./assets/locales/en/translation.json";
import translationFR from "./assets/locales/fr/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(ICU)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "fr",
    detection: {
      order: ["navigator", "localStorage"],
    },
    supportedLngs: ["fr", "en"],
    lng: "fr",
    interpolation: {
      format: function (value, format) {
        if (format === "capitalize") {
          return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
      },
    },
  });

i18n.services.formatter?.add("capitalize", (value, lng, options) => {
  if (options.format === "capitalize") {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return value;
});

export default i18n;
