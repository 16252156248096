import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService, userService } from "../../../common/utils/services";
import { AuthType } from "../../../common/utils/types/auth.type";

export const handleInitMe = createAsyncThunk(
  "user/getMe",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userService.getMe();

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleUpdateMe = createAsyncThunk(
  "user/updateMe",
  async (
    { formAccount, hideToast }: { formAccount: AuthType; hideToast?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await userService.updateMe(formAccount, hideToast);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleLogin = createAsyncThunk(
  "user/login",
  async (formLogin: AuthType, { rejectWithValue }) => {
    try {
      const response = await authService.login(formLogin);
      const data = response.data;

      if (response?.status === 200) {
        localStorage.setItem("infosLogin", data?.token);

        if (!data?.user?.projects.length) {
          window.location.href = "/new-project";
        } else if (data?.token) {
          window.location.href = "/";
        }
      }

      return data?.user;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
