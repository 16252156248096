import styled, { css } from "styled-components";
import { convertHexToRGBA } from "../../utils/functions";
import { Box } from "@mui/material";

export const StyledNavItem = styled(Box)<{
  isActive?: boolean;
  noPadding?: boolean;
  $height?: string;
  top?: string;
  borderRadius?: string;
  color?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: ${({ noPadding }) => (noPadding ? 0 : "0 12px")};
  width: 100%;
  cursor: pointer;
  transition: 0.3s;

  color: ${({ theme, isActive }) =>
    isActive ? theme.palette.primary.main : theme.palette.text.primary};

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: ${({ noPadding }) => (noPadding ? "-12px" : "0")};
    border-radius: ${({ borderRadius }) => borderRadius ?? "12px"};
    transition: all 0.3s;
    background-color: transparent;
  }

  ${({ isActive }) =>
    !isActive
      ? css<{ $height?: string; top?: string; color?: string }>`
          &:hover {
            color: ${({ color, theme }) => color ?? theme.palette.primary.main};

            &:before {
              height: ${({ $height }) => $height ?? "calc(100% + 20px)"};
              top: ${({ top }) => top ?? "-10px"};
              background-color: ${({ theme, color }) =>
                convertHexToRGBA(color ?? theme.palette.primary.main, 0.1)};
            }

            svg,
            h6 {
              transition: all 0.3s;
              color: ${({ color, theme }) =>
                color ?? theme.palette.primary.main};
            }
          }
        `
      : css<{ $height?: string; top?: string }>`
          &:before {
            height: ${({ $height }) => $height ?? "calc(100% + 20px)"};
            top: ${({ top }) => top ?? "-10px"};
            background-color: ${({ theme }) =>
              convertHexToRGBA(theme.palette.primary.main, 0.1)};
          }
        `}
`;
