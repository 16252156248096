import { ctOriginalBlack, ctBlue, ctOriginalWhite } from "../theme/colors";
import {
  ClosureType,
  ProjectPosition,
  ProjectSettings,
  WidthType,
} from "../types/project.type";

export const projectInitialValues: ProjectSettings = {
  title: "HELP CENTER",
  titleColor: ctOriginalWhite,
  closureType: ClosureType.BOTH,
  backgroundTopColor: "#424242",
  backgroundBody: "#fcfcfc",
  background: "#fcfcfc",
  textColor: ctOriginalBlack,
  ctaBgColor: ctBlue,
  ctaTextColor: ctOriginalWhite,
  position: ProjectPosition.RIGHT,
  width: 350,
  widthType: WidthType.PX,
};

export const initialWidthTypeValues = [
  { label: "Px", value: WidthType.PX },
  { label: "%", value: WidthType.PERCENTAGE },
];
