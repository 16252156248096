import { Topic } from "./topics.type";

export interface Project {
  id: string;
  name: string;
  teamId?: string;
  topics?: Topic[];
  settings?: ProjectSettings;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum ClosureType {
  OUTSIDE = "OUTSIDE",
  CROSS = "CROSS",
  BOTH = "BOTH",
}

export enum ProjectPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum WidthType {
  PX = "PX",
  PERCENTAGE = "PERCENTAGE",
}

export interface ProjectSettings {
  id?: string;
  title: string;
  titleColor: string;
  closureType: ClosureType | null;
  backgroundTopColor: string;
  backgroundBody: string;
  background: string;
  textColor: string;
  ctaBgColor: string;
  ctaTextColor: string;
  position: ProjectPosition | null;
  width: number;
  widthType: WidthType;
}
