import { Node, mergeAttributes } from "@tiptap/core";

const VideoResize = Node.create({
  name: "iframe",

  group: "block",

  selectable: true,

  draggable: true,

  atom: true, // Important pour les nœuds autonomes

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: "80%", // Taille par défaut
      },
      height: {
        default: "auto",
      },
      style: {
        default: "cursor: pointer;", // Style par défaut pour le redimensionnement
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "iframe",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["iframe", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ({ node, editor, getPos }) => {
      const { src, width, height, style } = node.attrs;

      const $wrapper = document.createElement("div");
      const $container = document.createElement("div");
      const $iframe = document.createElement("iframe");

      $wrapper.setAttribute("style", `width: 100%; text-align: center;`);
      $container.setAttribute(
        "style",
        `width: ${width}; position: relative; display: flex; ${style}`
      );
      $iframe.setAttribute("src", src);
      $iframe.setAttribute("frameborder", "0");
      $iframe.setAttribute("allowfullscreen", "true");
      $iframe.style.width = "100%";
      $iframe.style.height = height;
      $container.appendChild($iframe);
      $wrapper.appendChild($container);

      // Redimensionnement logique
      let isResizing = false;
      let startX, startWidth;

      const showResizeHandles = () => {
        $container.style.border = "1px dashed #6C6C6C";

        // Ajoutez des poignées de redimensionnement
        Array.from({ length: 4 }, (_, index) => {
          const $dot = document.createElement("div");
          $dot.setAttribute(
            "style",
            `position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; cursor: nwse-resize;`
          );
          // Logique de positionnement des poignées de redimensionnement
          if (index === 0)
            ($dot.style.top = "-4px"), ($dot.style.left = "-4px");
          if (index === 1)
            ($dot.style.top = "-4px"), ($dot.style.right = "-4px");
          if (index === 2)
            ($dot.style.bottom = "-4px"), ($dot.style.left = "-4px");
          if (index === 3)
            ($dot.style.bottom = "-4px"), ($dot.style.right = "-4px");

          $dot.addEventListener("mousedown", (e) => {
            e.preventDefault();
            isResizing = true;
            startX = e.clientX;
            startWidth = $container.offsetWidth;

            const onMouseMove = (e) => {
              if (!isResizing) return;
              const deltaX =
                index % 2 === 0 ? -(e.clientX - startX) : e.clientX - startX;
              const newWidthPx = startWidth + deltaX;
              const newWidthPercent = (newWidthPx / $wrapper.offsetWidth) * 100; // Convertir en pourcentage
              $container.style.width = `${newWidthPercent}%`; // Ajuster la largeur du conteneur
            };

            const onMouseUp = () => {
              if (isResizing) {
                isResizing = false;
              }
              dispatchNodeView();
              document.removeEventListener("mousemove", onMouseMove);
              document.removeEventListener("mouseup", onMouseUp);
            };

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
          });

          $container.appendChild($dot);
        });
      };

      const hideResizeHandles = () => {
        $container.style.border = "none";
        while ($container.childElementCount > 1) {
          $container.removeChild($container.lastChild as any);
        }
      };

      $container.addEventListener("click", () => {
        hideResizeHandles();
        showResizeHandles();
      });

      document.addEventListener("click", (e) => {
        const $target = e.target;
        if (!$container.contains($target as any) && $container !== $target) {
          hideResizeHandles();
        }
      });

      const dispatchNodeView = () => {
        if (typeof getPos === "function") {
          const newAttrs = {
            ...node.attrs,
            width: $container.style.width,
          };
          editor.view.dispatch(
            editor.view.state.tr.setNodeMarkup(getPos(), null, newAttrs)
          );
        }
      };

      return {
        dom: $wrapper,
      };
    };
  },
});

export default VideoResize;
