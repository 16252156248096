import { Box } from "@mui/material";
import { Tooltip } from "antd";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from "react";
import CtSelect from "../../../common/components/CtSelect/CtSelect";
import CtButton from "../../../common/components/CtButton/CtButton";
import TagsManagement from "./TagsManagement";
import { Eye, EyeOff, Settings } from "lucide-react";
import { useAppSelector } from "../../../common/utils/hooks/reduxHook";
import {
  Category,
  ElementsSettings,
} from "../../../common/utils/types/topics.type";

const TagDetails: FunctionComponent<TagDetailsProps> = (
  props: TagDetailsProps
) => {
  const topicsState = useAppSelector((state) => state.topics);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (value: string) => {
    const findItem = topicsState?.categories?.find((item) => item.id === value);

    props.setSettings((prev: ElementsSettings) => ({
      ...prev,
      displayTag: {
        ...prev.displayTag,
        id: findItem?.id ?? prev.displayTag.id,
        name: findItem?.name ?? prev.displayTag.name,
        color: findItem?.color ?? prev.displayTag.color,
      },
    }));
  };

  return (
    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
      <CtSelect
        placeholder={"Pick a tag"}
        style={{ width: "250px" }}
        value={props.settings.displayTag.name}
        options={topicsState?.categories?.map((category: Category) => ({
          value: category.id,
          label: category.name,
        }))}
        onChange={handleChange}
      />

      <Tooltip title="Choose to display or not on final render">
        <CtButton
          icon={
            props.settings.displayTag.finalRender ? (
              <Eye size={14} />
            ) : (
              <EyeOff size={14} />
            )
          }
          onClick={() =>
            props.setSettings((prev) => ({
              ...prev,
              displayTag: {
                ...prev.displayTag,
                finalRender: !prev.displayTag.finalRender,
              },
            }))
          }
        />
      </Tooltip>

      <Tooltip title="Manage your tags">
        <CtButton
          icon={<Settings size={14} />}
          onClick={() => setIsModalOpen(true)}
        />
      </Tooltip>

      <TagsManagement
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Box>
  );
};

export default TagDetails;

interface TagDetailsProps {
  setSettings: Dispatch<SetStateAction<ElementsSettings>>;
  settings: ElementsSettings;
}
