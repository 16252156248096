import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Input, Modal, SelectProps, Tag, Tooltip } from "antd";
import {
  handleCreateCategory,
  handleDeleteCategory,
  handleUpdateCategory,
} from "../../../app/store/actions/topics.action";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../common/utils/hooks/reduxHook";
import CtButton from "../../../common/components/CtButton/CtButton";
import CtSelect from "../../../common/components/CtSelect/CtSelect";
import { Ban, GripVertical, Pen, Plus, Save, Trash2 } from "lucide-react";
import { Category } from "../../../common/utils/types/topics.type";
import CtLine from "../../../common/components/CtLine/CtLine";
import { useTranslation } from "react-i18next";

type TagRender = SelectProps["tagRender"];

export const tagRender: TagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};

const TagsManagement: FunctionComponent<TagsManagementProps> = (
  props: TagsManagementProps
) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const topicsState = useAppSelector((state) => state.topics);
  const appState = useAppSelector((state) => state.app);
  const [modal, contextHolder] = Modal.useModal();
  const [editingCategoryId, setEditingCategoryId] = useState<string | null>(
    null
  );
  const [editingCategory, setEditingCategory] = useState<Partial<Category>>({});
  const [selectedCategoryColor, setSelectedCategoryColor] = useState<
    string | null
  >("");
  const [options, setOptions] = useState<SelectProps["options"]>([]);

  const [newCategory, setNewCategory] = useState<Partial<Category>>({
    name: "",
    color: null,
  });

  const isCategoryAlreadyExisting = () => {
    const categoriesNames = topicsState?.categories?.map(
      (category) => category.name
    );

    return newCategory.name
      ? !categoriesNames.includes(newCategory.name)
      : true;
  };

  const config = {
    title: (
      <Typography fontWeight={"bold"}>{t("title.delete_category")}</Typography>
    ),
    content: <Typography>{t("labels.delete_category")}</Typography>,
  };

  useEffect(() => {
    const initialOptions = [
      { value: "red" },
      { value: "volcano" },
      { value: "orange" },
      { value: "gold" },
      { value: "yellow" },
      { value: "lime" },
      { value: "green" },
      { value: "cyan" },
      { value: "blue" },
    ];

    const filterOptions = (existingCategories: Category[]) => {
      const existingColors = existingCategories.map(
        (category) => category.color
      );
      return initialOptions.filter(
        (option) => !existingColors.includes(option.value)
      );
    };

    setOptions(filterOptions(topicsState.categories));
  }, [props.isModalOpen]);

  const handleAddNewTag = () => {
    if (newCategory.name && newCategory.color && appState.selectedProject?.id) {
      dispatch(
        handleCreateCategory({
          name: newCategory.name,
          color: newCategory.color,
          projectId: appState.selectedProject?.id,
        })
      );

      setNewCategory({
        name: "",
        color: null,
      });
    }
  };

  const handleEditCategory = (category: Category) => {
    setEditingCategoryId(category.id);
    setEditingCategory({
      name: category.name,
      color: category.color,
    });
    setSelectedCategoryColor(category.color);
  };

  const handleSaveCategory = () => {
    if (editingCategoryId && editingCategory.name && editingCategory.color) {
      // Dispatch update action here if necessary
      dispatch(
        handleUpdateCategory({
          id: editingCategoryId,
          name: editingCategory.name,
          color: editingCategory.color,
        })
      );
      setEditingCategoryId(null);
      setEditingCategory({});
    }
  };

  const handleClose = () => {
    props.setIsModalOpen(false);
    setEditingCategoryId(null);
    setEditingCategory({});
    setSelectedCategoryColor("");
    setOptions([]);
    setNewCategory({
      name: "",
      color: null,
    });
  };

  return (
    <Modal
      title={t("title.tags_management")}
      open={props.isModalOpen}
      footer={null}
      onCancel={handleClose}
    >
      {contextHolder}
      <Box display={"flex"} flexDirection={"column"} gap={"16px"} mt={"24px"}>
        {topicsState?.categories?.map((category: Category) => {
          const isEditing = category.id === editingCategoryId;
          return (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={category.id}
              gap={"4px"}
            >
              {isEditing ? (
                <>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"4px"}
                    width={"100%"}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gap={"4px"}
                      width={"50%"}
                    >
                      <GripVertical size={14} />
                      <Input
                        value={editingCategory.name}
                        onChange={(e) =>
                          setEditingCategory((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                      />
                    </Box>
                    <CtSelect
                      placeholder={t("labels.pick_color")}
                      value={editingCategory.color}
                      mode={"tags"}
                      tagRender={tagRender}
                      options={[
                        ...(options || []),
                        {
                          value: selectedCategoryColor,
                        },
                      ]}
                      style={{ width: "50%" }}
                      maxCount={1}
                      onChange={(value) => {
                        setEditingCategory((prev) => ({
                          ...prev,
                          color: value[0],
                        }));
                      }}
                    />
                  </Box>
                  <Box display={"flex"} gap={"4px"}>
                    <Tooltip title={t("tooltip.cancel")}>
                      <CtButton
                        icon={<Ban size={14} />}
                        onClick={() => setEditingCategoryId(null)}
                      />
                    </Tooltip>
                    <Tooltip title={t("tooltip.save")}>
                      <CtButton
                        icon={<Save size={14} />}
                        onClick={handleSaveCategory}
                      />
                    </Tooltip>
                  </Box>
                </>
              ) : (
                <>
                  <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                    <GripVertical size={14} />
                    <Tag
                      color={category.color ?? ""}
                      style={{ marginInlineEnd: 4 }}
                    >
                      {category.name}
                    </Tag>
                  </Box>
                  <Box display={"flex"} gap={"4px"}>
                    <CtButton
                      icon={<Trash2 size={14} />}
                      onClick={() => {
                        modal.confirm({
                          ...config,
                          onOk: async () => {
                            await dispatch(
                              handleDeleteCategory({ id: category.id })
                            );
                          },
                        });
                      }}
                    />

                    <CtButton
                      disabled={Boolean(editingCategoryId)}
                      icon={<Pen size={14} />}
                      onClick={() => handleEditCategory(category)}
                    />
                  </Box>
                </>
              )}
            </Box>
          );
        })}
        <CtLine />
        <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
          <Box display={"flex"} gap={"8px"}>
            <Box width={"50%"}>
              <Input
                placeholder={t("labels.new_category")}
                value={newCategory.name}
                onChange={(e) =>
                  setNewCategory(() => ({
                    ...newCategory,
                    name: e.target.value,
                  }))
                }
              />
            </Box>
            <CtSelect
              placeholder={t("labels.pick_color")}
              value={newCategory.color}
              mode={"tags"}
              tagRender={tagRender}
              options={options}
              style={{ width: "30%" }}
              maxCount={1}
              onChange={(value) => {
                setNewCategory(() => ({
                  ...newCategory,
                  color: value[0],
                }));
              }}
            />
            <CtButton
              icon={<Plus size={14} />}
              onClick={handleAddNewTag}
              disabled={
                !newCategory.name ||
                !newCategory.color ||
                !isCategoryAlreadyExisting()
              }
              style={{ width: "20%" }}
            >
              {t("buttons.add")}
            </CtButton>
          </Box>

          {!isCategoryAlreadyExisting() ? (
            <Typography
              variant={"caption"}
              color={(theme) => theme.palette.error.main}
            >
              {t("labels.category_already_exists")}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
};

interface TagsManagementProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export default TagsManagement;
