import { toast } from "react-toastify";
import { AuthType } from "../types/auth.type";
import pkAxios from "./axios";

class RemoteAuthService {
  async login(formLogin: AuthType) {
    try {
      const response = await pkAxios.post("/api/auth/login", { ...formLogin });

      return response;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async register(formRegister: AuthType) {
    try {
      const response = await pkAxios.post("/api/auth/signup", {
        ...formRegister,
      });

      return response;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async forgot(email: string) {
    try {
      const response = await pkAxios.post("/api/auth/forgot-password", {
        email,
      });

      return response;
    } catch (error) {
      return error;
    }
  }

  async logout() {
    try {
      const response = await pkAxios.post("/api/auth/logout");
      localStorage.removeItem("infosLogin");
      window.location.pathname = "/login";

      return response;
    } catch (error) {
      return error;
    }
  }

  async createUserFromInvitation(
    token: string,
    firstName: string,
    lastName: string,
    password: string
  ) {
    try {
      const response = await pkAxios.post(`/api/auth/create/user`, {
        token,
        firstName,
        lastName,
        password,
      });

      return response;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async inviteUserFromInvitation(
    userId: string,
    token: string,
    status: "Accepted" | "Refused"
  ) {
    try {
      const response = await pkAxios.post(`/api/auth/invite/user`, {
        userId,
        token,
        status,
      });

      return response;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }
}

export default RemoteAuthService;
