import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { StatusType } from "../../../common/utils/types/status.type";
import { UserState } from "../../../common/utils/types/user.type";
import {
  handleInitMe,
  handleLogin,
  handleUpdateMe,
} from "../actions/user.action";
import {
  handleCreateProject,
  handleDeleteProject,
  handleUpdateProject,
} from "../actions/app.action";

const initialState: UserState = {
  infos: null,
  status: StatusType.Succeeded,
  error: null,
};

const { reducer } = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(handleInitMe.pending, (state) => {
      state.status = StatusType.Loading;
    });

    builder.addCase(handleUpdateMe.fulfilled, (state, { payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { updatedAt, userSettingsId, settings, ...rest } = payload;

      state.infos = {
        ...rest,
        userSettings: settings,
      };
    });

    builder.addCase(handleDeleteProject.fulfilled, (state, { payload }) => {
      if (state.infos) {
        state.infos.projects = state.infos.projects.filter(
          (item) => item.id !== payload
        );
      }
    });

    builder.addCase(handleUpdateProject.fulfilled, (state, { payload }) => {
      if (state.infos?.projects) {
        const item = state.infos.projects.find(
          (project) => project.id === payload.id
        );

        if (item) {
          item.name = payload.name;
        }
      }
    });

    builder.addCase(handleCreateProject.fulfilled, (state, { payload }) => {
      if (state.infos && state.infos.projects) {
        state.infos.projects.push({ id: payload.id, name: payload.name });
      }
    });

    builder.addMatcher(
      isAnyOf(handleLogin.fulfilled, handleInitMe.fulfilled),
      (state, { payload }) => {
        state.infos = payload;
        state.status = StatusType.Succeeded;
      }
    );
  },
});

export default reducer;
