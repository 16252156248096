import { Project } from "./project.type";
import { StatusType } from "./status.type";

export interface UserState {
  infos?: User | null;
  status: StatusType;
  error?: string | null;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: Date;
  projects: Project[];
  userSettings: {
    language: Language;
  };
}

export enum Language {
  FR = "FR",
  EN = "EN",
}
