import { StyledDropdown } from "../../../features/ContextualHelp/components/DropdownActions";
import { Box, Typography } from "@mui/material";
import { Divider, Dropdown, DropdownProps } from "antd";
import { ChevronRight, CircleUser, GanttChart, LogOut } from "lucide-react";
import ListProject from "./ListProject";
import { authService } from "../../utils/services";
import { useAppSelector } from "../../utils/hooks/reduxHook";
import { StyledNavItem } from "./Items";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appState = useAppSelector((state) => state.app);

  const handleLogout = () => {
    authService.logout();
  };

  return (
    <StyledDropdown minWidth="250px" padding={"16px 8px"}>
      <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
        <Dropdown
          dropdownRender={() => <ListProject />}
          placement={"right" as DropdownProps["placement"]}
        >
          <StyledNavItem>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                <GanttChart size={20} />
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography lineHeight={1.2}>
                    {appState?.selectedProject?.name}
                  </Typography>
                  <Typography
                    variant={"caption"}
                    lineHeight={1.2}
                    color={(theme) => theme.palette.secondary.main}
                  >
                    {t("labels.switch_project")}
                  </Typography>
                </Box>
              </Box>
              <ChevronRight size={20} />
            </Box>
          </StyledNavItem>
        </Dropdown>

        <Divider
          style={{
            margin: "0px 12px",
            width: "calc(100% - 24px)",
            minWidth: 0,
          }}
        />

        <StyledNavItem onClick={() => navigate("/settings/account")}>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <CircleUser size={20} />
            <Typography>{t("navigation.my_profile")}</Typography>
          </Box>
        </StyledNavItem>

        <StyledNavItem onClick={handleLogout}>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <LogOut size={20} />
            <Typography>{t("navigation.logout")}</Typography>
          </Box>
        </StyledNavItem>
      </Box>
    </StyledDropdown>
  );
};

export default ProfileDropdown;
