import { FunctionComponent, useState } from "react";
import CtLayout from "../../../common/components/CtLayout/CtLayout";
import CtContent from "../../../common/components/CtLayout/CtContent";
import { Box, Typography, useMediaQuery } from "@mui/material";
import SubNav from "../components/SubNav";
import { Segmented } from "antd";
import SettingsContent from "../components/SettingsContent";
import { initialSettingsLanguages } from "../../../common/utils/constants/settings.consts";
import { SettingsLanguage } from "../../../common/utils/types/settings.type";
import ReactInstallation from "../components/ReactInstallation";
import CtComingSoon from "../../../common/components/CtInfoUser/CtComingSoon";
import { useTranslation } from "react-i18next";
import SubNavLayout from "../components/SubNavLayout";
import { Breakpoints } from "../../../common/utils/types/app.type";

const Installation: FunctionComponent = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);

  const [languageSelected, setLanguageSelected] = useState<SettingsLanguage>(
    SettingsLanguage.REACT
  );

  return (
    <SubNavLayout
      openMobileNavbar={openMobileNavbar}
      setOpenMobileNavbar={setOpenMobileNavbar}
    >
      <CtLayout isCollapsed={true}>
        <CtContent>
          <Box display={"flex"} height={"100%"}>
            {isMobile ? <></> : <SubNav />}

            <SettingsContent
              title={t("title.installation")}
              openMobileNavbar={openMobileNavbar}
              setOpenMobileNavbar={setOpenMobileNavbar}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={isMobile ? "column" : "row"}
                gap={isMobile ? "8px" : "4px"}
                width={"100%"}
                alignItems={"flex-start"}
              >
                <Typography variant={"h1"} fontWeight={"bold"}>
                  {t("title.how_to_install")}
                </Typography>

                <Segmented
                  value={languageSelected}
                  options={initialSettingsLanguages}
                  style={{ display: "flex" }}
                  onChange={(newValue) => setLanguageSelected(newValue)}
                />
              </Box>

              {languageSelected === SettingsLanguage.REACT ? (
                <ReactInstallation />
              ) : (
                <Box mt={"16px"}>
                  <CtComingSoon />
                </Box>
              )}
            </SettingsContent>
          </Box>
        </CtContent>
      </CtLayout>
    </SubNavLayout>
  );
};

export default Installation;
