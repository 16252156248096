import React, { FunctionComponent, useState } from "react";
import CtLayout from "../../../common/components/CtLayout/CtLayout";
import CtHeader from "../../../common/components/CtLayout/CtHeader";
import CtContent from "../../../common/components/CtLayout/CtContent";
import { Backdrop, Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import CtCard from "../../../common/components/CtCard/CtCard";
import styled from "styled-components";
import bookADemoIllu from "../../../assets/images/BookADemo.svg";
import bgInteractiveDemo from "../../../assets/images/BgInteractiveDemo.png";
import needHelpFR from "../../../assets/images/needHelpFR.svg";
import { convertHexToRGBA } from "../../../common/utils/functions";
import { PopupModal } from "react-calendly";
import CtButton from "../../../common/components/CtButton/CtButton";
import { Play, X } from "lucide-react";
import { ctWhite } from "../../../common/utils/theme/colors";
import { ToolioHelp } from "npmlibhelpcontextual";
import { Breakpoints } from "../../../common/utils/types/app.type";

const StyledCard = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "100%" : "250px")};
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 8px;
  box-shadow: ${({ theme }) =>
    `${convertHexToRGBA(theme.palette.text.primary, 0.05)} 0px 12px 24px`};
`;

const StyledBgDemo = styled(Box)`
  background-image: url(${bgInteractiveDemo});
  background-size: cover;
`;

const Welcome: FunctionComponent = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const [isBookDemoOpen, setIsBookDemoOpen] = useState(false);
  const [openDemo, setOpenDemo] = useState(false);

  return (
    <CtLayout>
      {openDemo ? (
        <Box position={"fixed"} zIndex={"9999"}>
          <Backdrop open={true} onClick={() => setOpenDemo(false)} />
          <Box
            position={"fixed"}
            top={"20px"}
            right={"20px"}
            sx={{ cursor: "pointer" }}
            onClick={() => setOpenDemo(false)}
          >
            <X size={40} color={ctWhite} />
          </Box>
          <div
            style={{
              position: "fixed",
              paddingBottom: "calc(60.26785714285714% + 41px)",
              height: "80%",
              width: "80%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              maxWidth: "1200px",
            }}
          >
            <iframe
              src="https://demo.arcade.software/3VT2hHw11FA2A0vvRKWv?embed&embed_mobile=tab&embed_desktop=inline&first_name=Test"
              title="Watch our interactive demo"
              frameBorder="0"
              loading="lazy"
              allowFullScreen
              allow="clipboard-write"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                colorScheme: "light",
              }}
            />
          </div>
        </Box>
      ) : null}
      <CtContent>
        <CtHeader
          title={
            <Box display={"flex"} alignItems={"center"} gap={"18px"}>
              <Typography variant={"h1"}>{t("title.welcome")}</Typography>
            </Box>
          }
        />

        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Box padding={"24px 26px"} width={"1042px"}>
            <CtCard>
              <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
                <StyledBgDemo
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"16px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={isMobile ? "300px" : "550px"}
                  borderRadius={"8px"}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant={"h1"}
                      color={(theme) => theme.palette.text.secondary}
                      textAlign={"center"}
                    >
                      Contextual - Interactive Demo
                    </Typography>
                    <Typography
                      variant={"h3"}
                      color={(theme) => theme.palette.text.secondary}
                    >
                      Watch our interactive demo
                    </Typography>
                  </Box>
                  <Box>
                    <CtButton
                      type={"primary"}
                      icon={<Play size={14} />}
                      style={{ width: "100%" }}
                      onClick={() => setOpenDemo(true)}
                    >
                      {t("Play")}
                    </CtButton>
                  </Box>
                </StyledBgDemo>
                <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
                  <Typography fontWeight={"bold"} fontSize={"16px"}>
                    Resources
                  </Typography>

                  <Box
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    gap={"24px"}
                  >
                    <StyledCard
                      isMobile={isMobile}
                      onClick={() => setIsBookDemoOpen(true)}
                    >
                      <StyledImage src={bookADemoIllu} />
                      <Typography fontSize={"14px"} fontWeight={"bold"}>
                        Book a demo
                      </Typography>
                      <Typography fontSize={"12px"}>
                        Learn how Contextual can enhance your app and explore
                        its key features.
                      </Typography>
                    </StyledCard>

                    <PopupModal
                      url={"https://calendly.com/contact-usetoolio/30min"}
                      onModalClose={() => setIsBookDemoOpen(false)}
                      open={isBookDemoOpen}
                      rootElement={
                        document.getElementById("root") ??
                        ((<div></div>) as any)
                      }
                    />

                    <ToolioHelp
                      topicId={"d0a75f8f-735c-48cf-b6e9-aea2e2c8a38c"}
                      trigger={
                        <StyledCard isMobile={isMobile}>
                          <StyledImage src={needHelpFR} />
                          <Typography fontSize={"14px"} fontWeight={"bold"}>
                            How Contextual is used in this app
                          </Typography>
                          <Typography fontSize={"12px"}>
                            See how Contextual is integrated and utilized in
                            this application.
                          </Typography>
                        </StyledCard>
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </CtCard>
          </Box>
        </Box>
      </CtContent>
    </CtLayout>
  );
};

export default Welcome;
