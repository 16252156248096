import React, { FunctionComponent, useContext } from "react";
import { ButtonProps, ConfigProvider } from "antd";
import { css } from "@emotion/css";
import CtButton from "./CtButton";
import { BadgeHelp } from "lucide-react";
import { ToolioHelp } from "npmlibhelpcontextual";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { Breakpoints } from "../../utils/types/app.type";

const CtGradientButton: FunctionComponent<CtGradientButtonProps> = (
  props: CtGradientButtonProps
) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const { getPrefixCls } = useContext(ConfigProvider.ConfigContext);
  const rootPrefixCls = getPrefixCls();
  const linearGradientButton = css`
    &.${rootPrefixCls}-btn-primary:not([disabled]):not(
        .${rootPrefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: "";
        background: linear-gradient(135deg, #6253e1, #04befe);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `;

  return (
    <ConfigProvider
      button={{
        className: linearGradientButton,
      }}
    >
      <ToolioHelp
        topicId={props.topicId}
        trigger={
          <CtButton
            {...props}
            type={"primary"}
            icon={props.icon ?? <BadgeHelp size={14} />}
          >
            {isMobile ? null : props.children ?? t("buttons.need_help")}
          </CtButton>
        }
      />
    </ConfigProvider>
  );
};

interface CtGradientButtonProps extends ButtonProps {
  topicId?: string;
}

export default CtGradientButton;
