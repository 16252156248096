import { Tooltip } from "antd";
import { Download } from "lucide-react";
import { FunctionComponent } from "react";
import CtButton from "../../../../common/components/CtButton/CtButton";
import { useAppSelector } from "../../../../common/utils/hooks/reduxHook";
import { useTranslation } from "react-i18next";
import { downloadJson } from "../../../../common/utils/functions";

const DownloadJSON: FunctionComponent<DownloadJSONProps> = (
  props: DownloadJSONProps
) => {
  const { t } = useTranslation();
  const topicsState = useAppSelector((state) => state.topics);
  const appState = useAppSelector((state) => state.app);

  return (
    <Tooltip title={t("tooltip.create_json_for_developers")}>
      <CtButton
        type={"primary"}
        ghost
        icon={<Download size={14} />}
        onClick={() => {
          if (appState.selectedProject?.id && topicsState.list) {
            downloadJson(appState.selectedProject?.id, topicsState.list);
          }
        }}
        disabled={props.disabled}
      >
        {t("buttons.export_for_developers")}
      </CtButton>
    </Tooltip>
  );
};

interface DownloadJSONProps {
  disabled: boolean;
}

export default DownloadJSON;
