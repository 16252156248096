import { FunctionComponent, useEffect, useState } from "react";
import CtLayout from "../../../common/components/CtLayout/CtLayout";
import CtContent from "../../../common/components/CtLayout/CtContent";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SubNav from "../components/SubNav";
import SettingsContent from "../components/SettingsContent";
import TableUsers from "../components/TableUsers";
import { User } from "../../../common/utils/types/user.type";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../common/utils/hooks/reduxHook";
import { handleGetProjectById } from "../../../app/store/actions/topics.action";
import { teamService } from "../../../common/utils/services";
import CtButton from "../../../common/components/CtButton/CtButton";
import { Mail, Unplug, UserPlus } from "lucide-react";
import { Input, Modal } from "antd";
import { isValidEmail } from "../../../common/utils/functions";
import { useTranslation } from "react-i18next";
import SubNavLayout from "../components/SubNavLayout";
import { Breakpoints } from "../../../common/utils/types/app.type";

const Team: FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const userState = useAppSelector((state) => state.user);
  const appState = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);

  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);

  const [listUsers, setListUsers] = useState<Partial<User>[]>([]);
  const [invitedMembers, setInvitedMembers] = useState<Partial<User>[]>([]);

  const [leaveTeamModal, setLeaveTeamModal] = useState(false);
  const [inviteMemberModal, setInviteMemberModal] = useState(false);
  const [email, setEmail] = useState("");

  const getTeamUsers = async () => {
    if (!appState.selectedProject?.teamId) {
      await dispatch(handleGetProjectById({}));
    }

    if (appState.selectedProject?.teamId) {
      const data = await teamService.getTeam(appState.selectedProject?.teamId);

      setListUsers(data?.members);
      setInvitedMembers(data?.inviteTokens);
    }
  };

  useEffect(() => {
    getTeamUsers();
  }, [appState.selectedProject?.teamId]);

  return (
    <SubNavLayout
      openMobileNavbar={openMobileNavbar}
      setOpenMobileNavbar={setOpenMobileNavbar}
    >
      <CtLayout isCollapsed={true}>
        <CtContent>
          <Box display={"flex"} height={"100%"}>
            {isMobile ? <></> : <SubNav />}

            <SettingsContent
              title={t("title.team")}
              tag={t("labels.members", { count: listUsers.length })}
              hideBg={true}
              openMobileNavbar={openMobileNavbar}
              setOpenMobileNavbar={setOpenMobileNavbar}
              extraAttributes={
                <Box
                  display={"flex"}
                  gap={isMobile ? "4px" : "16px"}
                  alignItems={"center"}
                >
                  {listUsers.length > 1 ? (
                    <CtButton
                      type={"primary"}
                      danger={true}
                      icon={<Unplug size={14} />}
                      onClick={() => setLeaveTeamModal(true)}
                    >
                      {!isMobile ? t("buttons.leave_team") : null}
                    </CtButton>
                  ) : null}
                  <CtButton
                    type={"primary"}
                    icon={<UserPlus size={14} />}
                    onClick={() => setInviteMemberModal(true)}
                  >
                    {!isMobile ? t("buttons.invite_member") : null}
                  </CtButton>
                </Box>
              }
            >
              <Box overflow={"scroll"}>
                <TableUsers
                  listUsers={listUsers}
                  invitedMembers={invitedMembers}
                  getTeamUsers={() => getTeamUsers()}
                />
              </Box>
            </SettingsContent>
          </Box>
        </CtContent>

        <Modal
          title={
            <Typography variant={"h2"}>{t("title.add_member")}</Typography>
          }
          centered
          open={inviteMemberModal}
          onOk={async () => {
            appState?.selectedProject?.teamId
              ? await teamService.inviteNewMember(
                  appState?.selectedProject?.teamId,
                  email
                )
              : null;

            setInviteMemberModal(false);
            setEmail("");
            getTeamUsers();
          }}
          onCancel={() => {
            setInviteMemberModal(false);
            setEmail("");
          }}
          okText={t("buttons.continue")}
          okButtonProps={{
            disabled: email.length === 0 || !isValidEmail(email),
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
            <Typography variant={"caption"}>
              {t("labels.email_to_invite")}
            </Typography>

            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
              width={"100%"}
            >
              <Typography fontWeight={"bold"} variant={"caption"}>
                {t("labels.email")}
              </Typography>
              <Input
                placeholder={"michael.jackson@youhou.com"}
                autoFocus={true}
                prefix={
                  <Box display={"flex"} mr={"4px"}>
                    <Mail size={14} color={theme.palette.grey[400]} />
                  </Box>
                }
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </Box>
          </Box>
        </Modal>

        <Modal
          title={
            <Typography variant={"h2"}>{t("title.leave_team")}</Typography>
          }
          centered
          open={leaveTeamModal}
          onOk={async () => {
            appState?.selectedProject?.teamId && userState.infos?.id
              ? await teamService.removeMember(
                  appState?.selectedProject?.teamId,
                  userState.infos?.id
                )
              : null;

            setLeaveTeamModal(false);
            window.location.href = "/";
          }}
          onCancel={() => {
            setLeaveTeamModal(false);
          }}
          okText={"I quit"}
          okButtonProps={{
            danger: true,
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
            <Typography variant={"caption"}>
              {t("labels.sure_to_leave_team")}
            </Typography>
          </Box>
        </Modal>
      </CtLayout>
    </SubNavLayout>
  );
};

export default Team;
