import { useState } from "react";

export const useLoading = () => {
  const [loadingStates, setLoadingStates] = useState<any>({});

  const execWithLoader = async (key: any, fn: any) => {
    setLoadingStates((prev: any) => ({ ...prev, [key]: true }));

    try {
      await fn();
    } finally {
      setLoadingStates((prev: any) => ({ ...prev, [key]: false }));
    }
  };

  return { loadingStates, execWithLoader };
};
