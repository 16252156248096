import { Avatar, Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import styled from "styled-components";
import CtLine from "../CtLine/CtLine";
import { ChevronsUpDown, Newspaper, Rocket, Settings } from "lucide-react";
import { convertHexToRGBA, isPageActive } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import ProfileDropdown from "./ProfileDropdown";
import { useAppSelector } from "../../utils/hooks/reduxHook";
import { StyledNavItem } from "./Items";
import LanguageManagement from "./LanguageManagement";
import { useTranslation } from "react-i18next";
import iconLogo from "../../../assets/images/iconLogo.svg";
import CtLink from "../CtLink/CtLink";

const StyledNavbar = styled.div<{ isCollapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ isCollapsed }) => (isCollapsed ? "100px" : "300px")};
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  overflow: hidden;
  box-sizing: border-box;
  padding: 24px;
`;

const StyledItemHover = styled(Box)`
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: 0.3s;

  &:before {
    opacity: 0;
    position: absolute;
    content: "";
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    top: -8px;
    left: -8px;
    background-color: ${({ theme }) =>
      convertHexToRGBA(theme.palette.secondary.light, 0.3)};
    border-radius: 8px;
    z-index: 0;
    transition: opacity 0.3s;
  }

  &:hover:before {
    opacity: 1;
  }
`;

const Navbar: FunctionComponent<NavBarProps> = (props: NavBarProps) => {
  const { t } = useTranslation();
  const appState = useAppSelector((state) => state.app);
  const userState = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <StyledNavbar isCollapsed={props.isCollapsed}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={props.isCollapsed ? "center" : "stretch"}
        gap={"16px"}
      >
        <Dropdown
          dropdownRender={() => <ProfileDropdown />}
          trigger={["click"]}
        >
          <StyledItemHover
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              gap={"10px"}
              alignItems={"center"}
              width={"calc(100% - 36px)"}
            >
              <Avatar
                sx={{
                  borderRadius: "10px",
                  backgroundColor: (theme) => theme.palette.primary.main,
                }}
                variant="square"
              >
                {appState.selectedProject?.name?.[0]?.toUpperCase()}
              </Avatar>
              {!props.isCollapsed ? (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  width={"calc(100% - 36px)"}
                >
                  <Typography
                    fontWeight={"bold"}
                    variant={"subtitle1"}
                    lineHeight={1}
                    color={(theme) => theme.palette.primary.main}
                    noWrap={true}
                  >
                    {appState?.selectedProject?.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontWeight={"bold"}
                    color={(theme) => theme.palette.secondary.main}
                    noWrap={true}
                  >
                    {userState?.infos?.email}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            {!props.isCollapsed ? (
              <ChevronsUpDown size={"18px"} style={{ minWidth: "18px" }} />
            ) : null}
          </StyledItemHover>
        </Dropdown>

        <CtLine />

        <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
          <StyledNavItem
            isActive={isPageActive("/")}
            onClick={() => navigate("/")}
          >
            <Rocket />
            {!props.isCollapsed ? (
              <Typography variant={"subtitle1"}>
                {t("labels.welcome")}
              </Typography>
            ) : null}
          </StyledNavItem>

          <StyledNavItem
            isActive={isPageActive("/contextual-help")}
            onClick={() => navigate("/contextual-help")}
          >
            <Newspaper />
            {!props.isCollapsed ? (
              <Typography variant={"subtitle1"}>
                {t("navigation.my_topics")}
              </Typography>
            ) : null}
          </StyledNavItem>

          {/* <StyledNavItem
            isActive={isPageActive("/statistics")}
            onClick={() => navigate("/statistics")}
          >
            <PieChart />
            {!props.isCollapsed ? (
              <Typography variant={"subtitle1"}>
                {t("navigation.statistics")}
              </Typography>
            ) : null}
          </StyledNavItem> */}

          <CtLine />

          <StyledNavItem
            isActive={window.location.pathname.includes("/settings")}
            onClick={() => navigate("/settings/general")}
          >
            <Settings />
            {!props.isCollapsed ? (
              <Typography variant={"subtitle1"}>
                {t("navigation.settings")}
              </Typography>
            ) : null}
          </StyledNavItem>
        </Box>
      </Box>

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={props.isCollapsed ? "center" : "space-between"}
        gap={"8px"}
      >
        {!props.isCollapsed ? (
          <Box display={"flex"} alignItems={"center"} gap={"8px"}>
            <CtLink
              href={"https://usetoolio.com"}
              external={true}
              label={
                <img
                  src={iconLogo}
                  alt="Icon Logo"
                  width={"16px"}
                  style={{ opacity: 0.5 }}
                />
              }
            />

            <Typography
              fontSize={"8px"}
              textAlign={"center"}
              color={(theme) => theme.palette.secondary.main}
            >
              {t("labels.version", { format: "capitalize" })} 1.0.0
            </Typography>
          </Box>
        ) : null}

        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={props.isCollapsed ? "column" : "row"}
          gap={"4px"}
        >
          {/* <Tooltip title="Documentation">
            <CtButton icon={<BookText size={14} />} />
          </Tooltip> */}

          <LanguageManagement />
        </Box>
      </Box>
    </StyledNavbar>
  );
};

interface NavBarProps {
  isCollapsed?: boolean;
}

export default Navbar;
