import React, { FunctionComponent } from "react";
import { Box, useTheme } from "@mui/material";
import { CircleMinus, CirclePlus } from "lucide-react";
import { TopicItemProps } from "../../../../common/utils/constants/topics.consts";

const AddToListButton: FunctionComponent<TopicItemProps> = (
  props: TopicItemProps
) => {
  const theme = useTheme();

  const handleAddToList = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.topic.id) {
      if (!props.selectedItems.includes(props.topic.id)) {
        props.setSelectedItems([...props.selectedItems, props.topic.id]);
      }
    }
  };

  const handleRemoveFromList = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.topic.id) {
      if (props.selectedItems.includes(props.topic.id)) {
        props.setSelectedItems(
          props.selectedItems.filter((id) => id !== props.topic.id)
        );
      }
    }
  };

  return (
    <Box
      position={"absolute"}
      top={"50%"}
      left={"-12px"}
      width={"25px"}
      borderRadius={"50%"}
      display={"flex"}
      bgcolor={(theme) => theme.palette.common.white}
      sx={{ transform: "translateY(-50%)" }}
      onClick={(event) => {
        if (props.topic.id && props.selectedItems.includes(props.topic.id)) {
          handleRemoveFromList(event);
        } else {
          handleAddToList(event);
        }
      }}
    >
      {props.topic.id && props.selectedItems.includes(props.topic.id) ? (
        <CircleMinus color={theme.palette.primary.main} />
      ) : (
        <CirclePlus />
      )}
    </Box>
  );
};

export default AddToListButton;
