import { Select, SelectProps } from "antd";
import { ChevronDown } from "lucide-react";
import { FunctionComponent } from "react";

const CtSelect: FunctionComponent<CtSelectProps> = (props: CtSelectProps) => {
  return <Select suffixIcon={<ChevronDown size={14} />} {...props} />;
};

export default CtSelect;

interface CtSelectProps extends SelectProps {}
