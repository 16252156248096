import { Backdrop, Box, useMediaQuery } from "@mui/material";
import { Menu } from "lucide-react";
import { FunctionComponent, ReactNode, useState } from "react";
import styled from "styled-components";
import { Breakpoints } from "../../utils/types/app.type";
import Navbar from "../Navbar/Navbar";

const StyledHeader = styled.div`
  padding: 24px 26px;
  min-height: 81px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

const CtHeader: FunctionComponent<CtHeaderProps> = (props: CtHeaderProps) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.MOBILE})`);
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      height={props.height}
    >
      <StyledHeader>
        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
          {isMobile ? (
            <Menu onClick={() => setOpenNavbar(!openNavbar)} />
          ) : null}
          {props.title}
        </Box>
        {props.actions}
      </StyledHeader>
      {props.children}

      {openNavbar ? (
        <>
          <Backdrop
            open={true}
            sx={{ position: "absolute", zIndex: 8 }}
            onClick={() => setOpenNavbar(false)}
          />

          <Box position={"absolute"} zIndex={9}>
            <Navbar />
          </Box>
        </>
      ) : null}
    </Box>
  );
};

interface CtHeaderProps {
  children?: ReactNode;
  title: ReactNode;
  actions?: ReactNode;
  height?: string;
}

export default CtHeader;
