import { toast } from "react-toastify";
import pkAxios from "./axios";
import { ProjectSettings } from "../types/project.type";
import i18n from "../../../i18n";

class ProjectService {
  async createProject(projectName: string) {
    try {
      const response = await pkAxios.post("/api/projects", {
        name: projectName,
      });

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async updateProject(projectId: string, projectName: string) {
    try {
      const response = await pkAxios.put(`/api/projects/${projectId}`, {
        name: projectName,
      });

      if (response?.status === 200) {
        toast.success(i18n.t("toast.project_renamed"));
      }

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async getProjectId(projectId: string) {
    try {
      const response = await pkAxios.get(`/api/projects/${projectId}`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async getCategories() {
    try {
      const response = await pkAxios.get(`/api/projects/categories`);

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async updateSettings({
    projectId,
    settings,
  }: {
    projectId: string | undefined;
    settings: ProjectSettings;
  }) {
    try {
      const response = await pkAxios.put(
        `/api/projects/settings/${projectId}`,
        settings
      );

      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }

  async deleteProject(projectId: string) {
    try {
      const response = await pkAxios.delete(`/api/projects/${projectId}`);

      return response;
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      return error;
    }
  }
}

export default ProjectService;
