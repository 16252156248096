import { createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../../common/utils/types/status.type";
import {
  TopicDisplayMode,
  TopicsState,
} from "../../../common/utils/types/topics.type";
import {
  handleDeleteCategory,
  handleDeleteTopic,
  handleGetCategories,
  handleGetProjectById,
  handleGetTopics,
  handleSelectTopic,
  handleSetTopicsSettings,
  handleUpdateCategory,
} from "../actions/topics.action";

const initialState: TopicsState = {
  list: [],
  filteredList: [],
  categories: [],
  settings: {
    displayMode: TopicDisplayMode.ROW,
  },
  selectedTopic: null,
  status: StatusType.Idle,
  error: null,
};

const { reducer } = createSlice({
  name: "topics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(handleSetTopicsSettings.fulfilled, (state, { payload }) => {
      state.settings = { ...state.settings, ...payload };
    });

    builder.addCase(handleGetProjectById.pending, (state) => {
      state.status = StatusType.Loading;
    });

    builder.addCase(handleGetProjectById.fulfilled, (state, { payload }) => {
      state.list = payload?.topics;
      state.filteredList = payload?.topics;
      state.status = StatusType.Succeeded;
    });

    // CATEGORIES
    builder.addCase(handleGetCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
    });

    builder.addCase(handleUpdateCategory.fulfilled, (state, { payload }) => {
      state.categories = state.categories.map((category) =>
        category.id === payload.id ? { ...category, ...payload } : category
      );
    });

    builder.addCase(handleDeleteCategory.fulfilled, (state, { payload }) => {
      state.categories = state.categories.filter(
        (category) => category.id !== payload
      );
    });

    // TOPICS
    builder.addCase(handleGetTopics.fulfilled, (state, { payload }) => {
      state.filteredList = payload;
    });

    builder.addCase(handleSelectTopic.fulfilled, (state, { payload }) => {
      state.selectedTopic = payload;
    });

    builder.addCase(handleDeleteTopic.fulfilled, (state, { payload }) => {
      if (state.filteredList) {
        state.filteredList = state.filteredList.filter(
          (topic) => !payload?.includes(topic.id)
        );
        if (state.filteredList.length === 0) {
          state.filteredList = null;
        }
      }

      if (state.list) {
        state.list = state.list.filter((topic) => !payload?.includes(topic.id));
        if (state.list.length === 0) {
          state.list = null;
        }
      }
    });
  },
});

export default reducer;
